import React, {Component} from "react";
import OptionsSlider from "../OptionsSlider";

class LayerOpacity extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            id:this.props.value,
        }
    }

    handlerChange = (value) => {
        console.log("Opacity")
        for (let i = 0 ; i < this.props.result.length;i++){
            if (this.props.result[i].name === this.props.value) {
                this.props.result[i].opacity = value ;
            }
        }
        this.props.handle();
    };

    render () {
        const { value } = this.state;
        return (
            <div className='slider-horizontal'>
                <OptionsSlider handler={this.handlerChange} title={"Opacity"} />
            </div>
        )
    }
}
export default LayerOpacity;