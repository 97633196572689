import React, {Component} from "react";
import OptionsSlider from "../OptionsSlider";

class LayerTextSize extends Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            id:this.props.value,
        }
    }

    handleChange = (value) => {
        for (let i = 0; i < this.props.data2.length; i++) {
            if (this.props.data2[i].name === this.props.value+"Text") {
                this.props.data2[i].opacity = value ;
            }
        }
        this.props.handle();
    };

    render () {
        return (
            <div className='slider-horizontal'>
                <OptionsSlider handler={this.handleChange} title={"Text Opacity"} />
            </div>
        )
    }
}
export default LayerTextSize;