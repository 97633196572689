import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React from "react";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const FeatureInfos = {
    en: {
        translation: {
            "f_company_id":"Operator",
            "Свойства":"Properties",
            "Доп.Свойства.":"Extra properties",
            "Графики":"Graphs",
            "Запасы":"Reserves",
            "Права Доступа":"Permissions",
            "Дополнительная информация":"Additional Information",
            "Таблица Дополнительной Информации":"Table of Additional Information",
            "Search: ":"Search: ",
            "GeoTiff":"GeoTiff",
            "Las Files":"Las Files",
            "Edit Infos":"Edit Infos",
            "Original Infos":"Original Infos",
            "Last edited by ":"Last edited by ",
            "Modified ":"Modified ",
            "General object":"General object",
            "User object":"User object",
            "Add as User Objects":"Add as User Objects",
            "Save":"Save",
            "Edit Geometry":"Edit Geometry",
            "Delete Object":"Delete Object",
            "Change Mode":"Change Mode",
            "Drag'n'drop images here, or click to select files":"Drag'n'drop images here, or click to select files",
            "You are not allowed to change permissions on this object":"You are not allowed to change permissions on this object",
            "description":"Description",
            "basin_name":"Name",
            "geo_index_start_id":"Geo index start",
            "geo_index_end_id":"Geo index end",
            "basin_type_id":"Basin Type",
            "basin_subtype_id":"Basin Subtype",
            "basin_class_id":"Basin Class",
            "basin_fluid_type_id":"",
            "basin_age_id":"Age",
            "area":"Area",
            "subbasin_name":"Name",
            "source":"Source",
            "confidence_degree":"Confidence degree",
            "fields_name":"Name",
            "year":"Year",
            "field_class":"Field Class",
            "location":"Location",
            "field_type":"Field Type",
            "development_stage":"Development stage",
            "discovery_method":"Discovery method",
            "terrain":"Terrain",
            "discovery_year":"Discovery year",
            "shutdown_year":"Shutdown year",
            "start_production_year":"Start production year",
            "planed_start_production_year":"Planed start production year",
            "max_elevation":"Max elevation",
            "max_water_depth":"Max water depth",
            "licenses_name":"Name",
            "lic_nbr":"License Number",
            "operator_name":"Operator name",
            "paid_value":"Paid value",
            "auction_start_payment":"Auction start payment",
            "cost_difirence_coeficient":"Cost difirence coeficient",
            "bid_members":"Bid Members",
            "bid_auction_date":"Bid auction Date",
            "auction_link":"Auction link",
            "parent_licenses":"Parent licenses",
            "mother_company":"Mother company",
            "confidence_degree_id":"Confidence degree",
            "license_status":"License Status",
            "license_type":"License Type",
            "issue_date":"Issue date",
            "expiry_date":"Expiry date",
            "length_2d":"Length",
            "s_lin_name":"Name",
            "s_lin_nbr":"Number",
            "domain_id":"Domain",
            "seismic_type_id":"Seismic Type",
            "signal_source_id":"Signal source",
            "field_work_year":"Field work year",
            "processing_year":"Processing year",
            "field_operator_name":"Field operator name",
            "processing_company_name":"Processing company name",
            "rgf_nbr":"Rgf Number",
            "rgf_report_year":"Rgf report year",
            "rgf_link":"Rgf link",
            "well_name":"Name",
            "well_head_altitude":"Head altitude",
            "kb_altitude":"kb Altitude",
            "td_m":"td m",
            "target_reservoir_id":"Target reservoir",
            "uwi":"uwi",
            "alternative_names":"Alternative name",
            "current_status_id":"Current status",
            "status_start_date":"Status start date",
            "status_end_date":"Status end date",
            "well_stock_id":"Stoke",
            "well_type_id":"Well Type",
            "object_name":"Name",
            "outcrop":"Outcrop",
            "object_type":"Object type",
            "geo_position":"Geo position",
            "reference":"Reference",
            "straton":"Straton",
            "info_year":"Info Year",
            "info_source_text":"Info source text",
            "info_structure":"Info structure",
            "original_name":"Name",
            "geo_compos":"Geo composition",
            "geologicmap_name":"Name",
            "update_date":"Update date",
            "name":"Name",
            "protected_area_type_id":"Type",
            "profile_create_date":"Profile create date",
            "protected_area_profile_id":"Profile",
            "org_category":"Category",
            "f_co2_org_infos_name_rus":"Name",
            "name_ru":"Name",
            "storage_type_id":"Storage type",
            "region_name":"Name",
            "Index":"Index",
            "Name":"Name",
            "Author":"Author",
            "Doi":"Doi",
            "Url":"Url",
            "Publication Type":"Publication Type",
            "Publication Tags":"Publication Tags",
            "Year":"Year",
            "Layer":"Layer",
            "Edit":"Edit",
            "Permission":"Permission",
            "Delete":"Delete",
            "GeoTiff Table":"GeoTiff Table",
            "Возраст от:":"Age from:",
            "Возраст до:":"Age to:",
            "Add to map":"Add to map",
            "Index_geotiff":"Index_geotiff",
            "GeoTiff Name":"GeoTiff Name",
            "GeoTiff Type":"GeoTiff Type",
            "Geo index start":"Geo index start",
            "Geo index end":"Geo index end",
            "Publication Url":"Publication Url",
            "View":"View",
            "Permission_geotiff":"Permission",
            "Delete_geotiff":"Delete",
            "Filter":"Filter",
            "Graphics":"Graphics",
            "Basins":"Basins",
            "Pie":"Pie",
            "Bar":"Bar",
            "Histogram":"Histogram",
            "Bubble":"Bubble",
            "Subbasins":"Subbasins",
            "Wells":"Wells",
            "Fields":"Fields",
            "Licenses":"Licenses",
            "Geo_Objects":"Geo_Objects",
            "Protected_Areas":"Protected_Areas",
            "GeologicMap":"GeologicMap",
            "Values":"Values",
            "value_graph":"Values:",
            "Значение 2:":"Values 2:",
            "Axis":"Axis",

            "X Axis :":"X Axis :",
            "Y Axis :":" Y Axis :",
            "Layer_graph":"Layer:",
            "Graphic type":"Graphic type",
            "Тип графика:":"Graphic type:",
            "Цвет :":"Color :",
            "Цвет":"Color",
            "Размер :":"Size :",
            "Размер":"Size",
            "Символ :":"Symbol :",
            "Символ":"Symbol",
            "Легенда :":"Legend :",
            "Легенда":"Legend",
            "Сравнить:":"Compare:",
            "Compare?":"Compare",
            "Убрать остальные объекты? :":"Remove other objects? :",
            "Only self?":"Only self?",
            "Выбрать Объект:":"Choose object:",
            "Choose object":"Choose object",
            "Построить":"Build",
            "Добавить":"Add",
            "Protected Areas":"Protected Areas",
            "Geo_Objects Significance":"Geo_Objects Significance",
            "Geo_Objects Type":"Geo_Objects Type",
            "Confidence Degree":"Confidence Degree",
            "Data Availability":"Data Availability",
            "Wells Status":"Wells Status",
            "Wells Stock":"Wells Stock",
            "Wells Type":"Wells Type",
            "Operator Name":"Operator Name",
            "Operator ID":"Operator ID",
            "Currency":"Currency",
            "License Status":"License Status",
            "License Type":"License Type",
            "Auction value":"Auction value",
            "Paid value":"Paid value",
            "Discovery Method":"Discovery Method",
            "Development Stage":"Development Stage",
            "Terrain":"Terrain",
            "Location":"Location",
            "Fields Type":"Fields Type",
            "Fields Class":"Fields Class",
            "Data Source":"Data Source",
            "Basin Age":"Basin Age",
            "Basin Subtype":"Basin Subtype",
            "Basin Type":"Basin Type",
            "Basins Class":"Basins Class",
            "Basins Volume":"Basins Volume",
            "Size":"Size",
            "Color":"Color",
            "Symbol":"Symbol",
            "None":"None",
            "Normal":"Normal",
            "Log":"Log",
            "Да":"Yes",
            "Нет":"No",
            "Inside object":"Inside object",
            "Las Files Table":"Las Files Table",
            "Upload":"Upload",
            "Source":"Source",
            "research_name":"Research Name",
            "mnemonic":"Mnemonic",
            "group_name":"Mnemonic Group Name",
            "method":"Method",
            "min_value":"Min Value",
            "max_value":"Max Value",
            "min_depth":"Min Depth",
            "max_depth":"Max Depth",
            "flat":"Flat",
            "outlier":"Outlier",
            "unit":"Unit",
            "Date":"Data",
            "Group":"Group",
            "ГИС подрядчик":"GIS contractor",
            "Choose Filter : ":"Choose Filter : ",
            "Filter ":"Filter ",
            " : From ":" : From ",
            "from":"from",
            " To ":" To ",
            "to":"to",
            "Group by":"Group by",
            "Select groups : ":"Select groups : ",
            "All":"All",
            "Choose Graph Type: ":"Choose Graph Type: ",
            "Reservoir":"Reservoir",
            "Choose Group Params: ":"Choose Group Params: ",
            "Choose X Param: ":"Choose X Param: ",
            "Choose X axis: ":"Choose X axis: ",
            "Choose Y Param: ":"Choose Y Param: ",
            "Choose Y axis: ":"Choose Y axis: ",
            "min":"min",
            " max ":" max ",
            " mean":" mean",
            "Add new publication":"Add new publication",
            "Имя публикации : ":"Publication Name : ",
            "Автор : ":"Author : ",
            "Doi : ":"Doi : ",
            "Год Публикации : ":"Year of Publication : ",
            "Ссылка : ":"Url : ",
            "Теги : ":"Tags : ",
            "Tags":"Tags",
            "Тип публикации : ":"Publication Type : ",
            "Publications Type":"Publications Type",
            "Files to Upload: ":"Files to Upload: ",
            "Drag'n'drop files here, or click to select files.":"Drag'n'drop files here, or click to select files.",
            "Cancel uploading":"Cancel uploading",
            "Upload anyway":"Upload anyway",
            "Add Las FIles":"Add Las FIles",
            "Имя Las Файла : ":"Las File Name : ",
            "Гис Подрядчик : ":"GIS contractor : ",
            "Год : ":"Year : ",
            "Источник : ":"Source : ",
            "Fill required fields":"Fill required fields",
            "Show/Hide Permission Editing":"Show/Hide Permission Editing",
            "f_org_mother_company_orig":"Mother Company",
            "f_infra_obj_infos_managed_org":"Managed Company",
            "":"",




        }
    },
    ru: {
        translation: {
            "f_company_id":"Имя оператора",
            "Свойства":"Свойства",
            "Доп.Свойства.":"Доп.Свойства.",
            "Графики":"Графики",
            "Запасы":"Запасы",
            "Права Доступа":"Права Доступа",
            "Дополнительная информация":"Дополнительная информация",
            "Таблица Дополнительной Информации":"Таблица Дополнительной Информации",
            "Search: ":"Поиск: ",
            "GeoTiff":"Привязанные карты",
            "Las Files":" Las Файлы",
            "Edit Infos":"Править информацию",
            "Original Infos":"Оригинальный Инфос",
            "Last edited by ":"Редактировал : ",
            "Modified ":"Изменено : ",
            "Add as User Objects":"Добавить как пользовательский объект",
            "General object":"Общий объект",
            "User object":"Пользовательский объект",
            "Save":"Сохранить",
            "Edit Geometry":"Редактировать Геометрию",
            "Delete Object":"Удалить Объект",
            "Change Mode":"Изменить режим",
            "Drag'n'drop images here, or click to select files":"Перетащите файл сюда или нажмите для выбора файла",
            "You are not allowed to change permissions on this object":"У вас нету прав на изменения доступа к этому объекту",
            "description":"Описание",
            "basin_name":"Имя",
            "geo_index_start_id":"Гео Индекс Начало",
            "geo_index_end_id":"Гео Индекс конец",
            "basin_type_id":"Тип Бассейна",
            "basin_subtype_id":"Подтип Бассейна",
            "basin_class_id":"Класс",
            "basin_fluid_type_id":"Тип полезных ископаемых",
            "basin_age_id":"Возраст",
            "area":"Площадь",
            "subbasin_name":"Имя",
            "source":"Источник информации",
            "confidence_degree":"Степень уверенности",
            "fields_name":"Название Месторождения",
            "year":"Год",
            "field_class":"Класс Месторождения",
            "location":"Местоположение",
            "field_type":"Тип Месторождения",
            "development_stage":"Стадия разработки",
            "discovery_method":"Метод открытия",
            "terrain":"Местность",
            "discovery_year":"Год открытия",
            "shutdown_year":"Год ликвидации",
            "start_production_year":"Год начала добычи",
            "planed_start_production_year":"Запланированный год начала добычи",
            "max_elevation":"Максимальная альтитуда",
            "max_water_depth":"Максимальная глубина моря",
            "licenses_name":"Имя",
            "lic_nbr":"Номер лицензии",
            "operator_name":"Имя оператора",
            "paid_value":"Оплаченная стоимость",
            "auction_start_payment":"Начальная цена аукциона",
            "cost_difirence_coeficient":"Разница в цене",
            "bid_members":"Участники аукциона",
            "bid_auction_date":"Дата аукциона",
            "auction_link":"Ссылка на аукцион",
            "parent_licenses":"Родительские лицензии",
            "mother_company":"Материнская компания",
            "confidence_degree_id":"Степень уверенности",
            "license_status":"Статус Лицензии",
            "license_type":"Тип Лицензии",
            "issue_date":"Дата выдачи",
            "expiry_date":"Дата окончания",
            "length_2d":"Длина",
            "s_lin_name":"Имя",
            "s_lin_nbr":"Номер",
            "domain_id":"Область",
            "seismic_type_id":"Тип",
            "signal_source_id":"Источник сигнала",
            "field_work_year":"Год полевых работ",
            "processing_year":"Год Обработки",
            "field_operator_name":"Имя оператора",
            "processing_company_name":"Имя компании",
            "rgf_nbr":"Номер РГФ",
            "rgf_report_year":"Год отчета РГФ",
            "rgf_link":"Ссылка РГФ",
            "well_name":"Имя",
            "well_head_altitude":"Альтитуда устья скважины",
            "kb_altitude":"Альтитуда ротора",
            "td_m":"Забой",
            "target_reservoir_id":"Целевой резервуар",
            "uwi":"uwi",
            "alternative_names":"Альтернативные Имена",
            "current_status_id":"Текущий статус",
            "status_start_date":"Дата начала статуса",
            "status_end_date":"дата конца статуса",
            "well_stock_id":"Фонд скважины",
            "well_type_id":"Тип Скважины",
            "object_name":"Имя",
            "outcrop":"Outcrop",
            "object_type":"Тип Объекта",
            "geo_position":"Географическое положение",
            "reference":"Ссылка",
            "straton":"Стратон",
            "info_year":"Год информации",
            "info_source_text":"Источник Информации",
            "info_structure":"Структура Информации",
            "original_name":"Имя",
            "geo_compos":"Геологический состав",
            "geologicmap_name":"Имя",
            "update_date":"Дата изменения",
            "name":"Имя",
            "protected_area_type_id":"Тип Заповедника",
            "profile_create_date":"Дата создания профиля",
            "protected_area_profile_id":"Профиль",
            "org_category":"Категория",
            "f_co2_org_infos_name_rus":"Имя",
            "name_ru":"Имя",
            "storage_type_id":"Тип хранилища",
            "region_name":"Имя",
            "Index":"Индекс",
            "Name":"Имя",
            "Author":"Автор",
            "Doi":"Doi",
            "Url":"Ссылка",
            "Publication Type":"Тип Информации",
            "Publication Tags":"Теги",
            "Year":"Год",
            "Layer":"Слой",
            "Edit":"Редактировать",
            "Permission":"Права доступа",
            "Delete":"Удалить",
            "GeoTiff Table":"Таблица Привязанных Карт",
            "Возраст от:":"Возраст от:",
            "Возраст до:":"Возраст до:",
            "Add to map":"Добавить на карту",
            "Index_geotiff":"Индекс",
            "GeoTiff Name":"Имя Карты",
            "GeoTiff Type":"Тип Карты",
            "Geo index start":"Гео индекс начало",
            "Geo index end":"Гео индекс конец",
            "Publication Url":"Ссылка на публикацию",
            "View":"Предпросмотр",
            "Permission_geotiff":"Права доступа",
            "Delete_geotiff":"Удалить",
            "Filter":"Фильтровать",
            "Graphics":"Графики",
            "Basins":"Басейны",
            "Pie":"Круговая диаграмма",
            "Bar":"Cтолбиковая диаграмма",
            "Histogram":"Гистограмма",
            "Bubble":"Пузырьковая диаграмма",
            "Subbasins":"НГО",
            "Wells":"Скважины",
            "Fields":"Месторождения",
            "Licenses":"Лицензии",
            "Geo_Objects":"Геологические Объекты",
            "Protected_Areas":"Заповедники",
            "GeologicMap":"Геологическая карта",
            "Values":"Значение",
            "value_graph":"Значение:",
            "Значение 2:":"Значение 2:",
            "Axis":"Ось",
            "X Axis :":"Ось X :",
            "Y Axis :":" Ось Y :",
            "Layer_graph":"Слой:",
            "Graphic type":"Тип графика",
            "Тип графика:":"Тип графика:",
            "Цвет :":"Цвет :",
            "Цвет":"Цвет",
            "Размер :":"Размер :",
            "Размер":"Размер",
            "Символ :":"Символ :",
            "Символ":"Символ",
            "Легенда :":"Легенда :",
            "Легенда":"Легенда",
            "Сравнить:":"Сравнить:",
            "Compare?":"Сравнить",
            "Убрать остальные объекты? :":"Убрать остальные объекты? :",
            "Only self?":"",
            "Выбрать Объект:":"Выбрать Объект:",
            "Choose object":"Выбрать Объект",
            "Построить":"Построить",
            "Добавить":"Добавить",
            "Protected Areas":"Заповедник",
            "Geo_Objects Significance":"Важность Гео Обьъекта",
            "Geo_Objects Type":"Тип Гео Объекта",
            "Confidence Degree":"Степень Уверенности",
            "Data Availability":"Доступность Данных",
            "Wells Status":"Статус Скважины",
            "Wells Stock":" Скважины",
            "Wells Type":"Тип Скважины",
            "Operator Name":"Имя Оператора",
            "Operator ID":"ID Оператора",
            "Currency":"Валюта",
            "License Status":"Статус Лицензии",
            "License Type":"Тип Лицензии",
            "Auction value":"Цена Аукциона",
            "Paid value":"Платеж Победителя",
            "Discovery Method":"Метож Обнаружения",
            "Development Stage":"Этап Разработки",
            "Terrain":"Местность",
            "Location":"Местоположение",
            "Fields Type":"Тип Месторождения",
            "Fields Class":"Класс Месторождения",
            "Data Source":"Источник Данных",
            "Basin Age":"Возраст Бассейна",
            "Basin Subtype":"Подтип Бассейна",
            "Basin Type":"Тип Бассейна",
            "Basins Class":"Класс Бассейна",
            "Basins Volume":"Объем Бассейна",
            "Size":"Размер",
            "Color":"Цвет",
            "Symbol":"Символ",
            "None":"Нет",
            "Normal":"Нормальный",
            "Log":"Логарифмический",
            "Да":"Да",
            "Нет":"Нет",
            "Inside object":"Внутри Объекта",
            "Las Files Table":"Таблица Las Файлов",
            "Upload":"Скачать",
            "Source":"Источник",
            "research_name":"Имя Исследования",
            "mnemonic":"Мнемоника",
            "group_name":"Имя Мнемонической Группы",
            "method":"Метод",
            "min_value":"Минимальное Значение",
            "max_value":"Максимальное Значение",
            "min_depth":"Минимальная Глубина",
            "max_depth":"Максимальная Глубина",
            "flat":"Flat",
            "outlier":"Выброс",
            "unit":"Единицы Измерения",
            "Date":"Дата",
            "Group":"Группы",
            "ГИС подрядчик":"ГИС подрядчик",
            "Choose Filter : ":"Выбирите Фильтр",
            "Filter ":"Фильтр ",
            " : From ":" : От ",
            "from":"от",
            " To ":" До ",
            "to":"до",
            "Group by":"Группировка по",
            "Select groups : ":"Выбирите Группы",
            "All":"Все",
            "Choose Graph Type: ":"Выбирите Тип Графика",
            "Reservoir":"",
            "Choose Group Params: ":"Выбирите Группу Параметров",
            "Choose X Param: ":"Выбирите параметр X",
            "Choose X axis: ":"Выбирите Ось X",
            "Choose Y Param: ":"Выбирите параметр Y",
            "Choose Y axis: ":"Выбирите Ось Y",
            "min":"Минимальное",
            " max ":" Максимальное ",
            " mean":" Среднее",
            "Add new publication":"Добавить информацию",
            "Имя публикации : ":"Имя публикации : ",
            "Автор : ":"Автор : ",
            "Doi : ":"Doi : ",
            "Год Публикации : ":"Год Публикации : ",
            "Ссылка : ":"Ссылка : ",
            "Теги : ":"Теги : ",
            "Tags":"Теги",
            "Тип публикации : ":"Тип информации : ",
            "Publications Type":"Тип информации",
            "Files to Upload : ":"Файлы для загрузки : ",
            "Drag'n'drop files here, or click to select files.":"Перетащите файл сюда или нажмите для выбора файла",
            "Cancel uploading":"Отменить загрузка",
            "Upload anyway":"Все равно загружать",
            "Add Las FIles":"Добавить Las Файл",
            "Имя Las Файла : ":"Имя Las Файла : ",
            "Гис Подрядчик : ":"Гис Подрядчик : ",
            "Год : ":"Год : ",
            "Источник : ":"Источник : ",
            "Fill required fields":"Заполните обязательные поля",
            "Show/Hide Permission Editing":"Показать/Скрыть Изменение Прав Доступа",
            "f_org_mother_company_orig":"Материнская компания",
            "f_infra_obj_infos_managed_org":"Управляющая компания"
        }
    }
};


