import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import {Button, Checkbox, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import imageName from "../Interface/blank.png";
import GroupPermissionForObject from "./GroupPermissionForObject";
import '../Styles/Reserves.css'


export default class ReservesSuccessProbability extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:null,
            ProbabilityTypeBase:[{name:'Резервуар (коллектор)',value:null},
                {name:'Ловушка',value:null},
                {name:'Покрышка',value:null},
                {name:'Нефтегазометеринская порода',value:null},
                {name: 'Время и миграция',value:null}],
            ProbabilityType:['Резервуар (коллектор)',
                'Ловушка',
                'Покрышка',
                'Нефтегазометеринская порода',
                'Время и миграция'],
            ProbabilityTypeChoosen:[]


        }
        this.onDropDownClick = this.onDropDownClick.bind(this)
        this.onInputChange = this.onInputChange.bind(this)
    }
    onDropDownClick(e,data) {
        console.log(e,data,this.state.ProbabilityTypeChoosen)
        this.setState({ProbabilityTypeChoosen:data.value})
    };
    onInputChange(name,e,data){
        console.log(name,e,data)
        let ProbabilityTypeBase = this.state.ProbabilityTypeBase;
        if ( data.value >= 0 && data.value <= 1){
            console.log(1)
            for (let  i = 0 ; i < ProbabilityTypeBase.length;i++){
                if (ProbabilityTypeBase[i].name === name){
                    ProbabilityTypeBase[i].value = data.value ;
                    this.setState({ProbabilityTypeBase:ProbabilityTypeBase})
                }
            }
        }else if (data.value <= 0 ){
            console.log(1)
            document.getElementById(name).value = 0
        }else{
            console.log(1)
            document.getElementById(name).value = 1
        }
        let resultProb = 1
        for (let  i = 0 ; i < ProbabilityTypeBase.length;i++){
            if (ProbabilityTypeBase[i].value !== null){
                resultProb = resultProb * ProbabilityTypeBase[i].value;
                this.props.setProbability(resultProb)
            }
        }

    };
    componentDidUpdate() {

    }
    render() {
        return(
            <div style={{display:this.props.display }} >
                <br/>
                <Label className={"size75label1"}>{"Вероятность Геологического Успеха :"}</Label><Dropdown style={{zIndex: 5000,position: "relative"}} className={"size75dropdown"}  onChange={this.onDropDownClick} multiple selection options={this.state.ProbabilityType.map((name)=>{return({key:name,value:name,text:name})})} />
                {this.state.ProbabilityTypeChoosen.map((name)=>{
                    let value = null;
                    for ( let i = 0 ; i < this.state.ProbabilityTypeBase.length;i++){
                        if (this.state.ProbabilityTypeBase[i].name === name){
                            value = this.state.ProbabilityTypeBase[i].value
                        }
                    }
                    return(
                        <>
                            <br/>
                            <Label className={"size75label1"}>{name}</Label>
                            <Input className={"size75input"} id={name} value={value} min="0" max="1" type={'number'}
                                   onChange={(e,data)=>{
                                       this.onInputChange(name,e,data)}
                            }/>
                        </>
                    )
                })}
            </div>
        )
    }
}
