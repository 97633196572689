import React from "react";
import {Button, Dropdown, Icon, Input} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import AddPublications from "./AddPublications";
import AddLegends from "./AddLegends";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { withTranslation } from 'react-i18next';
import {age_period, age_word} from "../VariableInitialize"
import i18n from "../i18n";
import GroupPermissionForObject from "./GroupPermissionForObject";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
const config = require('../config');
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const AddPublicationsTranslate = withTranslation()(AddPublications)
const AddLegendsTranslate = withTranslation()(AddLegends)
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
async function requast(quary){
    return axios.post(urlBackend+'/postQuery', {query:quary}).then(response => {let data = response.data;data = data.push({key:"",text:"------",value:""}); return response.data})
}
var publication_word = requast({type:"publications",select_type:"get_publications_dict",select_params:null}).then(result => {publication_word= result});

var legends_word = requast({type:"get_legends",select_type:"get_legends_dict",select_params:null}).then(result => {legends_word= result});

class GeoTiffLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display:"none",
            mapName:"",
            source:"",
            mapType:"",
            tags:[],
            description:"",
            doi:"",
            error:"",
            wordBasic:age_word.reverse(),
            periodBasic:age_period,
            word:age_word.reverse(),
            period:age_period,
            period2:age_period,
            age_word:age_word,
            value:[0,4600],
            value_from:null,
            value_to:null,
            value_to_state:null,
            value_from_state:null,
            publication:null,
            legend:null,
            PermissionDisplay:"none",
            groupPermArray:[],
            groupPermArrayBasic:[],
            PermissionDisplayIcon:'plus',
            open:false,
            index:0,
        }
    };
    setPermission(id) {
        for (let i=0; i< this.state.groupPermArrayBasic.length;i++){
            let permCode = null;
            if ( this.state.groupPermArray[i].permissions === 'read')
                permCode = 1;
            if ( this.state.groupPermArray[i].permissions === 'change')
                permCode = 2;
            if ( this.state.groupPermArrayBasic[i].permissions === null && this.state.groupPermArray[i].permissions !== null){
                //insert
                console.log("insert from NULL to " + this.state.groupPermArray[i].permissions)
                let PermQuary = {type:"insert_Groups_Permission",select_type:"insert_Groups_Permission_Geotiff",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id,permission:permCode}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }else if ( this.state.groupPermArrayBasic[i].permissions !== null && this.state.groupPermArray[i].permissions === null){
                //delete
                console.log("delete from " + this.state.groupPermArrayBasic[i].permissions)
                let PermQuary = {type:"delete_Groups_Permission",select_type:"delete_Groups_Permission_Geotiff",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }else if ( this.state.groupPermArrayBasic[i].permissions !== null && this.state.groupPermArray[i].permissions !== null && this.state.groupPermArrayBasic[i].permissions !== this.state.groupPermArray[i].permissions){
                //update
                console.log("update from " +this.state.groupPermArrayBasic[i].permissions + " to " + this.state.groupPermArray[i].permissions)
                let PermQuary = {type:"update_Groups_Permission",select_type:"update_Groups_Permission_Geotiff",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id,permission:permCode}}
                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                    .then((res) => {
                        console.log(res)
                    })
                    .catch((error)=>{console.log(error)})
            }
        }
    }
    updatePublicationWord = () =>{
        publication_word = requast({type:"publications",select_type:"get_publications_dict",select_params:null}).then(result => {publication_word= result;this.setState({index:this.state.index++})});
        legends_word = requast({type:"get_legends",select_type:"get_legends_dict",select_params:null}).then(result => {legends_word= result;this.setState({index:this.state.index++})});
    };
    updateLegendsWord = () =>{
        legends_word = requast({type:"get_legends",select_type:"get_legends_dict",select_params:null}).then(result => {legends_word= result;this.setState({index:this.state.index++})});

    };
    componentDidMount() {
        let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:Cookies.get("id")}}
        axios.post(urlBackend+'/postQuery', {query: PermQuary})
            .then((res) => {
                console.log(res)
                this.setState({
                    groupPermArray:res.data[0].get_permission_groups,
                    groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_permission_groups))
                })
            })
            .catch((error)=>{console.log(error)})
    }
    onSelectFrom = (event,{value}) => {
        var res = value.split(" ");
        let obj_value = parseFloat(res[0]);
        let obj_index = res[1];
        let obj_valueTo;
        //console.log(obj_value,obj_index);
        for (let i = 0; i<this.state.age_word.length;i++) {
            //console.log(age_word[i].object_index,obj_index)
            if (this.state.age_word[i].object_index.replace(/\s/g, "") === obj_index.replace(/\s/g, "")) {
                //console.log(i,this.state,age_period);
                obj_valueTo =this.state.age_word[i].age_to;
                //this.setState({period:this.state.period.slice(i)})
            }
        }
        let arr =Array.from(this.state.periodBasic);
        for (let i = 0 ;i<arr.length;i++) {
            let res2 = arr[i].value.split(" ");
            let obj_value = parseFloat(res2[0]);
            if (obj_value < obj_valueTo) {
                arr.splice(i, 1);
                i--;
            }
        }
        console.log(value,obj_value,res[1],res)
        this.setState({period2:arr,value:[obj_value,this.state.value[1]],value_from:res[1],value_from_state:value});
    };
    onSelectTo =(event,{value}) => {
        var res = value.split(" ");
        let obj_value = parseFloat(res[0]);
        console.log(value,obj_value,res[1],res)
        this.setState({value:[this.state.value[0],obj_value],value_to:res[1],value_to_state:value});
    };
    componentDidUpdate(prevProps) {
        console.log("GEOTIFFPERM",this.props.name,prevProps.name)
        if (this.props.name !== prevProps.name) {
            let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:Cookies.get("id")}}
            axios.post(urlBackend+'/postQuery', {query: PermQuary})
                .then((res) => {
                    console.log(res)
                    this.setState({
                        display:"none",
                        mapName:"",
                        source:null,
                        mapType:"",
                        tags:[],
                        description:"",
                        doi:"",
                        wordBasic:age_word.reverse(),
                        periodBasic:age_period,
                        word:age_word.reverse(),
                        period:age_period,
                        period2:age_period,
                        age_word:age_word,
                        value:[0,4600],
                        value_from:null,
                        value_to:null,
                        error:"",
                        publication:null,
                        legend:null,
                        groupPermArray:res.data[0].get_permission_groups,
                        groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_permission_groups))
                    })
                })
                .catch((error)=>{console.log(error)})

        }
    }

            render() {
        console.log(this.props.mapType)
        return(
            <div className="geotiffloader" style={{display:this.props.display}} >
                {this.props.name}
                {(this.props.display === "block") && (
                    <span>
                <br/>
                <Button basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button>
                <br/>
                        <span style={{color:"red"}}>{this.state.error}</span>
                    <div style={{display:"grid"}}>
                        <span >{"Название карты:"}<Input defaultValue={this.state.mapName}  onChange={(e,val) =>{
                            this.setState({mapName:val.value})
                        }}/></span>
                        <span >{"Тип карты:"}<Dropdown
                            style={{zIndex:999999999,overflow: 'inherit'}}
                                options={this.props.mapType}
                                value={this.state.mapType}
                                onChange={(e,val) => {
                                    this.setState({mapType:val.value})
                                }}
                                selection
                                placeholder={"Choose layer to insert"} />
                        </span>
                        <span >{"Описание карты:"}<Input defaultValue={this.state.description}  onChange={(e,val) =>{
                            this.setState({description:val.value})
                        }}/></span>
                        <span style={{display: "flex",marginTop: "10px"}}>{"Публикация:"}<Dropdown
                            search
                            fluid
                            style={{overflow:"visible",zIndex:"9999999"}}
                            options={publication_word}
                            value={this.state.publication}
                            onChange={(e,val) => {
                                console.log(publication_word,val)
                                this.setState({publication:val.value})
                            }}
                            selection
                            placeholder={"Choose publication"} /></span>
                        <br />
                        <span style={{display: "flex",marginTop: "10px"}}>{"Легенды:"}<Dropdown
                            search
                            fluid
                            style={{overflow:"visible",zIndex:"9999998"}}
                            options={legends_word}
                            value={this.state.legend}
                            onChange={(e,val) => {
                                console.log(legends_word,val)
                                this.setState({legend:val.value})
                            }}
                            selection
                            placeholder={"Choose Legend"} /></span>
                        {"Возраст от:"}<Dropdown id={"ChronoDropDown1"} value={this.state.value_from_state} options={this.state.period.reverse()} onChange={this.onSelectFrom} fluid selection placeholder="Выбирите начальный период" />
                        <br/>
                        {"Возраст до:"}<Dropdown id={"ChronoDropDown2"} value={this.state.value_to_state} options={this.state.period2} onChange={this.onSelectTo} fluid selection placeholder="Выбирите конечный период" />
                        <Button basic size={"mini"} onClick={()=>{
                            if (this.state.PermissionDisplay === "none")
                                this.setState({PermissionDisplay:"block",PermissionDisplayIcon:'minus'})
                            else
                                this.setState({PermissionDisplay:"none",PermissionDisplayIcon:'plus'})
                        }}><Icon name={this.state.PermissionDisplayIcon}  />{"Show/Hide Permission Editing"}</Button>
                    <br/>
                    <div style={{display:this.state.PermissionDisplay}}>
                        <GroupPermissionForObject csstop={null} arrayChange={(array)=>{this.setState({groupPermArray:array})}} permArrayBasic={this.state.groupPermArrayBasic} permArray={this.state.groupPermArray} id={null} close={()=>{this.setState({PermissionDisplay:"none"})}} display={this.state.PermissionDisplay} />
                    </div>
                        <AddPublicationsTranslate update={this.updatePublicationWord} word={this.props.word} display={this.props.display} id={null} layer={""}/>
                        <AddLegendsTranslate update={this.updateLegendsWord} word={this.props.word} display={this.props.display} id={null} layer={""}/>
                    </div>
                    - Теги (для быстрого поиска)
                    <br/>
                    <Dialog
                        classes={{ root: 'MenuItem'}}
                        open={this.state.open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={()=>{this.setState({open:false})}}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                            <DialogTitle id="alert-dialog-slide-title">{"Similar File Already exist in DB"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Confirm
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={()=>{this.setState({open:false})}} color="primary">
                                    Disagree
                                </Button>
                                <Button onClick={async()=>{
                                    let doi ;
                                    let border;
                                    let image ;
                                    if (  this.state.mapName !== "" && this.state.description !== "" &&  this.state.mapType !== ""){
                                        for (let i = 0 ; i < this.props.layerlist.length;i++){
                                            if ( this.props.layerlist[i].name === this.props.name){
                                                border = "ST_GeomFromText('POLYGON(("+this.props.layerlist[i].border[0][0]+" "+this.props.layerlist[i].border[0][1]+","+this.props.layerlist[i].border[1][0]+" "+this.props.layerlist[i].border[1][1]+", "+this.props.layerlist[i].border[2][0]+" "+this.props.layerlist[i].border[2][1]+","+this.props.layerlist[i].border[3][0]+" "+this.props.layerlist[i].border[3][1]+","+this.props.layerlist[i].border[0][0]+" "+this.props.layerlist[i].border[0][1]+"))',4326)" ;
                                                image = new File([this.props.layerlist[i].data], this.state.mapName+".png");
                                                if (this.state.doi.includes("https://doi.org/"))
                                                    doi = this.state.doi;
                                                else
                                                    doi = "https://doi.org/"+this.state.doi;
                                                console.log(this.props.layerlist[i],image,this.props.layerlist[i].data)
                                                //console.log(dataform,this.state.selectedFile);
                                                await fetch(this.props.layerlist[i].data).then(r => r.blob()).then(blobFile =>{
                                                    let file =  new File([blobFile], this.props.name.toString().split('.').join('') +".png", { type: "image/png" })
                                                    const dataform = new FormData();
                                                    dataform.append("file",file);
                                                    axios.post(urlBackend+'/layerid', {query:"select exists (select * from geotiff_map where f_geotiff_map_name = '"+this.state.mapName+"' limit 1)"})
                                                        .then((res) => {
                                                            console.log(res.data[0].exists)
                                                            if (res.data[0].exists) {
                                                                this.setState({fileName: this.state.mapName, open: true});
                                                            } else {
                                                                axios.post(urlBackend + '/uploadimages', dataform, {
                                                                    // receive two    parameter endpoint url ,form data
                                                                }).then(res => { // then print response status
                                                                    console.log(res)
                                                                    console.log(urlBackend + '/displayimages?file=' + res.data.filename);
                                                                    this.setState({error: ""});

                                                                    axios.post(urlBackend + '/layerid', {query: "insert into attachments (f_filename,f_mime_type,f_attachment_type_id,f_user_id) values ('" + res.data.filename + "' , 'image/png', 180, " + Cookies.get("id") + ") returning f_attachment_id as id"})
                                                                        .then((res) => {
                                                                            console.log(res.data[0].id)
                                                                            axios.post(urlBackend + '/layerid', {query: "Insert into geotiff_map (f_geotiff_map_name,f_geotiff_map_type_id,f_geotiff_map_source,f_geotiff_map_description,f_attachment_id,f_publication_id,f_legends_id,f_geotiff_map_geometry) values ('" + this.state.mapName + "', " + this.state.mapType + ", '" + this.state.source + "', '" + this.state.description + "' , " + res.data[0].id + " , '" + this.state.publication + "', " + this.state.legend + "', "  + border + " ) RETURNING f_geotiff_map_id;"})
                                                                                .then((res) => {
                                                                                    this.setPermission(res.data[0].f_geotiff_map_id)
                                                                                    this.props.close();
                                                                                    this.props.alert("success", "geotiff successfully loaded.")
                                                                                    console.log(res)
                                                                                }).catch((error) => {
                                                                                console.log(error)
                                                                            });
                                                                        }).catch((error) => {
                                                                        console.log(error)
                                                                    });
                                                                }).catch((error) => {
                                                                    console.log(error)
                                                                });
                                                            }
                                                        })
                                                })

                                            }
                                        }
                                    }
                                    else {
                                        this.setState({error:"Fill all required fields."})
                                    }
                                }} color="primary">
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog>
                    <Button basic size={'small'} onClick={async()=>{
                        let doi ;
                        let border;
                        let image ;
                        if (  this.state.mapName !== ""  &&  this.state.mapType !== ""){
                            for (let i = 0 ; i < this.props.layerlist.length;i++){
                                if ( this.props.layerlist[i].name === this.props.name){
                                    border = "ST_GeomFromText('POLYGON(("+this.props.layerlist[i].border[0][0]+" "+this.props.layerlist[i].border[0][1]+","+this.props.layerlist[i].border[1][0]+" "+this.props.layerlist[i].border[1][1]+", "+this.props.layerlist[i].border[2][0]+" "+this.props.layerlist[i].border[2][1]+","+this.props.layerlist[i].border[3][0]+" "+this.props.layerlist[i].border[3][1]+","+this.props.layerlist[i].border[0][0]+" "+this.props.layerlist[i].border[0][1]+"))',4326)" ;
                                    image = new File([this.props.layerlist[i].data], this.state.mapName+".png");
                                    if (this.state.doi.includes("https://doi.org/"))
                                        doi = this.state.doi;
                                    else
                                        doi = "https://doi.org/"+this.state.doi;
                                    console.log(this.props.layerlist[i],image,this.props.layerlist[i].data)


                                    //console.log(dataform,this.state.selectedFile);
                                    await fetch(this.props.layerlist[i].data).then(r => r.blob()).then(blobFile =>{
                                        let file =  new File([blobFile], this.props.name.toString().split('.').join('') +".png", { type: "image/png" })
                                        const dataform = new FormData();
                                        dataform.append("file",file);
                                        axios.post(urlBackend+'/checkFile', dataform, {
                                            // receive two    parameter endpoint url ,form data
                                        }).then(res => { // then print response status
                                            console.log(res)
                                            console.log(urlBackend+'/displayimages?file='+res.data.filename);
                                            if (res.data.old !== undefined && res.data.old === true){
                                                let resultFileName = res.data.filename;
                                                axios.post(urlBackend+'/postQuery', {query: {type:"attachments",select_type:"exists",select_params:{
                                                            filename:res.data.filename,
                                                        }}})
                                                    .then((res2) => {
                                                        console.log(res2)
                                                        if (res2.data[0].exists !== undefined && !res2.data[0].exists){
                                                            axios.post(urlBackend+'/postQuery', {query: {type:"geotiff_insert",select_type:"geotiff_insert",select_params:{
                                                                        description:this.state.description,
                                                                        source:this.state.source,
                                                                        publication_id:this.state.publication,
                                                                        legends_id:this.state.legend,
                                                                        map_name:this.state.mapName,
                                                                        map_type:this.state.mapType,
                                                                        value_from:this.state.value_from,
                                                                        value_to:this.state.value_to,
                                                                        border:border,
                                                                        user_id:Cookies.get("id"),
                                                                        file_name:resultFileName
                                                                    }}})
                                                                .then((res) => {
                                                                    this.setPermission(res.data[0].f_geotiff_map_id)
                                                                    this.props.close();
                                                                    this.props.alert("success","geotiff successfully loaded.")
                                                                    console.log(res)
                                                                }).catch((error) => {
                                                                console.log(error)
                                                            });
                                                        }else{
                                                            this.setState({error:"That geotiff already exist in DB"});
                                                            this.props.alert("info","That geotiff already exist in Database.")
                                                        }
                                                    })
                                                    .catch((error) => {console.log(error);})
                                            }
                                            else{
                                                console.log("GeoTiffLoader2")
                                                let resultFileName = res.data.filename;
                                                axios.post(urlBackend+'/postQuery', {query: {type:"geotiff_insert",select_type:"geotiff_insert",select_params:{
                                                            description:this.state.description,
                                                            source:this.state.source,
                                                            publication_id:this.state.publication,
                                                            legends_id:this.state.legend,
                                                            map_name:this.state.mapName,
                                                            map_type:this.state.mapType,
                                                            value_from:this.state.value_from,
                                                            value_to:this.state.value_to,
                                                            border:border,
                                                            user_id:Cookies.get("id"),
                                                            file_name:resultFileName
                                                        }}})
                                                    .then((res) => {
                                                        this.setPermission(res.data[0].f_geotiff_map_id)
                                                        this.props.close();
                                                        this.props.alert("success","geotiff successfully loaded.")
                                                        console.log(res)
                                                    }).catch((error) => {
                                                    console.log(error)
                                                });
                                            }
                                        }).catch((error) => {
                                            console.log(error)
                                        });
                                    })

                                }
                            }
                        }
                        else {
                            this.setState({error:"Fill all required fields."})
                        }
                    }} icon>Send</Button>
                        </span>
                    )}
            </div>
        )}}
export default GeoTiffLoader;
