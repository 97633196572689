import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import {isMobile} from "react-device-detect";
import {Helmet} from "react-helmet";
import * as turf from '@turf/turf'
import {Button, Checkbox, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import {
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton, VKIcon, VKShareButton
} from "react-share";
import DatePicker from "react-datepicker";
import i18n from "../../i18n";
import * as htmlToImage from 'html-to-image';
import * as jspdf from "jspdf";
import Plot from "react-plotly.js";
import GroupPermissionForObject from "../GroupPermissionForObject";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import UpdatePublications from "../UpdatePublications";
import Dropzone from "react-dropzone";
import JoditEditor from "jodit-react";
import AddPublications from "../AddPublications";
import AddFiles from "../AddFiles";
import {companies_dict,world_source_status, world_source_formation, world_source_reservoir, world_source_storage, basin_age_word, basin_fluid_word, basin_type_word, development_stage_word, location_word, terrain_word, protected_areas_word, protected_areas_profile_word, confidence_degree_word, licenses_status_word,
    licenses_type_word, discovery_method_word, field_type_word, signal_source_word, domain_word, seismic_type_word, field_class_word, basin_subtype_word,  well_status_word, well_type_word,
    well_stock_word, basin_class_word, outcrop_word, age_from_word, age_to_word, object_type_word, urlBackend, urlMartin, tags_word, publication_type_word, georefApi,tectonic_morpho_type_word,tectonic_order_word,tectonic_type_word} from "../../VariableInitialize";
import {Transition} from "../../functions";
import {withTranslation} from "react-i18next";
import FeatureInfosGraphs from "./FeatureInfosGraphs";
import FeatureInfosGeoTiffs from "./FeatureInfosGeoTiffs";
import UploadLas from "../UploadLas";
import FeatureInfosReserves from "./FeatureInfosReserves";
import FeatureInfosLasFiles from "./FeatureInfosLasFiles";
//<FeatureInfos avgWinValue={avgWinValue} maxWinValue={maxWinValue} result={result} LayerOptions={LayerOptions} UserOptions={UserOptions} userdataname={userdataname} data={data} presetdata={presetdata} dataname={dataname} data2={data2}
const AddFilesTranslate = withTranslation()(AddFiles)
const AddPublicationsTranslate = withTranslation()(AddPublications)

const RenderRow = (props) =>{
    return props.keys.map((key, index)=>{
        return <td key={props.data[key]}><div style={{ maxHeight:150, overflow:"auto"}}>{props.data[key]}</div></td>
    })
};
class FeatureInfos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            desc: this.props.desc,
            infos: this.props.infos,
            display: this.props.display,
            infosEdit:false,
            PermissionDisplay:"none",
            groupPermArray:[],
            groupPermArrayBasic:[],
            PermissionOwnObject:false,
            propDisplay:"none",
            graphDisplay:"none",
            Graphics:null,
            GraphicsDisplay:"none",
            GraphicsType:null,
            GraphicsSize:null,
            GraphicsColor:null,
            GraphicsSymbol:null,
            GraphicsLegend:null,
            GraphicsTypeArray:[{value:'pie',text:'Pie',key:'pie'},{value:"bar",key:"bar",text:"Bar"},{value:"histogram",key:"histogram",text:"Histogram"},{value:"bubble",key:"bubble",text:"Bubble"}],
            GraphicsLayer:"",
            GraphicsXAxisType:true,
            GraphicsYAxisType:true,
            GraphicsSizeSymbol:true,
            GraphicsSizeInput:5,
            GraphicsAxisLayout:null,
            GraphicsDict:"universal_dict",
            GraphicsLayerArray:[{value:'v_basins',text:'Basins',key:'Basins'},{value:'v_subbasins',text:'Subbasins',key:'Subbasins'},{value:'v_wells',text:'Wells',key:'Wells'},{value:'v_fields',text:'Fields',key:'Fields'},{value:'v_licenses',text:'Licenses',key:'Licenses'},{value:'v_geo_objects',text:'Geo_Objects',key:'Geo_Objects'},{value:'v_protected_areas',text:'Protected_Areas',key:'Protected_Areas'},{value:'v_geologicmap',text:'GeologicMap',key:'GeologicMap'}],
            GraphicsRow:null,
            GraphicsRow2:null,
            GraphicsError:null,
            GraphicsRowArray:[],
            GraphicsRowArrayNumber:[],
            GraphicsRowArrayWord:[],
            GraphicsRowDict:null,
            GraphicsCompare:false,
            GraphicsCompareArray:[],
            AddProperties:'',
            AddPropertiesLoading:false,
            co2_type:"pie",
            co2_rosneft:false,
            ReservesGraph:{},
            GraphicsCompareObject:[],
            GraphicsCompareObjectName:[],
            GraphicsOnlySelf:false,
            publicationDisplay:"none",
            PublicationsGroupPermDisplay:false,
            PublicationsGroupPermCssTop:0,
            PublicationsObjectID:null,
            PublicationsGroupPermArray:[{}],
            PublicationsGroupPermArrayBasic:[{}],
            filesDisplay:"none",
            filesSearch:"",
            filesDeleteID:null,
            files:[{}],
            filesSort:true,
            publicationSearch:"",
            publicationDeleteID:null,
            geoTiffDisplay:"none",
            geoTiffWait:false,
            imageDisplay:"none",
            resultdel:false,
            open:false,
            deleteObjectOpen:false,
            editOpen:false,
            editID:null,
            editName:"",
            editAuthor:"",
            editDOI:"",
            editYear:null,
            editUrl:"",
            editTags:[],
            editType:"",
            geotiffArray:[{}],
            trigger:false,
            saveActive:false,
            inc:0,
            layout:{},
            graphdata:[],
            userObjectButton:true,
            publicationError:"",
            publication :[{}],
            radioCheck:"image",
            publicationType:"",
            publicationName:"",
            PublicationFiles:null,
            publicationSort:true,
            graphTrig:false,
            config : {
                readonly: true, // all options from https://xdsoft.net/jodit/doc/
                toolbar:false,
                iframe:true,
                iframeStyle: 'html{overflow-y: scroll!important}',
                useIframeResizer: false,
                openInNewTabCheckbox: true,
            },
            editor : React.createRef(null),
            array: [],
            url:"",
            id:'0'
        };
        this.getHeader = this.getHeader.bind(this);
        this.getRowsData = this.getRowsData.bind(this);
        this.getKeys = this.getKeys.bind(this);
        this.getKeysProp = this.getKeysProp.bind(this);
        this.getKeys2 = this.getKeys2.bind(this);
        this.getValues = this.getValues.bind(this);
        this.getValuesProp = this.getValuesProp.bind(this);
        this.getValues2 = this.getValues2.bind(this);
        this.updatePublication=this.updatePublication.bind(this);
        this.updateFiles=this.updateFiles.bind(this);
        this.onSort = this.onSort.bind(this)
    }
    getKeys = function(){
        let infos =Object.assign({}, this.state.infos[0]);
        return Object.keys(infos);
    };
    getKeysProp = function(val){
        let infos =Object.assign({}, val[0]);
        return Object.keys(infos);
    };
    handleRadioChange = (e, { value }) => this.setState({ radioCheck:value });
    getKeys2 = function(){
        if (this.state.infos[0] !== undefined){
            let infos = this.getKeys();
            let result = [];
            infos.map((key, index)=>{
                if (key === "description")
                    return result.push({title:key,field:key,cellStyle: {display:'block',maxHeight:200,width:150, overflow:"auto"}});
                else
                    return result.push({title:key,field:key});
            });
            // console.log(result);
            return result;}
    };
    getValues = function() {
        let infos =Object.assign({}, this.state.infos[0]);;
        return Object.values(infos);
    };
    getValuesProp = function(val) {
        let infos =Object.assign({}, val[0]);;
        return Object.values(infos);
    };
    getValues2 = function() {
        let infos =Object.assign({}, this.state.infos[0]);;
        return [infos];
    };
    getHeader = function(){
        var keys = this.getKeys();
        return keys.map((key, index)=>{
            return <th key={key}>{key.toUpperCase()}</th>
        })
    };
    updatePublication(id,layer) {
        console.log(this.state,id,this.state.id);
        //let publicationQuary = "select f_filename as FileName,(select f_publication_name from publications where f_attachment_id=attachments.f_attachment_id) as Name,(select f_publication_year from publications where f_attachment_id=attachments.f_attachment_id)  as UploadDate,(select name_ru from universal_dict where universal_dict_id = (select f_publication_type_id from publications where f_attachment_id=attachments.f_attachment_id) ) as Type,f_user_id as User,f_attachment_id as ID,(select f_publication_url from publications where f_attachment_id=attachments.f_attachment_id) as url ,(select f_publication_doi from publications where f_attachment_id=attachments.f_attachment_id) as doi,(select f_publication_author from publications where f_attachment_id=attachments.f_attachment_id) as author from attachments where f_attachment_type_id = 181 AND f_object_id = " +this.state.id;
        let publicationQuary = {type:"get_publications",select_type:"get_publications",select_params:{id:this.state.array[1].value,layer:layer,user_id:Cookies.get('id')}}
        console.log(layer,publicationQuary)
        axios.post(urlBackend+'/postQuery', {query:publicationQuary})
            .then((res) => {
                this.setState({publicationDisplay:"block",publication :res.data})
            }).catch((error) => {
            console.log(error)
        });
    };
    updateFiles(id,layer){
        let filesQuary = {type:"get_files",select_type:"get_files",select_params:{id:id,user_id:Cookies.get("id"),layer:layer}}
        console.log(layer,filesQuary)
        axios.post(urlBackend+'/postQuery', {query:filesQuary})
            .then((res) => {
                console.log(res.data);
                this.setState({filesDisplay:"block",files :res.data})
            }).catch((error) => {
            console.log(error)
        });
    };
    onSort(sortKey){
        //Publication
        const data = this.state.publication;
        console.log(data);
        data.sort((a,b) => {

            console.log(a[sortKey],b[sortKey])
            if (this.state.publicationSort) {
                if (!a[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return +1;
                }

                if (!b[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return -1;
                }
                return a[sortKey].toString().localeCompare(b[sortKey].toString());
            }else {
                if (!a[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return -1;
                }

                if (!b[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return +1;
                }
                return b[sortKey].toString().localeCompare(a[sortKey].toString());
            }
        })
        this.setState({publication:data,publicationSort:!this.state.publicationSort})
    }
    getRowsData = function(){
        let items = this.getKeys();
        var keys = this.getKeys();
        return items.map((row, index)=>{
            return <tr key={index}><RenderRow key={index} data={row} keys={keys}/></tr>
        })
    };
    get_co2_graphs(layer_id,mode) {
            let query = {type:"get_co2_orgs_infos",select_type:"get_in_radius",select_params:{object_id:layer_id}}
        if (mode === 'rosneft')
            query = {type:"get_co2_orgs_infos",select_type:"get_in_radius_rosneft",select_params:{object_id:layer_id}}
            axios.post(urlBackend+'/postQuery', {query: query})
                .then((res) => {
                    console.log(res)
                    let emissions = {sum:0,data:[],graph:[],graph2:[],graphLayout:{},graphLayout2:{}};
                    let sum = 0
                    let sumtorch = 0
                    let colors = [  'Salmon', "purple", "DarkKhaki", "teal", "yellow", "crimson","orange", "gold", "red",'Lavender', 'Indigo',"green",  'olive', 'Brown', "black"];
                    let values = [];
                    let dataSunBurst = {operator:[],emissions_value:[],emissions_valuetorch:[]};
                    let dataSunBurstCat = {emissions_value:[],emissions_value_sub:[],category:[],subcategory:[]};
                    let labelsSunBurstCat = [];
                    let valuesSunBurstCat = [];
                    let valuesSunBurstParentsCat = [];
                    let labelsSunBurst = [];
                    let valuesSunBurst = [];
                    let valuesSunBurstParents = [];
                    let labelsSunBurst2 = [];
                    let valuesSunBurst2 = [];
                    let valuesSunBurstParents2 = [];
                    let values2 = [];
                    let labels = [];
                    let labels2 = [];
                    let width = 500;
                    if (this.state.co2_type === "pie")
                        width = 400
                    let graphLayout = {
                        height: 800,
                        width: width,
                        "hmaxheight":200,
                        showlegend: true,
                        legend: {
                            "orientation": "h",
                            "hmaxheight":100
                        }
                    };let graphLayout2 = {
                        height: 800,
                        width: width,
                        "hmaxheight":200,
                        showlegend: true,
                        legend: {
                            "orientation": "h",
                            "hmaxheight":100
                        }
                    };

                    /*{
                    height: 800,
                    width: 400,
                    //margin: {
                    //    b: 440,
                    //},
                    "hmaxheight":200,
                    legend: {
                        "orientation": "h",
                        "hmaxheight":100
                    }
                };

                     */

                    for ( let i = 0 ; i < res.data.length ;i++){
                        sum = sum + Number.parseInt(res.data[i].emissions_value)
                        if (res.data[i].emissions_valuetorch !== null)
                            sumtorch = sumtorch + Number.parseInt(res.data[i].emissions_valuetorch)
                        values.push(Number.parseInt(res.data[i].emissions_value))
                        values2.push(res.data[i].category)
                        labels.push(res.data[i].operator)
                        if (dataSunBurstCat.category.includes(res.data[i].category)){
                            let index = dataSunBurstCat.category.indexOf(res.data[i].category);
                            dataSunBurstCat.emissions_value[index] = dataSunBurstCat.emissions_value[index] + Number.parseInt(res.data[i].emissions_value);
                            if (res.data[i].subcategory !== null){
                                if (dataSunBurstCat.subcategory[index].includes(res.data[i].subcategory)){
                                    let index_sub = dataSunBurstCat.subcategory[index].indexOf(res.data[i].subcategory);
                                    dataSunBurstCat.emissions_value_sub[index][index_sub] = dataSunBurstCat.emissions_value_sub[index][index_sub] + Number.parseInt(res.data[i].emissions_value);
                                } else {
                                    dataSunBurstCat.subcategory[index].push(res.data[i].subcategory)
                                    dataSunBurstCat.emissions_value_sub[index].push(Number.parseInt(res.data[i].emissions_value))
                                }
                            }
                        }else{
                            dataSunBurstCat.emissions_value.push(Number.parseInt(res.data[i].emissions_value))
                            dataSunBurstCat.category.push(res.data[i].category)
                            dataSunBurstCat.subcategory.push([])
                            dataSunBurstCat.emissions_value_sub.push([])
                            let index = dataSunBurstCat.category.indexOf(res.data[i].category)
                            if (res.data[i].subcategory !== null){
                                dataSunBurstCat.subcategory[index].push(res.data[i].subcategory)
                                dataSunBurstCat.emissions_value_sub[index].push(Number.parseInt(res.data[i].emissions_value))
                            }
                        }
                        if (dataSunBurst.operator.includes( res.data[i].operator) ){
                            let index = dataSunBurst.operator.indexOf(res.data[i].operator);
                            dataSunBurst.emissions_value[index]=(Number.parseInt(dataSunBurst.emissions_value[index]) + Number.parseInt(res.data[i].emissions_value))
                            if (res.data[i].emissions_valuetorch !== null){
                                dataSunBurst.emissions_valuetorch[index]=(Number.parseInt(dataSunBurst.emissions_valuetorch[index]) + Number.parseInt(res.data[i].emissions_valuetorch))
                            }

                        }else{
                            dataSunBurst.operator.push(res.data[i].operator)
                            dataSunBurst.emissions_value.push(Number.parseInt(res.data[i].emissions_value))

                            if (res.data[i].emissions_valuetorch === null)
                                dataSunBurst.emissions_valuetorch.push(0)
                            else
                                dataSunBurst.emissions_valuetorch.push(Number.parseInt(res.data[i].emissions_valuetorch))
                        }
                    }
                    console.log(dataSunBurst,dataSunBurstCat)
                    labelsSunBurst.push("All emissions")
                    labelsSunBurst2.push("All emissions")
                    valuesSunBurst.push(sum)
                    valuesSunBurst2.push(sum)
                    valuesSunBurstParents.push("")
                    valuesSunBurstParents2.push("")
                    labelsSunBurstCat.push("All emissions")
                    valuesSunBurstCat.push(sum)
                    valuesSunBurstParentsCat.push("")
                    for ( let i = 0 ; i < dataSunBurstCat.category.length ; i++){
                        labelsSunBurstCat.push(dataSunBurstCat.category[i])
                        valuesSunBurstCat.push(dataSunBurstCat.emissions_value[i])
                        valuesSunBurstParentsCat.push("All emissions")
                        if (dataSunBurstCat.subcategory.length > 0){
                            for ( let j = 0 ; j < dataSunBurstCat.subcategory.length; j++){
                                labelsSunBurstCat.push(dataSunBurstCat.subcategory[i][j])
                                valuesSunBurstCat.push(dataSunBurstCat.emissions_value_sub[i][j])
                                valuesSunBurstParentsCat.push(dataSunBurstCat.category[i])
                            }
                        }
                    }
                    for ( let i = 0 ; i < dataSunBurst.operator.length ; i++){
                        labelsSunBurst.push(dataSunBurst.operator[i].replaceAll('"',''))
                        labelsSunBurst2.push(dataSunBurst.operator[i].replaceAll('"',''))
                        valuesSunBurst.push(dataSunBurst.emissions_value[i])
                        valuesSunBurst2.push(dataSunBurst.emissions_value[i])
                        valuesSunBurstParents.push("All emissions")
                        valuesSunBurstParents2.push("All emissions")
                        labelsSunBurst.push(dataSunBurst.operator[i].replaceAll('"','') + " flare emissions")
                        labelsSunBurst2.push(dataSunBurst.operator[i].replaceAll('"','') + " flare emissions")
                        if (dataSunBurst.emissions_valuetorch[i] !== null){
                            valuesSunBurst.push(dataSunBurst.emissions_valuetorch[i])
                            valuesSunBurst2.push(dataSunBurst.emissions_valuetorch[i])
                        }else{
                            valuesSunBurst.push(0)
                            valuesSunBurst2.push(0)
                        }
                        valuesSunBurstParents.push(dataSunBurst.operator[i].replaceAll('"',''))
                        valuesSunBurstParents2.push(dataSunBurst.operator[i].replaceAll('"',''))
                        labelsSunBurst2.push(dataSunBurst.operator[i].replaceAll('"','') + " other emissions")
                        if (dataSunBurst.emissions_valuetorch[i] === null)
                            valuesSunBurst2.push( dataSunBurst.emissions_value[i])
                        else
                            valuesSunBurst2.push(dataSunBurst.emissions_value[i] - dataSunBurst.emissions_valuetorch[i])
                        valuesSunBurstParents2.push(dataSunBurst.operator[i].replaceAll('"',''))
                    }

                    let set = new Set(values2)
                    set = Array.from(set)
                    let graphLabel = [];
                    let graphValue = []
                    for (let i = 0 ; i < set.length ; i++){
                        let count = 0;
                        for (let j = 0 ; j <values2.length;j++){
                            if ( set[i] === values2[j]){
                                count++;
                            }

                        }
                        graphLabel.push(set[i])
                        graphValue.push(count)
                    }
                    let set2 = new Set(labels)
                    set2 = Array.from(set2)
                    if ( graphLabel.length > 20)
                        graphLayout2.height = 800
                    else
                        graphLayout2.height = 400 + 20*graphLabel.length
                    if ( set2.length > 20)
                        graphLayout.height = 800
                    else
                        graphLayout.height = 400 + 20*set2.length


                    //sunburst
                    if (this.state.co2_type === "sunburst")
                        graphLayout = graphLayout2;



                    emissions.sumtorch = sumtorch;
                    emissions.sum = sum;
                    emissions.data = res.data
                    console.log(set,graphLabel,graphValue)
                    emissions.graphLayout = graphLayout;
                    emissions.graphLayout2 = graphLayout2;
                    if (this.state.co2_type === "pie"){
                        emissions.graph = [{
                            values: values,
                            labels: labels,
                            type: 'pie',
                            hole: .8,
                        }];

                        emissions.graph2 =
                            [{
                                values: values,
                                labels: values2,
                                marker: {
                                    colors: colors
                                },
                                type: 'pie',
                                hole: .8,
                            }];

                    }else{
                        emissions.graph = [
                            {
                                "type": "sunburst",
                                "labels": labelsSunBurst2,
                                "parents": valuesSunBurstParents2,
                                "values":  valuesSunBurst2,
                                //"leaf": {"opacity": 0.4},
                                "marker": {"line": {"width": 1}},
                                "textposition": 'inside',
                                "insidetextorientation": 'radial',
                                "branchvalues": 'total'
                            }]

                        console.log(emissions.graph)
                        /*[{
                            values: values,
                            labels: labels,
                            type: 'pie',
                            hole: .8,
                        }];

                         */
                        emissions.graph2 =
                            [
                                {
                                    "type": "sunburst",
                                    "labels": labelsSunBurstCat,
                                    "parents": valuesSunBurstParentsCat,
                                    "values":  valuesSunBurstCat,
                                    //"leaf": {"opacity": 0.4},
                                    "marker": {"line": {"width": 1}},
                                    "textposition": 'inside',
                                    "insidetextorientation": 'radial',
                                    "branchvalues": 'total'
                                }]
                        /*
                        [{
                            values: values,
                            labels: values2,
                            marker: {
                                colors: colors
                            },
                            type: 'pie',
                            hole: .8,
                        }];

                         */
                    }


                    console.log(values,labels,values2)
                    axios.post(urlBackend+'/postQuery', {query:{type:"get_co2_orgs_infos",select_type:"get_point",select_params:{object_id:layer_id}} })
                        .then((res2) => {
                            console.log(res2.data[0].lat,res2.data[0].lon,res2)
                            var radius = 250;
                            var center = [Number.parseFloat(res2.data[0].lat), Number.parseFloat(res2.data[0].lon)];
                            var options = { steps: 50, units: "kilometers", properties: { foo: "bar" } };
                            var circle = turf.circle(center, radius, options);
                            console.log(circle)
                            this.props.emissions(circle)
                            this.setState({AddPropertiesLoading:false,AddProperties:emissions});
                        }).catch((error) => {
                        console.log(error)
                    });
                }).catch((error) => {
                console.log(error)
            });
    }
    get_infra_graphs(layer_id,mode) {
        console.log(layer_id)
        let query = {type:"get_infra_obj_infos",select_type:"get_in_radius",select_params:{object_id:layer_id}}
        if (mode === 'rosneft')
            query = {type:"get_infra_obj_infos",select_type:"get_in_radius_all_rosneft",select_params:{object_id:layer_id}}
        if (mode === 'all_rosneft')
            query = {type:"get_infra_obj_infos",select_type:"get_in_radius_rosneft",select_params:{object_id:layer_id}}
        axios.post(urlBackend+'/postQuery', {query: query})
            .then((res) => {
                console.log(res)
                function removeNull(array) {
                    return array.filter(x => x !== null)
                }
                let emissions = {sum:0,data:[],graph:[],graph2:[],graphLayout:{},graphLayout2:{}};
                let sum = 0
                let sumtorch = 0
                let colors = [  'Salmon', "purple", "DarkKhaki", "teal", "yellow", "crimson","orange", "gold", "red",'Lavender', 'Indigo',"green",  'olive', 'Brown', "black"];
                let values = [];
                let dataSunBurst = {operator:[],emissions_value:[],emissions_valuetorch:[]};
                let dataSunBurstCat = {emissions_value:[],emissions_value_sub:[],category:[],subcategory:[]};
                let labelsSunBurstCat = [];
                let valuesSunBurstCat = [];
                let valuesSunBurstParentsCat = [];
                let labelsSunBurst = [];
                let valuesSunBurst = [];
                let valuesSunBurstParents = [];
                let labelsSunBurst2 = [];
                let valuesSunBurst2 = [];
                let valuesSunBurstParents2 = [];
                let values2 = [];
                let labels = [];
                let labels2 = [];
                let width = 500;
                if (this.state.co2_type === "pie")
                    width = 400
                let graphLayout = {
                    height: 800,
                    width: width,
                    "hmaxheight":200,
                    showlegend: true,
                    legend: {
                        "orientation": "h",
                        "hmaxheight":100
                    }
                };let graphLayout2 = {
                    height: 800,
                    width: width,
                    "hmaxheight":200,
                    showlegend: true,
                    legend: {
                        "orientation": "h",
                        "hmaxheight":100
                    }
                };

                /*{
                height: 800,
                width: 400,
                //margin: {
                //    b: 440,
                //},
                "hmaxheight":200,
                legend: {
                    "orientation": "h",
                    "hmaxheight":100
                }
            };

                 */

                for ( let i = 0 ; i < res.data.length ;i++){
                    //console.log(res.data[i].emissions_value,Number.parseInt(res.data[i].emissions_value))
                    if (res.data[i].emissions_value !== null)
                    sum = sum + Number.parseInt(res.data[i].emissions_value)
                    if (res.data[i].emissions_valuetorch !== null)
                        sumtorch = sumtorch + Number.parseInt(res.data[i].emissions_valuetorch)
                    values.push(Number.parseInt(res.data[i].emissions_value))
                    values2.push(res.data[i].category)
                    labels.push(res.data[i].operator)
                    if (dataSunBurstCat.category.includes(res.data[i].category)){
                        let index = dataSunBurstCat.category.indexOf(res.data[i].category);
                        dataSunBurstCat.emissions_value[index] = dataSunBurstCat.emissions_value[index] + Number.parseInt(res.data[i].emissions_value);
                        if (res.data[i].subcategory !== null){
                            if (dataSunBurstCat.subcategory[index].includes(res.data[i].subcategory)){
                                let index_sub = dataSunBurstCat.subcategory[index].indexOf(res.data[i].subcategory);
                                dataSunBurstCat.emissions_value_sub[index][index_sub] = dataSunBurstCat.emissions_value_sub[index][index_sub] + Number.parseInt(res.data[i].emissions_value);
                            } else {
                                dataSunBurstCat.subcategory[index].push(res.data[i].subcategory)
                                dataSunBurstCat.emissions_value_sub[index].push(Number.parseInt(res.data[i].emissions_value))
                            }
                        }
                    }else{
                        dataSunBurstCat.emissions_value.push(Number.parseInt(res.data[i].emissions_value))
                        dataSunBurstCat.category.push(res.data[i].category)
                        dataSunBurstCat.subcategory.push([])
                        dataSunBurstCat.emissions_value_sub.push([])
                        let index = dataSunBurstCat.category.indexOf(res.data[i].category)
                        if (res.data[i].subcategory !== null){
                            dataSunBurstCat.subcategory[index].push(res.data[i].subcategory)
                            dataSunBurstCat.emissions_value_sub[index].push(Number.parseInt(res.data[i].emissions_value))
                        }
                    }
                    if (dataSunBurst.operator.includes( res.data[i].operator) ){
                        let index = dataSunBurst.operator.indexOf(res.data[i].operator);
                        dataSunBurst.emissions_value[index]=(Number.parseInt(dataSunBurst.emissions_value[index]) + Number.parseInt(res.data[i].emissions_value))
                        if (res.data[i].emissions_valuetorch !== null){
                            dataSunBurst.emissions_valuetorch[index]=(Number.parseInt(dataSunBurst.emissions_valuetorch[index]) + Number.parseInt(res.data[i].emissions_valuetorch))
                        }

                    }else{
                        dataSunBurst.operator.push(res.data[i].operator)
                        dataSunBurst.emissions_value.push(Number.parseInt(res.data[i].emissions_value))

                        if (res.data[i].emissions_valuetorch === null)
                            dataSunBurst.emissions_valuetorch.push(0)
                        else
                            dataSunBurst.emissions_valuetorch.push(Number.parseInt(res.data[i].emissions_valuetorch))
                    }
                }
                console.log(dataSunBurst,dataSunBurstCat)
                labelsSunBurst.push("All emissions")
                labelsSunBurst2.push("All emissions")
                valuesSunBurst.push(sum)
                valuesSunBurst2.push(sum)
                valuesSunBurstParents.push("")
                valuesSunBurstParents2.push("")
                labelsSunBurstCat.push("All emissions")
                valuesSunBurstCat.push(sum)
                valuesSunBurstParentsCat.push("")
                for ( let i = 0 ; i < dataSunBurstCat.category.length ; i++){
                    labelsSunBurstCat.push(dataSunBurstCat.category[i])
                    valuesSunBurstCat.push(dataSunBurstCat.emissions_value[i])
                    valuesSunBurstParentsCat.push("All emissions")
                    if (dataSunBurstCat.subcategory.length > 0){
                        for ( let j = 0 ; j < dataSunBurstCat.subcategory.length; j++){
                            labelsSunBurstCat.push(dataSunBurstCat.subcategory[i][j])
                            valuesSunBurstCat.push(dataSunBurstCat.emissions_value_sub[i][j])
                            valuesSunBurstParentsCat.push(dataSunBurstCat.category[i])
                        }
                    }
                }
                for ( let i = 0 ; i < dataSunBurst.operator.length ; i++){
                    if (dataSunBurst.operator[i] !== null )
                        labelsSunBurst.push(dataSunBurst.operator[i].replaceAll('"',''))
                    if (dataSunBurst.operator[i] !== null )
                        labelsSunBurst2.push(dataSunBurst.operator[i].replaceAll('"',''))
                    valuesSunBurst.push(dataSunBurst.emissions_value[i])
                    valuesSunBurst2.push(dataSunBurst.emissions_value[i])
                    valuesSunBurstParents.push("All emissions")
                    valuesSunBurstParents2.push("All emissions")
                    if (dataSunBurst.operator[i] !== null)
                        labelsSunBurst.push(dataSunBurst.operator[i].replaceAll('"','') + " flare emissions")
                    if (dataSunBurst.operator[i] !== null)
                        labelsSunBurst2.push(dataSunBurst.operator[i].replaceAll('"','') + " flare emissions")
                    if (dataSunBurst.emissions_valuetorch[i] !== null){
                        valuesSunBurst.push(dataSunBurst.emissions_valuetorch[i])
                        valuesSunBurst2.push(dataSunBurst.emissions_valuetorch[i])
                    }else{
                        valuesSunBurst.push(0)
                        valuesSunBurst2.push(0)
                    }
                    if (dataSunBurst.operator[i] !== null )
                        valuesSunBurstParents.push(dataSunBurst.operator[i].replaceAll('"',''))
                    if (dataSunBurst.operator[i] !== null )
                        valuesSunBurstParents2.push(dataSunBurst.operator[i].replaceAll('"',''))
                    if (dataSunBurst.operator[i] !== null )
                        labelsSunBurst2.push(dataSunBurst.operator[i].replaceAll('"','') + " other emissions")
                    if (dataSunBurst.emissions_valuetorch[i] === null)
                        valuesSunBurst2.push( dataSunBurst.emissions_value[i])
                    else
                        valuesSunBurst2.push(dataSunBurst.emissions_value[i] - dataSunBurst.emissions_valuetorch[i])
                    if (dataSunBurst.operator[i] !== null )
                        valuesSunBurstParents2.push(dataSunBurst.operator[i].replaceAll('"',''))
                }

                let set = new Set(values2)
                set = Array.from(set)
                let graphLabel = [];
                let graphValue = []
                for (let i = 0 ; i < set.length ; i++){
                    let count = 0;
                    for (let j = 0 ; j <values2.length;j++){
                        if ( set[i] === values2[j]){
                            count++;
                        }

                    }
                    graphLabel.push(set[i])
                    graphValue.push(count)
                }
                let set2 = new Set(labels)
                set2 = Array.from(set2)
                if ( graphLabel.length > 20)
                    graphLayout2.height = 800
                else if (graphLabel.length <= 4)
                    graphLayout2.height = 400 + 49*graphLabel.length
                else if (graphLabel.length < 6)
                    graphLayout2.height = 400 + 45*graphLabel.length
                else if (graphLabel.length < 10)
                    graphLayout2.height = 400 + 30*graphLabel.length
                else if (graphLabel.length < 15)
                    graphLayout2.height = 400 + 23*graphLabel.length


                if ( set2.length > 20)
                    graphLayout.height = 800
                else
                    graphLayout.height = 400 + 20*set2.length


                //sunburst
                if (this.state.co2_type === "sunburst")
                    graphLayout = graphLayout2;



                emissions.sumtorch = sumtorch;
                emissions.sum = sum;
                emissions.data = res.data
                console.log(set,graphLabel,graphValue)
                emissions.graphLayout = graphLayout;
                emissions.graphLayout2 = graphLayout2;
                if (this.state.co2_type === "pie"){
                    emissions.graph = [{
                        values: values,
                        labels: labels,
                        type: 'pie',
                        hole: .8,
                    }];

                    emissions.graph2 =
                        [{
                            values: values,
                            labels: values2,
                            marker: {
                                colors: colors
                            },
                            type: 'pie',
                            hole: .8,
                        }];

                }else{
                    emissions.graph = [
                        {
                            "type": "sunburst",
                            "labels": labelsSunBurst2,
                            "parents": valuesSunBurstParents2,
                            "values":  valuesSunBurst2,
                            //"leaf": {"opacity": 0.4},
                            "marker": {"line": {"width": 1}},
                            "textposition": 'inside',
                            "insidetextorientation": 'radial',
                            "branchvalues": 'total'
                        }]

                    console.log(emissions.graph)
                    /*[{
                        values: values,
                        labels: labels,
                        type: 'pie',
                        hole: .8,
                    }];

                     */
                    emissions.graph2 =
                        [
                            {
                                "type": "sunburst",
                                "labels": labelsSunBurstCat,
                                "parents": valuesSunBurstParentsCat,
                                "values":  valuesSunBurstCat,
                                //"leaf": {"opacity": 0.4},
                                "marker": {"line": {"width": 1}},
                                "textposition": 'inside',
                                "insidetextorientation": 'radial',
                                "branchvalues": 'total'
                            }]
                    /*
                    [{
                        values: values,
                        labels: values2,
                        marker: {
                            colors: colors
                        },
                        type: 'pie',
                        hole: .8,
                    }];

                     */
                }


                console.log(values,labels,values2)
                axios.post(urlBackend+'/postQuery', {query:{type:"get_infra_obj_infos",select_type:"get_point",select_params:{object_id:layer_id}} })
                    .then((res2) => {
                        console.log(res2.data[0].lat,res2.data[0].lon,res2)
                        var radius = 250;
                        var center = [Number.parseFloat(res2.data[0].lat), Number.parseFloat(res2.data[0].lon)];
                        var options = { steps: 50, units: "kilometers", properties: { foo: "bar" } };
                        var circle = turf.circle(center, radius, options);
                        console.log(circle)
                        this.props.emissions(circle)
                        this.setState({AddPropertiesLoading:false,AddProperties:emissions});
                    }).catch((error) => {
                    console.log(error)
                });
            }).catch((error) => {
            console.log(error)
        });
    }
    componentDidMount() {
        if (window.dispatchEvent) {
            window.dispatchEvent(new Event('custom-render-trigger'));
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("new array:",this.props.infos,"   NEXT NAME:  ",nextProps.name,"   CURRENT NAME:",this.props.name,"   NOT UNDEFINED:",this.state.infos[0],"  NEXt    ",nextProps.infos[0]);
        // Каждый раз, когда props.name изменяется, состояние обновляется.
        console.log("initialize recieve",(this.props.infos[0] !== undefined),(this.props.infos[0]!==nextProps.infos[0]),this.props.infos[0],nextProps.infos[0])
        if (nextProps.name !== this.props.name || this.props.infos[0] !== nextProps.infos[0]  ) {
            console.log("initialize",(this.props.infos[0] !== undefined),this.props.infos[0])
            if (this.props.infos[0] !== undefined) {
                //console.log("array constract",this.state.infos[0])
                var keys = this.getKeysProp(nextProps.infos);
                let keys2 = this.getKeys2();
                let values = this.getValuesProp(nextProps.infos);
                let value2 = this.getValues2();
                let array = [];
                let username = "";
                let graph = false;
                //keys.forEach((key, i) => result[key] = values[i]);
                for (let i = 0; i < keys.length; i++) {
                    let type = "word";
                    let word;
                    let visible = true;
                    if ( keys[i].includes("f_msu_branches_infos_id")|| keys[i].includes("f_msu_branches_id")|| keys[i].includes("author_id")|| keys[i].includes("f_infra_obj_infos_id")|| keys[i].includes("f_infra_obj_id")|| keys[i].includes("f_co2_world_sources_id")|| keys[i].includes("f_co2_world_sources_infos_id")|| keys[i].includes("f_co2_orgs_id")|| keys[i].includes("f_co2_org_infos_id")|| keys[i].includes("basins_id") || keys[i].includes("basins_infos_id") || keys[i].includes("seismics_id") || keys[i].includes("seismics_infos_id") ||  keys[i].includes("tectonicmap_id") || keys[i].includes("tectonicmap_infos_id")  || keys[i].includes("regions_id") || keys[i].includes("regions_infos_id")  || keys[i].includes("wells_id") || keys[i].includes("wells_infos_id")  || keys[i].includes("protected_areas_id") || keys[i].includes("protected_areas_infos_id")  || keys[i].includes("fields_id") || keys[i].includes("fields_infos_id") || keys[i].includes("geologicmap_id") || keys[i].includes("geologicmap_infos_id") || keys[i].includes("subbasins_id") || keys[i].includes("subbasins_infos_id") || keys[i].includes("licenses_id") || keys[i].includes("licenses_infos_id") || keys[i].includes("geo_objects_id") || keys[i].includes("geo_objects_infos_id") ) {
                        type = "reqest"
                        this.state.id = values[i];
                    }
                    else if (keys[i].includes("username")){
                        username = values[i];
                        type = "reqest2";
                    }else if (keys[i] === "description")
                        type = "textarea";
                    else if (keys[i] === "emissions")
                        type = "emissions";
                    else if (keys[i] === "emissions2")
                        type = "emissions2";
                    else if (keys[i].includes("year")||keys[i].includes("well_head_altitude") || keys[i].includes("target_reservoir_id") || keys[i].includes("td_m") || keys[i].includes("kb_altitude")  )
                        type = "number";
                    else if (keys[i].includes("graph")){
                        type = "graph";
                        graph = true;
                    }
                    else if (keys[i].includes("object_type")){
                        type = "multiword";
                        word = object_type_word;
                    }
                    else if (keys[i].includes("max_elevation")){
                        type = "number2";
                        visible = false;
                    }
                    else if (keys[i].includes("info_source_text")){
                        type = "string";
                    }
                    else if (keys[i].includes("source")|| keys[i].includes("auction_link") || keys[i].includes("rgf_link")){
                        type = "link";
                    }

                    else if ( keys[i].includes("cost_difirence_coeficient")||keys[i].includes("bid_members")|| keys[i].includes("parent_licenses" || keys[i].includes("mother_company")) ){
                        type = "inactive2";
                    }
                    else if (keys[i].includes("paid_value") || keys[i].includes("auction_start_payment")) {
                        type = "price";
                    }
                    else if (keys[i].includes("geo_index_start_id")){
                        type = "geo_index";
                        word = age_from_word;
                    }
                    else if (keys[i].includes("geo_index_end_id")){
                        type = "geo_index_end";
                        word = age_to_word;
                    }
                    else if (keys[i].includes("geo_index")){
                        type = "geo_index2";
                        word = age_from_word;
                    }
                    else if (keys[i].includes("max_water_depth")){
                        type = "number2";
                        visible = false;
                    }
                    else if (keys[i].includes("terrain")){
                        type = "word2";
                        word = terrain_word;
                        visible = false;
                    }
                    else if (keys[i].includes("outcrop")) {
                        type = "multiword";
                        word = outcrop_word;
                    }
                    else if (keys[i].includes("basin_class"))
                        word = basin_class_word;
                    else if (keys[i].includes("well_stock_id"))
                        word = well_stock_word;
                    else if (keys[i].includes("well_type_id"))
                        word = well_type_word;
                    else if (keys[i].includes("current_status_id"))
                        word = well_status_word;
                    else if (keys[i].includes("basin_subtype"))
                        word = basin_subtype_word;
                    else if (keys[i].includes("field_class"))
                        word = field_class_word;
                    else if (keys[i].includes("seismic_type_id"))
                        word = seismic_type_word;
                    else if (keys[i].includes("domain_id"))
                        word = domain_word;

                    else if (keys[i].includes("f_company_id")){
                        type = "search_dict"
                        word = companies_dict
                    }
                    else if (keys[i].includes("signal_source_id"))
                        word = signal_source_word;
                    else if (keys[i].includes("field_type"))
                        word = field_type_word;
                    else if (keys[i].includes("discovery_method"))
                        word = discovery_method_word;
                    else if (keys[i].includes("license_type"))
                        word = licenses_type_word;
                    //tectonic_morpho_type_word,tectonic_order_word,tectonic_type_word
                    else if (keys[i].includes("morpho_type"))
                        word = tectonic_morpho_type_word;
                    else if (keys[i].includes("tectonic_order"))
                        word = tectonic_order_word;
                    else if (keys[i].includes("tectonic_type"))
                        word = tectonic_type_word;
                    else if (keys[i].includes("license_status"))
                        word = licenses_status_word;
                    else if (keys[i].includes("confidence_degree_id"))
                        word = confidence_degree_word;
                    else if (keys[i].includes("protected_area_type_id"))
                        word = protected_areas_word;
                    else if (keys[i].includes("protected_area_profile_id")){
                        type = "multiword"
                        word = protected_areas_profile_word;
                    }
                    else if (keys[i].includes("terrain"))
                        word = terrain_word;
                    else if (keys[i].includes("location")){
                        type = "word3";
                        word = location_word;
                    }
                    else if (keys[i].includes("area") || keys[i].includes("length_2d")){
                        type = "inactive";
                    }
                    else if (keys[i].includes("development_stage"))
                        word = development_stage_word;
                    else if (keys[i].includes("basin_type"))
                        word = basin_type_word;
                    else if (keys[i].includes("fluid"))
                        word = basin_fluid_word;
                    else if (keys[i].includes("age"))
                        word = basin_age_word;
                    else if (keys[i].includes("reservoir_type_id"))
                        word = world_source_reservoir
                    else if (keys[i].includes("storage_type_id"))
                        word = world_source_storage;
                    else if (keys[i].includes("status_id"))
                        word = world_source_status;
                    else if (keys[i].includes("formation_id"))
                        word = world_source_formation;
                    else if (keys[i].includes("bid_auction_date")) {
                        type = "inactivedate";
                    }
                    else if (keys[i].includes("date")) {
                        type = "date";
                    }
                    else
                        type = "string";
                    array.push({name: keys[i], value: values[i], type: type, word: word,visible:visible})
                    //console.log(array);
                }
                console.log(nextProps,array);
                this.setState({
                    name: nextProps.name,
                    desc:nextProps.desc,
                    infos:nextProps.infos,
                    display:nextProps.display,
                    infosEdit:false,
                    PermissionDisplay:"none",
                    groupPermArray:[],
                    groupPermArrayBasic:[],
                    PermissionOwnObject:false,
                    array:array,
                    graphTrig:graph,
                    graphDisplay:"none",
                    Graphics:null,
                    GraphicsDisplay:"none",
                    GraphicsType:null,
                    GraphicsSize:null,
                    GraphicsColor:null,
                    GraphicsSymbol:null,
                    GraphicsLegend:null,
                    GraphicsTypeArray:[{value:'pie',text:'Pie',key:'pie'},{value:"bar",key:"bar",text:"Bar"},{value:"histogram",key:"histogram",text:"Histogram"},{value:"bubble",key:"bubble",text:"Bubble"}],
                    GraphicsLayer:"",
                    GraphicsXAxisType:true,
                    GraphicsYAxisType:true,
                    GraphicsSizeSymbol:true,
                    GraphicsSizeInput:5,
                    GraphicsAxisLayout:null,
                    GraphicsDict:"universal_dict",
                    GraphicsLayerArray:[{value:'v_basins',text:'Basins',key:'Basins'},{value:'v_subbasins',text:'Subbasins',key:'Subbasins'},{value:'v_wells',text:'Wells',key:'Wells'},{value:'v_fields',text:'Fields',key:'Fields'},{value:'v_licenses',text:'Licenses',key:'Licenses'},{value:'v_geo_objects',text:'Geo_Objects',key:'Geo_Objects'},{value:'v_protected_areas',text:'Protected_Areas',key:'Protected_Areas'},{value:'v_geologicmap',text:'GeologicMap',key:'GeologicMap'}],
                    GraphicsRow:null,
                    GraphicsRow2:null,
                    GraphicsError:null,
                    GraphicsRowDict:null,
                    GraphicsCompare:false,
                    GraphicsCompareArray:[],
                    GraphicsCompareObject:[],
                    GraphicsCompareObjectName:[],
                    GraphicsOnlySelf:true,
                    GraphicsRowArray:[],
                    GraphicsRowArrayNumber:[],
                    GraphicsRowArrayWord:[],
                    AddProperties:'',
                    AddPropertiesLoading:false,
                    co2_type:"pie",
                    co2_rosneft:false,
                    ReservesGraph:{},
                    publicationDisplay:"none",
                    PublicationsGroupPermDisplay:false,
                    PublicationsGroupPermCssTop:0,
                    PublicationsObjectID:null,
                    PublicationsGroupPermArray:[{}],
                    PublicationsGroupPermArrayBasic:[{}],
                    filesDisplay:"none",
                    filesSearch:"",
                    filesDeleteID:null,
                    files:[{}],
                    filesSort:true,
                    publicationSearch:"",
                    publicationDeleteID:null,
                    geoTiffDisplay:"none",
                    publicationSort:true,
                    geoTiffWait:false,
                    saveActive:false,
                    resultdel:false,
                    open:false,
                    deleteObjectOpen:false,
                    editOpen:false,
                    editID:null,
                    editName:"",
                    editAuthor:"",
                    editDOI:"",
                    editYear:null,
                    editUrl:"",
                    editTags:[],
                    editType:"",
                    editPositionTop:0,
                    inc:0,
                    trigger:true,
                    layout:{},
                    graphdata:[],
                    imageDisplay:"none",
                    publication :[{}],
                    propDisplay:"none"
                });
                //console.log(array,this.state.array)
            }
        }
    }
    handleCancelDelete = () => this.setState({ resultdel: false, open: false })
    render() {
        //console.log(this.props);
        if (this.state.infos[0] !== undefined && this.state.array.length >0) {
            //console.log(this.state.array,this.props)
            let keys = this.getKeys();
            let keys2 = this.getKeys2();
            let values = this.getValues();
            let value2 = this.getValues2();
            let prefix ='';
            let layer = '';
            let layer_id = '';
            let object_id = 1;
            let newInfoUrl = " ";
            let array = this.state.array;
            if (keys[1] !== undefined && array[1] !== undefined) {
                console.log(keys[1],this.state.infos,this.props.infos,this.state.array,1)
                layer = keys[1].replace("_id","");
                object_id = array[1].value;
                prefix = "/" + object_id + "/" + layer;
                layer_id = object_id;
                console.log(object_id,layer_id)
                console.log(window.location,array[1],layer,keys,this.state.array,)
                if (window.history.pushState) {
                    var newurl = window.location.origin+"/info/"  +layer + "/"+ this.props.name +"/"+ + object_id ;
                    window.history.pushState({path:newurl},'',newurl);
                    newInfoUrl = newurl;
                }
            }

            //console.log(array)
            let left = '50%';
            let width = '50%'
            let top = ''
            let height = '100%'
            let iconname = 'plus';
            if(this.state.propDisplay === "none")
                iconname = 'plus';
            else
                iconname = 'minus';
            let iconname2 = 'plus';
            let iconname3 = 'plus';
            if (this.state.publicationDisplay === "none")
                iconname3 = 'plus';
            else
                iconname3 = 'minus';
            let iconname4 = 'plus';
            if(this.state.geoTiffDisplay === "none")
                iconname4 = 'plus'
            else
                iconname4 = 'minus'
            let iconGraphics='plus';
            console.log("GraphD",this.state.GraphicsDisplay)
            let iconPermissions='plus';
            if(this.state.PermissionDisplay !== "none")
                iconPermissions='minus';

            let iconFiles = 'plus';
            if(this.state.filesDisplay === "none")
                iconFiles = 'plus'
            else
                iconFiles = 'minus'
            if ( isMobile) {
                left = '0%'
                width = '100%'
                top = ''
            }
            return(
                <div key={"infos"} id="infos" className="infos" style={{
                    display:this.props.display,
                    left: left,
                    width:width,
                    bottom:top,

                }}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.name}</title>
                        <meta name="description" content={JSON.stringify(this.state.infos[0])} />
                        <link rel="canonical" href={newurl}/>
                    </Helmet>
                    <div id={'infos_pdf'} key={"Properties" + this.state.infosEdit}
                        style = {{overflowY: 'scroll',maxHeight: '100%'}}
                    >
                        <Button basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button>
                        <br/>
                        <div>
                            <Button size={"small"} basic icon={"zoom-in"} onClick={()=>this.props.zoomin(layer,layer_id,this.state.name)}/>
                            <Label size={'large'}>
                                {this.props.name}
                            </Label>
                            <Checkbox
                                slider
                                style={{top:"12px"}}
                                size={'mini'}
                                label={i18n.t("Edit Infos")}
                                //className={'checkbox'}
                                checked={this.state.infosEdit}
                                onChange={ () => {
                                    this.setState({infosEdit:!this.state.infosEdit});
                                }}
                            />

                            <span style={{float: 'right'}}>
                        <LinkedinShareButton url={newInfoUrl}><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
                        <TelegramShareButton url={newInfoUrl}><TelegramIcon size={32} round={true} /></TelegramShareButton>
                        <TwitterShareButton url={newInfoUrl}><TwitterIcon size={32} round={true} /></TwitterShareButton>
                        <VKShareButton url={newInfoUrl}><VKIcon size={32} round={true} /></VKShareButton>
                        <Button circular style={{transform: "scale(0.82) translate(-4px, -12px)"}} size={"large"} icon onClick={() => {
                            console.log(document.getElementsByClassName('jodit-react-container')[0])
                            var doc = new jspdf.jsPDF('p', 'mm');
                            htmlToImage.toPng(document.getElementsByClassName('jodit-react-container')[0], { quality: 0.95 })
                                .then(function (dataUrl) {

                                    /*
                                    Here are the numbers (paper width and height) that I found to work.
                                    It still creates a little overlap part between the pages, but good enough for me.
                                    if you can find an official number from jsPDF, use them.
                                    */
                                    const imgProps= doc.getImageProperties(dataUrl);

                                    var imgWidth = 210;
                                    var pageHeight = 295;
                                    var imgHeight = imgProps.height * imgWidth / imgProps.width;
                                    var heightLeft = imgHeight ;
                                    console.log(imgProps.height,imgProps.width)

                                    var position = 0;
                                    console.log( heightLeft, imgHeight, imgWidth, imgProps.width, imgProps.height)
                                    doc.addImage(dataUrl, 'PNG', 0, position, imgWidth, imgHeight);
                                    heightLeft -= pageHeight;

                                    while (heightLeft >= 0) {
                                        position = heightLeft - imgHeight;
                                        doc.addPage();
                                        console.log(position,heightLeft)
                                        doc.addImage(dataUrl, 'PNG', 0, position, imgWidth, imgHeight);
                                        heightLeft -= pageHeight;
                                    }
                                    doc.save('infos.pdf');


                                })
                                .catch(function (error) {
                                    console.error('oops, something went wrong!', error);
                                });
                            let str =newInfoUrl
                            const el = document.createElement('textarea');
                            el.value = str;
                            el.setAttribute('readonly', '');
                            el.style.position = 'absolute';
                            el.style.left = '-9999px';
                            document.body.appendChild(el);
                            el.select();
                            document.execCommand('copy');
                            document.body.removeChild(el);
                        }}>
                            <Icon name='copy' />
                        </Button>
                    </span>
                        </div>
                        <br/>
                        {(this.props.userobject)&&(
                            <div>
                                <Button size={"small"} basic={this.state.userObjectButton} onClick={()=>{
                                    this.props.infosredraw(false,true)
                                    this.setState({inc:this.state.inc+1,userObjectButton:true})
                                }}>{i18n.t("General object")}</Button>
                                <Button size={"small"} basic={!this.state.userObjectButton} onClick={()=>{
                                    this.props.infosredraw(false,false)
                                    this.setState({inc:this.state.inc+1,userObjectButton:false})
                                }}>{i18n.t("User object")}</Button>
                                <br/>
                            </div>
                        )}

                        <Label basic size={'medium'} onClick={() => {
                            if(this.state.propDisplay === "none")
                                this.setState({propDisplay:"block"})
                            else
                                this.setState({propDisplay:"none"})
                        }}>
                            <Icon name={iconname} /> {i18n.t("Свойства")}
                        </Label>
                        {this.state.array.map(({ name, value, type, word,visible },index) => {
                            console.log(this.state.array)
                            if ((type === "geo_index_end" || type === "multiword" || type==="search_dict"  ||type === "word2"|| type === "word3" || type === "price" || type === "number2" || type === "geo_index" || type === "geo_index2" || type === "word" || type === "number" || type === "date" || type === "string") && !this.state.infosEdit)
                            {
                                let displayValue = value;
                                if (type === "date" && value !== undefined && value !== null && value !== ""){
                                    let year = value.split("T")[0].split("-")[0];
                                    let month = value.split("T")[0].split("-")[1];
                                    let day = value.split("T")[0].split("-")[2];
                                    console.log(value.split("T")[0].split("-")[0],value.split("T")[0].split("-")[1],value.split("T")[0].split("-")[2])
                                    displayValue = day + "-" + month + "-" + year;
                                }
                                return (
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {i18n.t(name)}
                                        </Label>
                                        {displayValue}
                                    </div>
                                )
                            }
                            if( type === "emissions"){
                                console.log(name, value, type, word,visible)
                                for (let k = 0 ; k < value.length;k++){
                                    return (<div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {value[k].f_co2_emissions_source + " " + value[k].f_co2_emissions_year}
                                        </Label>
                                        <Input
                                            value={value[k].f_co2_emissions_value}
                                            onChange={(e,val) => {
                                                // console.log(val.value);

                                            }}
                                        />
                                    </div>)
                                }
                            }
                            if( type === "emissions2"){
                                console.log(name, value, type, word,visible)
                                for (let k = 0 ; k < value.length;k++){
                                    return (<div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {value[k].f_infra_co2_emissions_source + " " + value[k].f_infra_co2_emissions_year}
                                        </Label>
                                        <Input
                                            value={value[k].f_infra_co2_emissions_value}
                                            onChange={(e,val) => {
                                                // console.log(val.value);

                                            }}
                                        />
                                    </div>)
                                }
                            }
                            if (type === "string" && name.includes("name") && name !== "operator_name" && name !== "alternative_names") {
                                return (
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {i18n.t(name)}
                                        </Label>
                                        <Input
                                            value={this.state.name}
                                            onChange={(e,val) => {
                                                // console.log(val.value);
                                                this.setState({name:val.value});
                                                this.state.array[index].value = val.value;
                                            }}
                                        />
                                    </div>
                                )}
                            else if(type === "date"){
                                //console.log(value)
                                let date ;
                                if (value === null)
                                    date = '';
                                else
                                    date=new Date(value);
                                return(
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {i18n.t(name)}
                                        </Label>
                                        <DatePicker
                                            selected={date}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={selected => {
                                                let date = new Date(value)
                                                //console.log(selected.toISOString(),value,date)
                                                this.state.array[index].value = selected.toISOString();
                                                value = selected
                                                this.setState({array:this.state.array});
                                            }
                                            }
                                        />
                                    </div>
                                )
                            }
                            else if(type === "inactivedate"){
                                //console.log(value)
                                let date ;
                                if (value === null)
                                    date = '';
                                else
                                    date=new Date(value);
                                return(
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {i18n.t(name)}
                                        </Label>
                                        <DatePicker
                                            selected={date}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                )
                            }
                            else if(type === "link"){
                                //console.log(value)
                                let link;
                                if ( value === null)
                                    link = "None";
                                else
                                    link = value;
                                return(
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {i18n.t(name)}
                                        </Label>
                                        <a target="_blank" rel="noopener noreferrer" href={value}>{link}</a>
                                    </div>
                                )
                            }
                            else if (type === "string") {
                                //console.log(name,value);
                                let title = name;
                                if (title.includes("_id"))
                                    title = title.replace("_id","");
                                return (
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {title}
                                        </Label>
                                        <Input
                                            value={value}
                                            onChange={(e,val) => {
                                                //console.log(val.value);
                                                //this.setState({name:val.value});
                                                let array = this.state.array;
                                                array[index].value = val.value;
                                                this.setState({array:array});
                                            }}
                                        />
                                    </div>
                                )}
                            else if (type === "number") {
                                return (<div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                    <br/>
                                    <Label>
                                        <Icon name='tags' /> {i18n.t(name)}
                                    </Label>
                                    <Input
                                        type={'number'}
                                        defaultValue={value}
                                        onChange={(e,val) => {
                                            let array = this.state.array;
                                            array[index].value = val.value;
                                            this.setState({array:array});
                                        }}
                                    />
                                </div>)
                            }
                            else if (type === "price") {
                                return (<div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                    <br/>
                                    <Label>
                                        <Icon name='tags' /> {i18n.t(name)}
                                    </Label>
                                    <Input
                                        label={{ basic: true, content: 'руб' }}
                                        labelPosition='right'
                                        type={'number'}
                                        defaultValue={value}
                                        onChange={(e,val) => {
                                            let array = this.state.array;
                                            array[index].value = val.value;
                                            this.setState({array:array});
                                        }}
                                    />
                                </div>)
                            }
                            else if (type === "inactive") {
                                return (<div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                    <br/>
                                    <Label>
                                        <Icon name='tags' /> {i18n.t(name)}
                                    </Label>
                                    <Input
                                        label={{ basic: true, content: 'km²' }}
                                        labelPosition='right'
                                        //disabled={true}
                                        readOnly
                                        type={'number'}
                                        defaultValue={value}
                                        onChange={(e,val) => {
                                        }}
                                    />
                                </div>)
                            }
                            else if (type === "inactive2") {
                                if (typeof value === "object")
                                {value = JSON.stringify(value)
                                    value = value.replace( /[{\[\]"}]/g, '' );}
                                return (<div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                    <br/>
                                    <Label>
                                        <Icon name='tags' /> {i18n.t(name)}
                                    </Label>
                                    <Input
                                        //disabled={true}
                                        className="inactive"
                                        readOnly
                                        defaultValue={value}
                                        onChange={(e,val) => {
                                        }}
                                    />
                                </div>)
                            }
                            else if (type === "number2") {
                                if (visible === true)
                                    return (<div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {i18n.t(name)}
                                        </Label>
                                        <Input
                                            type={'number'}
                                            defaultValue={value}
                                            onChange={(e,val) => {
                                                let array = this.state.array;
                                                array[index].value = val.value;
                                                this.setState({array:array});
                                            }}
                                        />
                                    </div>)
                            }
                            else if (type === "geo_index2") {
                                return (
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {"Geo Index : "}
                                        </Label>
                                        <Dropdown
                                            options={word}
                                            onChange={(e,val) => {
                                                let array = this.state.array;
                                                array[index].value = val.value;
                                                this.setState({array:array})
                                            }}
                                            //value={this.state.selected}
                                            selection
                                            placeholder={value} />
                                    </div>
                                )
                            }
                            else if (type === "geo_index") {
                                return (
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {"Age From"}
                                        </Label>
                                        <Dropdown
                                            options={word}
                                            onChange={(e,val) => {
                                                let array = this.state.array;
                                                array[index].value = val.value;
                                                this.setState({array:array})
                                            }}
                                            //value={this.state.selected}
                                            selection
                                            placeholder={value} />
                                    </div>
                                )
                            }
                            else if (type === "geo_index_end") {
                                return (
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {"Age to"}
                                        </Label>
                                        <Dropdown
                                            options={word}
                                            onChange={(e,val) => {
                                                let array = this.state.array;
                                                array[index].value = val.value;
                                                this.setState({array:array})
                                            }}
                                            //value={this.state.selected}
                                            selection
                                            placeholder={value} />
                                    </div>
                                )
                            }
                            else if (type === "search_dict") {
                                let title = name;
                                return (
                                    <div  style={{zIndex:900-index, position: "relative", display: "-webkit-box"}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {title}
                                        </Label>
                                        <Dropdown
                                            fluid
                                            style={{overflow:"visible",zIndex:"9999999", marginTop: "13px",width: "80%"}}
                                            search
                                            options={word}
                                            onChange={(e,val) => {
                                                let array = this.state.array;
                                                array[index].value = val.value;
                                                this.setState({array:array})
                                            }}
                                            //value={this.state.selected}
                                            selection
                                            placeholder={value} />
                                    </div>
                                )
                            }
                            else if (type === "multiword") {
                                let title = name;
                                if (title.includes("_id"))
                                    title = title.replace("_id","");
                                return (
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {title}
                                        </Label>
                                        <Dropdown
                                            multiple
                                            options={word}
                                            onChange={(e,val) => {
                                                let array = this.state.array;
                                                array[index].value = val.value;
                                                this.setState({array:array})
                                            }}
                                            //value={this.state.selected}
                                            selection
                                            placeholder={value} />
                                    </div>
                                )
                            }
                            else if (type === "word2") {
                                let title = name;
                                if (title.includes("_id"))
                                    title = title.replace("_id","");
                                if (visible === true)
                                    return (
                                        <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                            <br/>
                                            <Label>
                                                <Icon name='tags' /> {title}
                                            </Label>
                                            <Dropdown
                                                options={word}
                                                onChange={(e,val) => {
                                                    let array = this.state.array;
                                                    array[index].value = val.value;
                                                    this.setState({array:array})
                                                }}
                                                //value={this.state.selected}
                                                selection
                                                placeholder={value} />
                                        </div>
                                    )
                            }
                            else if (type === "word3"){
                                let title = name;
                                if (title.includes("_id"))
                                    title = title.replace("_id","");
                                return(
                                    <div  style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {title}
                                        </Label>
                                        <Dropdown
                                            options={word}
                                            onChange={(e,val) => {
                                                let array = this.state.array;
                                                if (val.value === 1){
                                                    array[index+5].visible = true;
                                                    array[index+10].visible = true;
                                                    array[index+11].visible = false;
                                                    array[index+11].value = null;
                                                }
                                                else if (val.value === 2){
                                                    array[index+5].visible = true;
                                                    array[index+10].visible = false;
                                                    array[index+10].value = null;
                                                    array[index+11].visible = true;
                                                }
                                                else if (val.value === 3){
                                                    array[index+5].visible = true;
                                                    array[index+10].visible = true;
                                                    array[index+11].visible = true;
                                                }
                                                else{
                                                    array[index+5].visible = false;
                                                    array[index+10].visible = false;
                                                    array[index+11].visible = false;
                                                    array[index+5].value = null;
                                                    array[index+10].value = null;
                                                    array[index+11].value = null;
                                                }
                                                array[index].value = val.value;
                                                this.setState({array:array})
                                            }}
                                            //value={this.state.selected}
                                            selection
                                            placeholder={value} />
                                    </div>
                                )
                            }
                            else if (type === "word") {
                                let title = name;
                                if (title.includes("_id"))
                                    title = title.replace("_id","");
                                //console.log(this.state.array[index].value,word,this.state.array[index].value !== null,typeof this.state.array[index].value !== "number");
                                if (this.state.array[index].value !== null && typeof this.state.array[index].value !== "number" )
                                {
                                    console.log("Success",basin_class_word,basin_subtype_word,basin_type_word,basin_fluid_word,word)
                                    for ( let k = 0 ; k < word.length ; k++){
                                        console.log(word[k].key === value)
                                        if (word[k].key === value) {
                                            this.state.array[index].value = word[k].value;
                                        }
                                    }
                                }
                                return (
                                    <div style={{display:this.state.propDisplay,zIndex:900-index}}>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {title}
                                        </Label>
                                        <Dropdown
                                            options={word}
                                            onChange={(e,val) => {
                                                let array = this.state.array;
                                                if (name === "basin_type_id") {
                                                    // console.log(array[index+1].name,array[index+2].name,val);
                                                    let class_temp = "";
                                                    if (val.value !== "") {
                                                        axios.post(urlBackend+'/layerid', {query:"select f_basin_subtype_name as key, f_basin_subtype_name as text ,f_basin_subtype_id as value from basin_subtype where f_basin_type_id = "+val.value})
                                                            .then((res) => {
                                                                for (let i = 0 ; i<res.data.length;i++){
                                                                    if ( i !== res.data.length-1){
                                                                        class_temp = class_temp +res.data[i].value +','
                                                                    }
                                                                    else
                                                                        class_temp = class_temp +res.data[i].value
                                                                }
                                                                array[index+1].word= res.data;
                                                                array[index+1].word.push({key:"",text:"------",value:""});
                                                                //console.log(res.data)
                                                                axios.post(urlBackend+'/layerid', {query:"select f_basin_class_name as key, f_basin_class_name as text ,f_basin_class_id as value from basin_class where f_basin_subtype_id in (" +class_temp +")"})
                                                                    .then((res) => {
                                                                        //console.log(res.data)
                                                                        array[index+2].word= res.data;
                                                                        array[index+2].word.push({key:"",text:"------",value:""});
                                                                        this.setState({array:array})
                                                                    }).catch((error) => {
                                                                    //console.log(error,"select f_basin_class_name as key, f_basin_class_name as text ,f_basin_class_id as value from basin_class where f_basin_subtype_id in (" +class_temp +")")
                                                                });
                                                            }).catch((error) => {
                                                            //console.log(error,"select f_basin_subtype_name as key, f_basin_subtype_name as text ,f_basin_subtype_id as value from basin_subtype where f_basin_type_id = "+val.value)
                                                        });

                                                    }
                                                }
                                                if (name === "basin_subtype_id") {
                                                    //console.log(this.state.array[index+1].name,this.state.array[index+2].name,val);
                                                    if (val.value !== "") {
                                                        axios.post(urlBackend+'/layerid', {query:"select f_basin_class_name as key, f_basin_class_name as text ,f_basin_class_id as value from basin_class where f_basin_subtype_id = "+val.value})
                                                            .then((res) => {
                                                                //console.log(res.data)
                                                                array[index+1].word= res.data;
                                                                array[index+1].word.push({key:"",text:"------",value:""});
                                                                this.setState({array:array})
                                                            }).catch((error) => {
                                                            console.log(error)
                                                        });

                                                    }
                                                }
                                                //console.log(val)
                                                array[index].value = val.value;
                                                //console.log(array);
                                                this.setState({array:array})
                                            }}
                                            value={value}
                                            selection
                                            placeholder={value} />
                                    </div>
                                )
                            }
                            else if (type === "textarea") {
                                console.log("publ comt render");
                                let parentWidth=window.innerWidth/2;
                                console.log(parentWidth)
                                let deleteID = null;
                                let deleteObj = false;
                                let publication =[{}];
                                let deleteIndex= null;

                                let files2 = [{}];
                                let icon = "plus";
                                if (this.state.AddProperties !== '')
                                    icon = "minus";
                                let layer = keys[1].replace("_id","");
                                return (
                                    <div
                                        //style={{width: "55%", position: "absolute",top: "50px", right: "0"}}
                                    >
                                        <div>
                                            {(layer === "fields") &&(
                                                <div>
                                                    <Label basic size={'medium'} onClick={() => {
                                                        console.log(layer);
                                                        if (this.state.AddProperties !== ''){
                                                            this.setState({AddProperties:'',ReservesGraph:{}});
                                                        }
                                                        else {
                                                            axios.post(urlBackend+'/layerid', {query:"select results.fields_name, array_agg(results.operator_name) as name,\n" +
                                                                    "array_agg(results.area) procent from \n" +
                                                                    "(\n" +
                                                                    "\tselect v_fields.fields_name as fields_name, REPLACE(v_licenses.operator_name,'\"','') as operator_name,\n" +
                                                                    "\tsum(st_area(st_intersection(v_fields.wkb_geometry, v_licenses.wkb_geometry))/st_area(v_fields.wkb_geometry)) area\n" +
                                                                    "from v_fields, v_licenses\n" +
                                                                    "where st_intersects(v_licenses.wkb_geometry, v_fields.wkb_geometry )\n" +
                                                                    "and v_fields.fields_id = " + layer_id + " AND v_licenses.license_status = 1 "+
                                                                    "group by v_fields.fields_name, REPLACE(v_licenses.operator_name,'\"','')\n" +
                                                                    ") results\n" +
                                                                    "group by results.fields_name" })
                                                                .then((res) => {
                                                                    console.log(res.data);
                                                                    let string = ''
                                                                    if ( res.data[0]!==undefined){
                                                                        for ( let i = 0; i < res.data[0].name.length;i++){
                                                                            let temp = res.data[0].name[i] + " : " +(res.data[0].procent[i]*100) +"% \n"
                                                                            string = string + temp;
                                                                            res.data[0].procent[i]=res.data[0].procent[i]*100;
                                                                        }
                                                                        console.log(string)
                                                                        let data = [{
                                                                            values: res.data[0].procent,
                                                                            labels: res.data[0].name,
                                                                            type: 'pie',
                                                                            hole: .8,
                                                                        }];

                                                                        let layout = {
                                                                            height: 500,
                                                                            width: parentWidth,
                                                                            legend : {orientation :'h', xanchor : "center", x : 0.5, y: 0,bgcolor:"rgba(0,0,0,0)"}
                                                                        };
                                                                        this.setState({AddProperties:{layout:layout,data:data}});
                                                                    }
                                                                }).catch((error) => {
                                                                console.log(error)
                                                            });
                                                            axios.post(urlBackend + '/postQuery' , {query:{type:"get_reserves_graph",select_type:"get_reserves_graph_perm",select_params:{user_id:Cookies.get("id")}}}
                                                            ).then((result) => {
                                                                if (result.data[0].exists)
                                                                    axios.post(urlBackend + '/postQuery', {
                                                                            query: {
                                                                                type: "get_reserves_graph",
                                                                                select_type: "get_reserves_graph",
                                                                                select_params: {object_id: layer_id}
                                                                            }
                                                                        }
                                                                    ).then((res) => {
                                                                        console.log(res.data[0])
                                                                        let trace1 = {
                                                                            labels: ["A", "B1", "B2", "C1", "C2"],
                                                                            values: [res.data[0].stoiip_a, res.data[0].stoiip_b1, res.data[0].stoiip_b2, res.data[0].stoiip_c1, res.data[0].stoiip_c2],
                                                                            name: i18n.t("Oil At Place"),
                                                                            type: 'pie',
                                                                            hole: .8,
                                                                        };

                                                                        let trace2 = {
                                                                            labels: ["A", "B1", "B2", "C1", "C2"],
                                                                            values: [res.data[0].oil_rec_a, res.data[0].oil_rec_b1, res.data[0].oil_rec_b2, res.data[0].oil_rec_c1, res.data[0].oil_rec_c2],
                                                                            name: i18n.t("Recovarable Oil"),
                                                                            type: 'pie',
                                                                            hole: .8,
                                                                        };
                                                                        let layout1 = {
                                                                            title: i18n.t("Oil At Place"),
                                                                            height: 500,
                                                                            width: parentWidth - 50,
                                                                            legend: {
                                                                                orientation: 'h',
                                                                                xanchor: "center",
                                                                                x: 0.5,
                                                                                y: 0,
                                                                                bgcolor: "rgba(0,0,0,0)"
                                                                            }
                                                                        };
                                                                        let layout2 = {
                                                                            title: i18n.t("Recovarable Oil"),
                                                                            height: 500,
                                                                            width: parentWidth - 50,
                                                                            legend: {
                                                                                orientation: 'h',
                                                                                xanchor: "center",
                                                                                x: 0.5,
                                                                                y: 0,
                                                                                bgcolor: "rgba(0,0,0,0)"
                                                                            }
                                                                        };
                                                                        this.setState({
                                                                            ReservesGraph: {
                                                                                layout1: layout1,
                                                                                layout2: layout2,
                                                                                data: [trace1],
                                                                                data2: [trace2]
                                                                            }
                                                                        });
                                                                    })
                                                                        .catch((error) => {
                                                                            console.log(error)
                                                                        })
                                                            })
                                                                .catch((error) => {
                                                                    console.log(error)
                                                                })
                                                        }
                                                    }}>
                                                        <Icon name={icon} /> {i18n.t("Доп.Свойства.")}
                                                    </Label>
                                                    <br/>
                                                    {(this.state.AddProperties !== '')&&(
                                                        <div>
                                                            <Plot
                                                                data={this.state.AddProperties.data}
                                                                layout={this.state.AddProperties.layout}
                                                            />
                                                            <br/>
                                                        </div>
                                                    )}
                                                    {(this.state.ReservesGraph.data !== undefined)&&(
                                                        <div>
                                                            <Plot
                                                                data={this.state.ReservesGraph.data}
                                                                layout={this.state.ReservesGraph.layout1}
                                                            />
                                                            <br/>
                                                            <Plot
                                                                data={this.state.ReservesGraph.data2}
                                                                layout={this.state.ReservesGraph.layout2}
                                                            />
                                                            <br/>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {(layer === "licenses") &&(<>
                                                <Label basic style={{maxHeight: "28px"}} size={'medium'} onClick={() => {
                                                    console.log(layer);
                                                    if (this.state.AddProperties !== ''){
                                                        console.log("seisimcs close")
                                                        this.setState({AddProperties:'',AddPropertiesLoading:false,ReservesGraph:{}});
                                                    }
                                                    else {
                                                        this.setState({AddPropertiesLoading:true})
                                                        axios.post(urlBackend+'/postQuery', {query:{type:"get_seismics_density",select_type:"get_seismics_density",select_params:{object_id:layer_id}} })
                                                            .then((res) => {
                                                                console.log(res)
                                                                this.setState({AddPropertiesLoading:false,AddProperties:res.data[0].density});
                                                            }).catch((error) => {
                                                            console.log(error)
                                                        });

                                                    }
                                                }}>
                                                    <Icon name={icon} /> {"Доп.Свойства."} {(this.state.AddPropertiesLoading )&&(<Button style={{top:"-5px",boxShadow:"none",transform: "scale(0.7)"}} basic loading/>)}
                                                </Label>
                                                <br/>
                                                {(this.state.AddProperties !== '')&&(
                                                    <div>
                                                        <Label>{"Seismics density : " + this.state.AddProperties}</Label>
                                                        <br/>
                                                    </div>
                                                )}
                                            </>)}
                                            {(layer.includes("co2")) &&(<>
                                                <Label basic style={{maxHeight: "28px"}} size={'medium'} onClick={() => {
                                                    if (this.state.AddProperties !== ''){
                                                        console.log("seisimcs close")
                                                        this.setState({AddProperties:'',AddPropertiesLoading:false,ReservesGraph:{}});
                                                    }
                                                    else {
                                                        this.setState({AddPropertiesLoading: true})
                                                        this.get_co2_graphs(layer_id,"all")
                                                    }
                                                }}>
                                                    <Icon name={icon} /> {"Доп.Свойства."} {(this.state.AddPropertiesLoading )&&(<Button style={{top:"-5px",boxShadow:"none",transform: "scale(0.7)"}} basic loading/>)}
                                                </Label>
                                                <br/>
                                                {(this.state.AddProperties !== '')&&(
                                                    <div >
                                                        {"Graph type:   "}<Dropdown value={this.state.co2_type} placeholder={"graph type"} onChange={(e,data)=>{
                                                            this.setState({co2_type:data.value});
                                                            this.get_co2_graphs(layer_id,"all")
                                                        }} options={[{value:"pie",key:"pie",text:"pie"},{value:"sunburst",key:"sunburst",text:"sunburst"}]} />
                                                        <br/>
                                                        {(Cookies.get("groups").includes('PH_CO2'))&&(<>
                                                                <div style={{display:"flex"}}>{"all"}<Checkbox style={{transform: 'scaleX(0.70)scaley(0.70)',marginTop:"-2px"}} onChange={(e,data)=>{
                                                                    this.setState({co2_rosneft:data.checked})
                                                                    if (data.checked)
                                                                        this.get_co2_graphs(layer_id,"rosneft")
                                                                    else
                                                                        this.get_co2_graphs(layer_id,"all")
                                                                }} value={this.state.co2_rosneft} toggle />{"rosneft"}</div>
                                                                <br/>
                                                            </>
                                                        )}

                                                        <div style={{display: "inline-flex"}}>
                                                            <Plot
                                                                onLegendClick={(e,d)=>console.log(e,d)}
                                                                onSunburstClick={(e,d)=>console.log(e,d)}
                                                                data={this.state.AddProperties.graph}
                                                                layout={this.state.AddProperties.graphLayout}
                                                            />
                                                            <Plot
                                                                onLegendClick={(e,d)=>console.log(e,d)}
                                                                onSunburstClick={(e,d)=>console.log(e,d)}
                                                                style={{position:"relative"}}
                                                                data={this.state.AddProperties.graph2}
                                                                layout={this.state.AddProperties.graphLayout2}
                                                            />
                                                        </div>
                                                        <br/>
                                                        <div style={{fontSize: "18px"}}>{"Total emissions in 250 km : "+this.state.AddProperties.sum.toLocaleString('en-US', {maximumFractionDigits:2})+ ", flare emissions : "+this.state.AddProperties.sumtorch.toLocaleString('en-US', {maximumFractionDigits:2})}</div>
                                                        <br/>
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Index</th>
                                                                <th>Name</th>
                                                                <th>Operator</th>
                                                                <th>Emission value</th>
                                                                <th>Emission flare value</th>
                                                                <th>Emission source</th>
                                                                <th>Emission year</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>


                                                            {this.state.AddProperties.data.map(
                                                            (info,index)=>{
                                                                return(
                                                                <tr>
                                                                    <td>{index}</td>
                                                                <td>{info.name}</td>
                                                                <td>{info.operator}</td>
                                                                <td>{info.emissions_value}</td>
                                                                    <td>{info.emissions_valuetorch}</td>
                                                                    <td>{info.emissions_source}</td>
                                                                    <td>{info.emissions_year}</td>
                                                                </tr>
                                                                )
                                                            }
                                                            )}
                                                            </tbody>
                                                        </table>
                                                        <br/>
                                                    </div>
                                                )}
                                            </>)}
                                            {(layer.includes("infra_obj")) &&(<>
                                                <Label basic style={{maxHeight: "28px"}} size={'medium'} onClick={() => {
                                                    if (this.state.AddProperties !== ''){
                                                        console.log("seisimcs close")
                                                        this.setState({AddProperties:'',AddPropertiesLoading:false,ReservesGraph:{}});
                                                    }
                                                    else {
                                                        this.setState({AddPropertiesLoading: true})
                                                        if (Cookies.get("groups").includes('PH_CO2'))
                                                            this.get_infra_graphs(array[1].value,"all_rosneft")
                                                        else
                                                            this.get_infra_graphs(array[1].value,"all")
                                                    }
                                                }}>
                                                    <Icon name={icon} /> {"Доп.Свойства."} {(this.state.AddPropertiesLoading )&&(<Button style={{top:"-5px",boxShadow:"none",transform: "scale(0.7)"}} basic loading/>)}
                                                </Label>
                                                <br/>
                                                {(this.state.AddProperties !== '')&&(
                                                    <div >
                                                        {"Graph type:   "}<Dropdown value={this.state.co2_type} placeholder={"graph type"} onChange={(e,data)=>{
                                                        this.setState({co2_type:data.value});
                                                        this.get_infra_graphs(array[1].value,"all")
                                                    }} options={[{value:"pie",key:"pie",text:"pie"},{value:"sunburst",key:"sunburst",text:"sunburst"}]} />
                                                        <br/>
                                                        {(Cookies.get("groups").includes('PH_CO2'))&&(<>
                                                                <div style={{display:"flex"}}>{"all"}<Checkbox style={{transform: 'scaleX(0.70)scaley(0.70)',marginTop:"-2px"}} onChange={(e,data)=>{
                                                                    this.setState({co2_rosneft:data.checked})
                                                                    if (data.checked)
                                                                        this.get_infra_graphs(array[1].value,"rosneft")
                                                                    else
                                                                        this.get_infra_graphs(array[1].value,"all_rosneft")
                                                                }} value={this.state.co2_rosneft} toggle />{"rosneft"}</div>
                                                                <br/>
                                                            </>
                                                        )}

                                                        <div style={{display: "inline-flex"}}>
                                                            <Plot
                                                                onLegendClick={(e,d)=>console.log(e,d)}
                                                                onSunburstClick={(e,d)=>console.log(e,d)}
                                                                data={this.state.AddProperties.graph}
                                                                layout={this.state.AddProperties.graphLayout}
                                                            />
                                                            <Plot
                                                                onLegendClick={(e,d)=>console.log(e,d)}
                                                                onSunburstClick={(e,d)=>console.log(e,d)}
                                                                style={{position:"relative"}}
                                                                data={this.state.AddProperties.graph2}
                                                                layout={this.state.AddProperties.graphLayout2}
                                                            />
                                                        </div>
                                                        <br/>
                                                        <div style={{fontSize: "18px"}}>{"Total emissions in 250 km : "+this.state.AddProperties.sum.toLocaleString('en-US', {maximumFractionDigits:2})+ ", flare emissions : "+this.state.AddProperties.sumtorch.toLocaleString('en-US', {maximumFractionDigits:2})}</div>
                                                        <br/>
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Index</th>
                                                                <th>Name</th>
                                                                <th>Operator</th>
                                                                <th>Emission value</th>
                                                                <th>Emission flare value</th>
                                                                <th>Emission source</th>
                                                                <th>Emission year</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>


                                                            {this.state.AddProperties.data.map(
                                                                (info,index)=>{
                                                                    return(
                                                                        <tr>
                                                                            <td>{index}</td>
                                                                            <td>{info.name}</td>
                                                                            <td>{info.operator}</td>
                                                                            <td>{info.emissions_value}</td>
                                                                            <td>{info.emissions_valuetorch}</td>
                                                                            <td>{info.emissions_source}</td>
                                                                            <td>{info.emissions_year}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            )}
                                                            </tbody>
                                                        </table>
                                                        <br/>
                                                    </div>
                                                )}
                                            </>)}
                                            <FeatureInfosGraphs array={this.state.array} id={this.state.id} avgWinValue={this.props.avgWinValue} maxWinValue={this.props.maxWinValue} onGraphClick={this.props.onGraphClick} parentWidth={parentWidth} layer={layer} />
                                        </div>
                                        <div>
                                            {((layer === "fields" || layer === "basins")&&Cookies.get("id")!==undefined) &&(
                                                <FeatureInfosReserves layer={layer} object_id={object_id}/>
                                            )}
                                        </div>
                                        <div>
                                            <Label basic size={'medium'} onClick={() => {
                                                if(this.state.PermissionDisplay === "none"){
                                                    let query = {type:'get_'+layer+'_infos',select_type:'own_object',select_params:{user_id:Cookies.get('id'),object_id:object_id}}
                                                    axios.post(urlBackend+'/postQuery', {query:query})
                                                        .then((result) => {
                                                            console.log(result.data);
                                                            if (result.data[0].exists === true){
                                                                let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission",select_params:{object_id:object_id,user_id:Cookies.get("id")}}
                                                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                                                    .then((res) => {
                                                                        console.log(res)
                                                                        this.setState({PermissionDisplay:"block",PermissionOwnObject:result.data[0].exists,groupPermArray:res.data[0].get_object_permission,groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_object_permission))})
                                                                    })
                                                                    .catch((error)=>{console.log(error)})
                                                            }
                                                            else{
                                                                this.setState({PermissionDisplay:"block",PermissionOwnObject:result.data[0].exists});
                                                            }

                                                        })
                                                        .catch((err) => {console.log(err);})

                                                }
                                                else
                                                    this.setState({PermissionDisplay:"none"})
                                            }}>
                                                <Icon name={iconPermissions} /> {i18n.t("Права Доступа")}
                                            </Label>
                                            <br/>
                                            <div style={{display:this.state.PermissionDisplay}}>
                                                {(!this.state.PermissionOwnObject)&&(
                                                    <>
                                                        {i18n.t("You are not allowed to change permissions on this object")}
                                                    </>
                                                )}
                                                {(this.state.PermissionOwnObject)&&(
                                                    <GroupPermissionForObject csstop={null} arrayChange={(array)=>{this.setState({groupPermArray:array})}} permArrayBasic={this.state.groupPermArrayBasic} permArray={this.state.groupPermArray} id={object_id} layer_id={layer} close={()=>{this.setState({PermissionDisplay:"none"})}} display={this.state.PermissionDisplay} />
                                                )}
                                            </div>
                                        </div>
                                        {console.log(layer)}
                                        <div>
                                            <Label basic size={'medium'} onClick={() => {
                                                if(this.state.publicationDisplay === "none"){
                                                    let publicationQuary = {type:"get_publications",select_type:"get_publications",select_params:{id:this.state.array[1].value,layer:layer,user_id:Cookies.get('id')}}
                                                    console.log(layer,publicationQuary)
                                                    axios.post(urlBackend+'/postQuery', {query:publicationQuary})
                                                        .then((res) => {
                                                            console.log(res.data);
                                                            publication = res.data;
                                                            this.setState({publicationDisplay:"block",publication :res.data})
                                                        }).catch((error) => {
                                                        console.log(error)
                                                    });
                                                }
                                                else
                                                    this.setState({publicationDisplay:"none"})
                                            }}>
                                                <Icon name={iconname3} /> {i18n.t("Дополнительная информация")}
                                            </Label>
                                            <GroupPermissionForObject csstop={this.state.PublicationsGroupPermCssTop} type={'publications'} arrayChange={(array)=>{this.setState({PublicationsGroupPermArray:array})}} permArrayBasic={this.state.PublicationsGroupPermArrayBasic} permArray={this.state.PublicationsGroupPermArray} id={this.state.PublicationsObjectID} close={()=>{this.setState({PublicationsGroupPermDisplay:false})}} display={this.state.PublicationsGroupPermDisplay} />
                                            <UpdatePublications update={this.updatePublication} id={this.state.array[1].value}
                                                                editID={this.state.editID}
                                                                editName={this.state.editName}
                                                                editAuthor={this.state.editAuthor}
                                                                editDOI={this.state.editDOI}
                                                                editYear={this.state.editYear}
                                                                editUrl={this.state.editUrl}
                                                                editTags={this.state.editTags}
                                                                editType={this.state.editType} tags={tags_word } word={publication_type_word}  layer={layer} editPositionTop={this.state.editPositionTop} display={this.state.editOpen} close={()=>{this.setState({editOpen:false})}} />
                                            <AddPublicationsTranslate update={this.updatePublication} id={this.state.array[1].value} tags={tags_word} word={publication_type_word} display={this.state.publicationDisplay} layer={layer}/>
                                            <div style={{display:this.state.publicationDisplay}}>
                                                <h2 id='titlepublications'>{i18n.t("Таблица Дополнительной Информации")}</h2>
                                                <br/>
                                                <Label>
                                                    <Icon name='tags' /> {i18n.t("Search: ")}
                                                </Label>
                                                <Input
                                                    //disabled={true}
                                                    //defaultValue={}
                                                    onKeyDown={(e)=>{
                                                        if (e.key === 'Enter') {
                                                            let publicationQuary = {type:"search_publications",select_type:"search_publications",select_params:{id:this.state.id,layer:layer,search:"%"+this.state.publicationSearch+"%",user_id:Cookies.get('id')}}
                                                            console.log(layer,publicationQuary)
                                                            axios.post(urlBackend+'/postQuery', {query:publicationQuary})
                                                                .then((res) => {
                                                                    console.log(res.data);
                                                                    publication = res.data;
                                                                    this.setState({publicationDisplay:"block",publication :res.data})
                                                                }).catch((error) => {
                                                                console.log(error)
                                                            });
                                                        }
                                                    }}
                                                    onChange={(e,val) => {
                                                        console.log(e,val.value)
                                                        this.setState({publicationSearch:val.value.toLowerCase()})
                                                    }}
                                                />
                                                {" "}
                                                <Icon
                                                    size='large'
                                                    onClick={()=>{
                                                        let publicationQuary = {type:"search_publications",select_type:"search_publications",select_params:{id:this.state.id,layer:layer,search:"%"+this.state.publicationSearch+"%",user_id:Cookies.get('id')}}
                                                        console.log(layer,publicationQuary)
                                                        axios.post(urlBackend+'/postQuery',{query:publicationQuary})
                                                            .then((res) => {
                                                                console.log(res.data);
                                                                publication = res.data;
                                                                this.setState({publicationDisplay:"block",publication :res.data})
                                                            }).catch((error) => {
                                                            console.log(error)
                                                        });
                                                    }}
                                                    basic
                                                    name='searchengin'
                                                />
                                                <Icon
                                                    size='large'
                                                    onClick={()=>{
                                                        let publicationQuary = {type:"get_publications",select_type:"get_publications",select_params:{id:this.state.array[1].value,layer:layer,user_id:Cookies.get('id')}}
                                                        console.log(layer,publicationQuary)
                                                        axios.post(urlBackend+'/postQuery', {query:publicationQuary})
                                                            .then((res) => {
                                                                console.log(res.data);
                                                                publication = res.data;
                                                                this.setState({publicationDisplay:"block",publication :res.data})
                                                            }).catch((error) => {
                                                            console.log(error)
                                                        });
                                                    }}
                                                    basic
                                                    name={'redo'}
                                                />
                                                <table id='publications'>
                                                    <tbody>
                                                    <tr key={index}>
                                                        <th onClick={()=>{
                                                        }}>{"  "}</th>
                                                        <th onClick={()=>{
                                                            console.log("index")
                                                            this.onSort("index")
                                                        }}>{i18n.t("Index")}</th>
                                                        <th onClick={()=>{
                                                            console.log("name")
                                                            this.onSort("name")
                                                        }}>{i18n.t("Name")}<Icon name='sort' size='small' /></th>
                                                        <th onClick={()=>{
                                                            console.log("author")
                                                            this.onSort("author")
                                                        }}>{i18n.t("Author")}<Icon name='sort' size='small' /></th>
                                                        <th onClick={()=>{
                                                            console.log("doi")
                                                            this.onSort("doi")
                                                        }}>{i18n.t("Doi")}<Icon name='sort' size='small' /></th>
                                                        <th onClick={()=>{
                                                            console.log("url")
                                                            this.onSort("url")
                                                        }}>{i18n.t("Url")}<Icon name='sort' size='small' /></th>
                                                        <th onClick={()=>{
                                                            console.log("type")
                                                            this.onSort("type")
                                                        }}>{i18n.t("Publication Type")}<Icon name='sort' size='small' /></th>
                                                        <th onClick={()=>{
                                                            console.log("tags")
                                                            this.onSort("tags")
                                                        }}>{i18n.t("Publication Tags")}<Icon name='sort' size='small' /></th>
                                                        <th onClick={()=>{
                                                            console.log("uploaddate")
                                                            this.onSort("uploaddate")
                                                        }}>{i18n.t("Year")}<Icon name='sort' size='small' /></th>
                                                        <th onClick={()=>{
                                                            console.log("layername")
                                                            this.onSort("layername")
                                                        }}>{i18n.t("Layer")}<Icon name='sort' size='small' /></th>
                                                        <th>{i18n.t("Edit")}</th>
                                                        <th>{i18n.t("Permission")}</th>
                                                        <th>{i18n.t("Delete")}</th>
                                                    </tr>
                                                    {this.state.publication.map(({filename,name,permission,uploaddate,type,user,id,tags,author,doi,url,layername},index) => {
                                                        console.log(filename,name,permission,uploaddate,type,user,id,tags,author,doi,url,layername)
                                                        let name2 = filename;
                                                        let urlFinal = url;
                                                        let perm
                                                        if (permission !== undefined && permission !== null)
                                                            perm = permission[0];
                                                        if (name === "" || name === undefined || name === null)
                                                        {
                                                            name = "Not Availible"
                                                        }
                                                        let date=[];
                                                        if ( filename !== undefined) {
                                                            //console.log(name2.indexOf("-"));
                                                            name2 = name2.slice(name2.indexOf("-")+1);
                                                            name2 = name2.slice(name2.indexOf("-")+1);
                                                        }

                                                        if (user !== undefined){
                                                            //console.log(user,Cookies.get('id'))
                                                            if (user === Number(Cookies.get('id'))){
                                                                deleteObj = true;
                                                            }
                                                        }
                                                        if (urlFinal === undefined || urlFinal === null || urlFinal === "")
                                                            urlFinal = false
                                                        let tags_display = tags;
                                                        if (tags !== undefined && tags !== null)
                                                            if ( tags.length > 0){
                                                                tags_display = ''
                                                                for (let i = 0; i < tags.length; i++) {
                                                                    //console.log(tags[i],tags_display);
                                                                    if (i === tags.length-1)
                                                                        tags_display = tags_display + tags[i] + ' \n'
                                                                    else
                                                                        tags_display = tags_display + tags[i] + ', \n'
                                                                }
                                                            }
                                                        //console.log(tags,filename,name,(filename === null || filename ===undefined || filename === "null"))
                                                        let fileurl = urlBackend+'/displayimages?file='+filename;
                                                        if (filename === null || filename ===undefined || filename === "" || filename === "null" || filename === "undefined")
                                                            fileurl = false;
                                                        console.log(fileurl)
                                                        return (
                                                            <tr onMouseDown={()=>{console.log(index,id,type);deleteID = id;}} key={index}>
                                                                {(type === "Las файл")&&(
                                                                    <td>
                                                                    <Icon onClick={()=>{
                                                                        this.props.lasHandler(fileurl)
                                                                    }} name={'line graph'}></Icon>
                                                                    </td>
                                                                )}
                                                                {(type === "GRID файл" || type === "Seg-Y файл")&&(
                                                                    <td>
                                                                        <Icon onClick={()=>{
                                                                            this.props.model3infos(fileurl)
                                                                        }} name={'line graph'}></Icon>
                                                                    </td>
                                                                )}
                                                                {(type !== "GRID файл" && type !== "Seg-Y файл" && type !== "Las файл")&&(
                                                                    <td>

                                                                    </td>
                                                                )}
                                                                <td>{index}</td>
                                                                {(fileurl===false)&&(
                                                                    <td>{name}</td>
                                                                )}
                                                                {(fileurl!==false)&&(
                                                                    <td><a href={fileurl.replace('+','%2b')}>{name}</a></td>
                                                                )}

                                                                <td>{author}</td>
                                                                <td><a href={doi} target="_blank">{doi}</a></td>
                                                                {(urlFinal)&&(
                                                                    <td onMouseOver={()=>console.log(url)}><a href={url} target="_blank">{"link"}</a></td>
                                                                )}
                                                                {(!urlFinal)&&(
                                                                    <td></td>
                                                                )}

                                                                <td>{type}</td>
                                                                <td>{tags_display}</td>
                                                                <td>{uploaddate}</td>
                                                                <td>{layername}</td>
                                                                <td>{(perm === 'Изменение' || deleteObj) && (
                                                                    <>
                                                                        <Icon name='edit' onClick={(e)=> {
                                                                            console.log(e.pageY,e.clientY,e.screenY)
                                                                            console.log(name,uploaddate,type,user,id,tags,author,doi,url,layername)
                                                                            this.setState({editID:id,editName:name,editAuthor:author,editDOI:doi,editYear:uploaddate,editUrl:url,editTags:tags,editType:type,editPositionTop:e.pageY-200,editOpen:true})
                                                                        }} />
                                                                    </>
                                                                )}</td>
                                                                <td>
                                                                    {(perm === 'Изменение' || deleteObj) && (
                                                                        <>
                                                                            <Icon name='group' onClick={(e)=> {
                                                                                e.persist()
                                                                                console.log(id)
                                                                                let PermQuary = {type:"get_Groups_Permission",select_type:"get_No_Object_Groups_Permission",select_params:{object_id:id,user_id:Cookies.get("id"),type:'publications'}}
                                                                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                                                                    .then((res) => {
                                                                                        console.log(res.data,e.pageY,e)
                                                                                        this.setState({PublicationsGroupPermDisplay:true,PublicationsGroupPermCssTop:e.pageY,PublicationsObjectID:id,PublicationsGroupPermArray:res.data[0].get_no_geo_object_permission,PublicationsGroupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_no_geo_object_permission))})
                                                                                    })
                                                                                    .catch((error)=>{console.log(error)})
                                                                            }} />
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>{(perm === 'Изменение' || deleteObj) && (
                                                                    <div>
                                                                        <Icon name='delete' onClick={()=> {
                                                                            this.setState({open:true,publicationDeleteID:id})
                                                                        }} />
                                                                    </div>
                                                                )}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    {(deleteObj) && (
                                                        <Dialog
                                                            classes={{ root: 'MenuItem'}}
                                                            open={this.state.open}
                                                            TransitionComponent={Transition}
                                                            keepMounted
                                                            onClose={this.handleCancelDelete}
                                                            aria-labelledby="alert-dialog-slide-title"
                                                            aria-describedby="alert-dialog-slide-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-slide-title">{"Delete"}</DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-slide-description">
                                                                    Confirm Delete
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={this.handleCancelDelete} color="primary">
                                                                    Disagree
                                                                </Button>
                                                                <Button onClick={()=> {
                                                                    console.log("DELETE FROM attachments WHERE f_attachment_id = " + this.state.publicationDeleteID);
                                                                    axios.post(urlBackend+'/layerid', {query:"DELETE FROM attachments WHERE f_attachment_id = " + this.state.publicationDeleteID})
                                                                        .then((res) => {
                                                                            let publicationQuary = {type:"get_publications",select_type:"get_publications",select_params:{id:this.state.array[1].value,layer:layer,user_id:Cookies.get('id')}}
                                                                            console.log(layer,publicationQuary)
                                                                            axios.post(urlBackend+'/postQuery', {query:publicationQuary})
                                                                                .then((res) => {
                                                                                    console.log(res.data);
                                                                                    publication = res.data;
                                                                                    this.setState({publicationDisplay:"block",publication :res.data})
                                                                                }).catch((error) => {
                                                                                console.log(error)
                                                                        }).catch((error) => {
                                                                        console.log(error)
                                                                    });

                                                                    });
                                                                }} color="primary">
                                                                    Agree
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    )}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        {(layer === 'wells')&&(<FeatureInfosLasFiles layerupdate={this.props.layerupdate} lasHandler={this.props.lasHandler} LayerOptions={this.props.LayerOptions} UserOptions={this.props.UserOptions} userdataname={this.props.userdataname} presetdata={this.props.presetdata} dataname={this.props.dataname} data2={this.props.data2} data={this.props.data} result={this.props.result} publication={publication} array={this.state.array} id={this.state.id} avgWinValue={this.props.avgWinValue} maxWinValue={this.props.maxWinValue} onGraphClick={this.props.onGraphClick} parentWidth={parentWidth} layer={layer}/>)}
                                        <FeatureInfosGeoTiffs layerupdate={this.props.layerupdate} LayerOptions={this.props.LayerOptions} UserOptions={this.props.UserOptions} userdataname={this.props.userdataname} presetdata={this.props.presetdata} dataname={this.props.dataname} data2={this.props.data2} data={this.props.data} result={this.props.result} publication={publication} array={this.state.array} id={this.state.id} avgWinValue={this.props.avgWinValue} maxWinValue={this.props.maxWinValue} onGraphClick={this.props.onGraphClick} parentWidth={parentWidth} layer={layer}/>
                                        <br/>
                                        <span style={{display:"inline-flex"}}>
                                    {(this.state.infosEdit)&&(
                                        <Button basic size={'mini'} onClick = {(e,val) => {
                                            this.setState({config:{readonly: !this.state.config.readonly,toolbar:!this.state.config.toolbar,iframe:this.state.config.iframe,iframeStyle: 'html{overflow-y: scroll!important}',
                                                    useIframeResizer: false,openInNewTabCheckbox: true,}})
                                        }}>{i18n.t("Change Mode")}</Button>)}
                                            <br/>
                                            {this.state.publicationError}
                                            <Dropzone onDrop={acceptedFiles => {
                                                console.log(acceptedFiles,acceptedFiles[0].name,acceptedFiles[0].type);
                                                if (this.state.radioCheck === 'image'){
                                                    const dataform = new FormData();
                                                    dataform.append('file', acceptedFiles[0]);
                                                    //console.log(dataform,this.state.selectedFile);
                                                    //console.log(dataform)
                                                    this.props.loadingopen();
                                                    axios.post(urlBackend+'/checkFile', dataform, {
                                                        // receive two    parameter endpoint url ,form data
                                                    }).then(res => { // then print response status
                                                        console.log(res,this.state.id)
                                                        let layer = keys[1].replace("_id","");
                                                        if (res.data.old){
                                                            this.setState({url:urlBackend+'/displayimages?file='+res.data.filename,publicationError:""});
                                                            this.props.loadingclose();
                                                            this.props.alert("info","File already exist. Old file displayed")
                                                        }
                                                        else {
                                                            this.setState({url:urlBackend+'/displayimages?file='+res.data.filename,publicationError:""});
                                                            axios.post(urlBackend+'/layerid', {query:"insert into attachments (f_filename,f_layer_id,f_object_id,f_user_id) values ('"+res.data.filename+"' , (select f_layer_id from layer where f_layer_tablename = '"+layer+"'), "+this.state.id+", "+Cookies.get("id")+")"})
                                                                .then((res) => {
                                                                    //console.log(res)
                                                                    this.props.loadingclose();
                                                                }).catch((error) => {
                                                                this.props.loadingclose();
                                                                console.log(error)
                                                            });
                                                        }

                                                    }).catch((error) => {
                                                        this.props.loadingclose();
                                                        console.log(error)
                                                    });
                                                }
                                                else {
                                                    if (this.state.publicationType !== "" && this.state.publicationName !== ""){
                                                        const dataform = new FormData();
                                                        dataform.append('file', acceptedFiles[0]);
                                                        //console.log(dataform,this.state.selectedFile);
                                                        console.log(dataform)
                                                        axios.post(urlBackend+'/checkFile', dataform, {
                                                            // receive two    parameter endpoint url ,form data
                                                        }).then(res => { // then print response status
                                                            //console.log(res,this.state.id)
                                                            let layer = keys[1].replace("_id","");
                                                            this.setState({url:urlBackend+'/displayimages?file='+res.data.filename});
                                                            axios.post(urlBackend+'/layerid', {query:"insert into attachments (f_filename,f_layer_id,f_object_id,f_user_id,f_mime_type,f_attachment_type_id) values ('"+res.data.filename+"' , (select f_layer_id from layer where f_layer_tablename = '"+layer+"'), "+this.state.id+", "+Cookies.get("id")+", '"+acceptedFiles[0].type+"', 181) returning f_attachment_id as id"})
                                                                .then((res) => {
                                                                    console.log(res.data[0].id)
                                                                    axios.post(urlBackend+'/layerid', {query:"Insert into publications (f_attachment_id,f_publication_type_id,f_user_id) values ("+res.data[0].id+", "+this.state.publicationType+","+Cookies.get("id")+")"})
                                                                        .then((res) => {
                                                                            console.log(res)
                                                                        }).catch((error) => {
                                                                        console.log(error)
                                                                    });
                                                                }).catch((error) => {
                                                                console.log(error)
                                                            });
                                                        }).catch((error) => {
                                                            console.log(error)
                                                        });
                                                    }else{
                                                        this.setState({publicationError:"Fill required fields"})
                                                    }
                                                }


                                            }}
                                            >
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div style={{height:"35px",width:"300px",borderRadius: "15px",padding: "10px",border:"1px solid black"}} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {i18n.t("Drag'n'drop images here, or click to select files")}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <Button icon onClick={() => {
                                        var copyText = document.getElementById("urlInput");
                                        //console.log(copyText)
                                        copyText.value ="<p><img src=\""+copyText.value+"\" alt=\"\" style=\"width: 300px;\"></p>";
                                        copyText.select();
                                        copyText.setSelectionRange(0, 99999);
                                        document.execCommand("copy");
                                        //console.log(value);
                                    }}>
                                        <Icon name='code' />
                                    </Button>
                                    <Input id={"urlInput"} value={this.state.url} action={{
                                        icon: "copy",
                                        onClick: (value) => {
                                            var copyText = document.getElementById("urlInput");
                                            copyText.select();
                                            copyText.setSelectionRange(0, 99999)
                                            document.execCommand("copy");
                                            //console.log(value);
                                        }
                                    }}/>

                                </span>
                                        <br/>
                                        <Label>
                                            <Icon name='tags' /> {i18n.t(name)}
                                        </Label>
                                        <JoditEditor
                                            value={this.state.desc.replaceAll('<a ','<a target="_blank" ')}
                                            config={this.state.config}
                                            style={{"overflowY": "scroll!important"}}
                                            id={'editor_pdf'}
                                            tabIndex={1} // tabIndex of textarea
                                            onBlur={newContent => {
                                                function dataURLtoFile(dataurl, filename) {

                                                    var arr = dataurl.split(',');
                                                    console.log(arr);
                                                    var mime = arr[0].match(/:(.*?);/)[1];
                                                    var bstr = atob(arr[1]);
                                                    var n = bstr.length;
                                                    var u8arr = new Uint8Array(n);

                                                    while(n--){
                                                        u8arr[n] = bstr.charCodeAt(n);
                                                    }

                                                    return new File([u8arr], filename, {type:mime});
                                                }
                                                function addStr(str, index, stringToAdd){
                                                    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
                                                }
                                                console.log (newContent);
                                                let temp;
                                                if (newContent.target === undefined)
                                                    temp = newContent;
                                                else
                                                    temp = newContent.target.innerHTML;
                                                console.log(temp);
                                                //console.log("img test",temp.indexOf('<img '),temp.indexOf('>',temp.indexOf('<img ')))
                                                if (temp.indexOf('<img ') !== -1 && temp.includes('src="data:image')){
                                                    this.setState({saveActive:true,})
                                                    console.log("Change proceed")
                                                    axios.post(urlBackend+'/description', {string:temp}, {
                                                        // receive two    parameter endpoint url ,form data
                                                    }).then(res => { // then print response status
                                                        console.log(res)
                                                        for (let j=0;j<res.data.names;j++){
                                                            let layer = keys[1].replace("_id","");
                                                            axios.post(urlBackend+'/layerid', {query:"insert into attachments (f_filename,f_layer_id,f_object_id,f_user_id) values ('"+res.data.names[j]+"' , (select f_layer_id from layer where f_layer_tablename = '"+layer+"'), "+this.state.id+", "+Cookies.get("id")+")"})
                                                                .then((res) => {
                                                                    //console.log(res)
                                                                }).catch((error) => {
                                                                console.log(error)
                                                            });
                                                        }
                                                        console.log(res.data.string)
                                                        this.setState({saveActive:false,desc:res.data.string.replaceAll('<a ','<a target="_blank" ')});
                                                        this.state.array[index].value = res.data.string.replaceAll('<a ','<a target="_blank" ');
                                                    }).catch((error) => {
                                                        console.log(error)
                                                    });
                                                }else {
                                                    if (newContent.target === undefined) {
                                                        this.setState({desc:newContent.replaceAll('<a ','<a target="_blank" ')});
                                                        this.state.array[index].value = newContent.replaceAll('<a ','<a target="_blank" ');
                                                    }
                                                    else {
                                                        this.setState({desc:newContent.target.innerHTML.replaceAll('<a ','<a target="_blank" ')});
                                                        this.state.array[index].value = newContent.target.innerHTML.replaceAll('<a ','<a target="_blank" ');
                                                    }
                                                }
                                                //console.log(newContent.target.innerHTML)

                                            }} // preferred to use only this option to update the content for performance reasons
                                            onChange={newContent => {}}
                                        />
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <br/>
                    {console.log(this.props.perm)}
                    {console.log(this.props.perm.includes("change"))}
                    {console.log(((layer.toLowerCase() !== 'basins' && layer.toLowerCase() !== 'fields' && layer.toLowerCase() !== 'wells') || this.props.author_id || this.props.perm.includes("change")))}
                    {console.log((this.props.author_id || this.props.perm.includes("change")),Number.parseInt(Cookies.get("id")),(this.props.author_id),this.props.perm.includes("change"), this.props.perm , Number.parseInt(Cookies.get("id")) === Number.parseInt(this.props.author_id) )}
                    {((layer.toLowerCase() !== 'basins' && layer.toLowerCase() !== 'fields' && layer.toLowerCase() !== 'wells') || this.props.author_id || this.props.perm.includes("change"))&&(
                        <span>
                            {console.log('have perm',this.state.infosEdit,layer)}
                        {(this.state.infosEdit && layer !=="f_co2_orgs")&&(<>
                            <div style={{display:"inline-flex"}}>
                                <Checkbox checked={this.state.trigger} onClick={() => {
                                    if (this.state.trigger === false)
                                        this.setState({trigger:true})
                                    else
                                        this.setState({trigger:false})
                                }} slider /><span>&emsp;Add as User Objects</span></div>
                            <br/>

                            <Button disabled={this.state.saveActive} size={'mini'} onClick = {(e,val) => {
                                if (Cookies.get("id") !== undefined) {
                                    let block = false;
                                    let viewState = "null";
                                    if (this.state.trigger)
                                        viewState = "USER";
                                    let column ="";
                                    let values = "";
                                    let reqestOI = "";
                                    let reqestTI = "";
                                    let reqestOU = "";
                                    let reqestTU = "";
                                    let layer = keys[1].replace("_id","");
                                    let array = this.state.array;
                                    for (let i = 1 ; i < array.length;i++ ){
                                        if (array[i].name === "geo_index_start_id") {
                                            if (array[i].value !== null && typeof array[i].value === "number" && array[i+1].value !== null && typeof array[i+1].value === "number" ) {
                                                if(array[i].value < array[i+1].value){
                                                    let valueTemp = array[i].value;
                                                    array[i].value = array[i+1].value;
                                                    array[i+1].value = valueTemp;
                                                }

                                            }
                                            else if (array[i].value !== null  && array[i+1].value !== null ){
                                                if (array[i].value !== null)
                                                    array[i].value = null;
                                                if (array[i+1].value !== null)
                                                    array[i+1].value = null
                                            }
                                        }
                                        if (array[i].name === "basin_class_id")
                                            if (typeof array[i].value === "number") {
                                                for (let j = 1 ; j < array.length;j++ ) {
                                                    if (array[j].name === "basin_subtype_id")
                                                        array[j].value = "(select f_basin_subtype_id from basin_class where f_basin_class_id = " + array[i].value + ")";
                                                    if (array[j].name === "basin_type_id")
                                                        array[j].value = "(select f_basin_type_id from basin_subtype where f_basin_subtype_id = (select f_basin_subtype_id from basin_class where f_basin_class_id = "+array[i].value+"))";
                                                }
                                            }
                                            else if (array[i].name === "basin_subtype_id")
                                                if (typeof array[i].value === "number") {
                                                    for (let j = 1 ; j < array.length;j++ ) {
                                                        if (array[j].name === "basin_type_id")
                                                            array[j].value = "(select f_basin_type_id from basin_subtype where f_basin_subtype_id = " + array[i].value + ")"
                                                    }
                                                }
                                    }
                                    for (let i = 1 ; i < array.length;i++ ){
                                        if (array[i].name === "basin_class_id")
                                        {
                                            if (array[i].value !== null) {
                                                if (typeof array[i].value === "number" || array[i].value.includes("select")) {
                                                    column =column + array[i].name +",";
                                                    values = values + array[i].value + ",";
                                                    //i = i+2;
                                                }
                                            }
                                        }

                                        else if (array[i].name === "basin_subtype_id") {
                                            if (array[i].value !== null) {
                                                if (typeof array[i].value === "number" || array[i].value.includes("select")) {
                                                    column = column + array[i].name + ",";
                                                    values = values + array[i].value + ",";
                                                    // i = i+1;
                                                }
                                            }
                                        }
                                        else if (array[i].name === "basin_type_id") {
                                            if (array[i].value !== null) {
                                                if (typeof array[i].value === "number" || array[i].value.includes("select")) {
                                                    column = column + array[i].name + ",";
                                                    values = values + array[i].value + ",";}
                                                /*else
                                        {
                                            array[i].value = null;
                                            column =column + array[i].name +",";
                                            values = values + array[i].value + ",";}*/
                                            }
                                        }

                                        else if(array[i].name === "basin_fluid_type_id") {
                                            if (array[i].value !== null) {
                                                if (typeof array[i].value === "number" || array[i].value.includes("select")) {
                                                    column = column + array[i].name + ",";
                                                    values = values + array[i].value + ",";}
                                                /*else
                                            {
                                                array[i].value = null;
                                                column =column + array[i].name +",";
                                                values = values + array[i].value + ",";}*/
                                            }
                                        }
                                        else if(array[i].name === "basin_age_id") {
                                            if (array[i].value !== null) {
                                                if (typeof array[i].value === "number" || array[i].value.includes("select")) {
                                                    column = column + array[i].name + ",";
                                                    values = values + array[i].value + ",";}
                                                /*else
                                            {
                                                array[i].value = null;
                                                column =column + array[i].name +",";
                                                values = values + array[i].value + ",";}*/
                                            }
                                        }
                                        else if(array[i].name === "graph" || array[i].name === "username" ||array[i].name === "cost_difirence_coeficient"||array[i].name === "author_id" ||array[i].name==="mother_company") {
                                        }
                                        else if(array[i].name === "object_type") {
                                            if (array[i].value !== null) {
                                                //console.log(array[i].name,array[i].value,layer);
                                                reqestTI = "insert into geo_objects_object_type (geo_objects_id,universal_dict_id,author_id,view_state) values "
                                                for (let k = 0 ; k<array[i].value.length;k++){
                                                    reqestTI = reqestTI + "(" +array[1].value+","+array[i].value[k]+","+Cookies.get("id")+",null)";
                                                    if (k!==array[i].value.length-1){
                                                        reqestTI = reqestTI + ","
                                                    }
                                                }
                                                reqestTI = reqestTI + ";"
                                                reqestTU = "UPDATE geo_objects_object_type SET view_state = 'ARCHIVE' WHERE geo_objects_id = "+array[1].value+";"
                                                //console.log(array[i].name,array[i].value,layer,reqestTI,reqestTU);
                                            }
                                        }
                                        else if(array[i].name === "outcrop") {
                                            if (array[i].value !== null) {
                                                reqestOI= "insert into geo_objects_outcrop (geo_objects_id,universal_dict_id,author_id,view_state) values "
                                                for (let k = 0 ; k<array[i].value.length;k++){
                                                    reqestOI = reqestOI + "(" +array[1].value+","+array[i].value[k]+","+Cookies.get("id")+",null)";
                                                    if (k!==array[i].value.length-1){
                                                        reqestOI = reqestOI + ","
                                                    }
                                                }
                                                reqestOI = reqestOI + ";"
                                                reqestOU = "UPDATE geo_objects_outcrop SET view_state = 'ARCHIVE' WHERE geo_objects_id = "+array[1].value+";"
                                                //console.log(array[i].name,array[i].value,layer,reqestOI,reqestOU);

                                            }
                                        }else if(array[i].name === "protected_area_profile_id") {
                                            /*if (array[i].value !== null) {
                                                reqestOI= "insert into geo_objects_outcrop (geo_objects_id,universal_dict_id,author_id,view_state) values "
                                                for (let k = 0 ; k<array[i].value.length;k++){
                                                    reqestOI = reqestOI + "(" +array[1].value+","+array[i].value[k]+","+Cookies.get("id")+",null)";
                                                    if (k!==array[i].value.length-1){
                                                        reqestOI = reqestOI + ","
                                                    }
                                                }
                                                reqestOI = reqestOI + ";"
                                                reqestOU = "UPDATE geo_objects_outcrop SET view_state = 'ARCHIVE' WHERE geo_objects_id = "+array[1].value+";"
                                                //console.log(array[i].name,array[i].value,layer,reqestOI,reqestOU);



                                            }*/
                                        }
                                        else if (array[i].type === "string" ) {
                                            if (array[i].value !== null) {
                                                column =column + array[i].name +",";
                                                values = values +"'"+ array[i].value + "',";}
                                        }
                                        else if (array[i].type === "word" || array[i].type ==="word3" || array[i].type ==="word2") {
                                            if (array[i].value !== null && typeof array[i].value === "number") {
                                                column =column + array[i].name +",";
                                                values = values + array[i].value + ",";}
                                        }
                                        else if (array[i].type === "textarea") {
                                            if (array[i].value !== null) {
                                                column =column + array[i].name +",";
                                                console.log(this.state.desc ,this.state.desc.replace(/'/g,"\\'"))
                                                values = values +"'"+ this.state.desc.replace(/'/g,"''")  + "',";}
                                        }
                                        else if(array[i].type==="reqest2"){

                                        }
                                        else if(array[i].type==="link"){
                                            if (array[i].value !== null) {
                                                column =column + array[i].name +",";
                                                values = values +"'"+ array[i].value + "',";}
                                        }
                                        else if(array[i].name==="bid_members" || array[i].name==="source"){
                                            if (array[i].value !== null) {
                                                column =column + array[i].name +",";
                                                values = values +"'"+ array[i].value + "',";}
                                        }
                                        else if(array[i].type==="date" || array[i].type==="inactivedate"){
                                            if (array[i].value !== null) {
                                                column =column + array[i].name +",";
                                                values = values +"'"+ array[i].value  + "',";}
                                        }
                                        else {
                                            if (array[i].value !== null && array[i].value !== "") {
                                                column =column + array[i].name +",";
                                                values = values + array[i].value + ",";
                                            }
                                        }

                                    }
                                    column = column + "author_id";
                                    values = values + Cookies.get("id");
                                    if ( layer ==="geo_objects" || layer === "protected_areas"){
                                        console.log("geo_objects")
                                    }
                                    else if (layer ==="licenses"){
                                        column = column + ",data_availability_id,data_source_id"
                                        values = values +",0,0"
                                    }
                                    else if (layer !== "regions" || layer !=="geologicmap"  ) {
                                        column = column + ",data_availability_id,confidence_degree_id,data_source_id"
                                        values = values +",0,0,0"
                                    }
                                    console.log(this.props.userstate , this.state.trigger)
                                    if (layer === "fields" || layer === "geo_objects"){
                                        console.log(this.state.userObjectButton)
                                        if (this.state.trigger){
                                            let query ={type:"update_"+layer, select_type:"updateUser", select_params:{id:array[0].value,author:Cookies.get("id"),columns:column,values:values,layer:layer}}
                                            axios.post(urlBackend+'/postQuery', {query:query})
                                                .then((res) => {
                                                    this.props.layerupdate();
                                                    this.props.alert("success","Infos successfully updated.")
                                                    this.props.close();
                                                    this.props.loadingclose();
                                                }).catch((error) => {
                                                this.props.loadingclose();
                                                this.props.alert("error","Error while updating infos.")
                                            });
                                        }
                                        else{
                                            let query ={type:"update_"+layer, select_type:"update", select_params:{id:array[0].value,author:Cookies.get("id"),columns:column,values:values,layer:layer}}
                                            axios.post(urlBackend+'/postQuery', {query:query})
                                                .then((res) => {
                                                    this.props.layerupdate();
                                                    this.props.alert("success","Infos successfully updated.")
                                                    this.props.close();
                                                    this.props.loadingclose();
                                                }).catch((error) => {
                                                this.props.loadingclose();
                                                this.props.alert("error","Error while updating infos.")
                                            });
                                        }
                                    }
                                    else if (this.state.userObjectButton){
                                        // Processing USER Infos
                                        if (this.state.trigger) {
                                            // Save as USER Infos
                                            column = column + ",view_state"
                                            values = values + ",'USER'"
                                            let query ={type:"update_"+layer, select_type:"archiveUser", select_params:{id:array[0].value,author:Cookies.get("id")}}
                                            let query2 ={type:"insert_"+layer, select_type:"insert", select_params:{id:array[0].value,columns:column,values:values,layer:layer}}
                                            axios.post(urlBackend+'/postQuery', {query:query2})
                                                .then((res) => {
                                                    this.props.layerupdate();
                                                    this.props.alert("success","Infos successfully updated.")
                                                    this.props.close();
                                                    return axios.post(urlBackend+'/postQuery', {query:query})
                                                        .then((res) => {this.props.loadingclose();})

                                                }).catch((error) => {
                                                this.props.loadingclose();
                                                this.props.alert("error","Error while updating infos.")
                                            });
                                        }
                                        else {
                                            // Save as GENERAL Infos ALWAYS EXIST
                                            console.log(this.props)
                                            this.props.loadingopen();
                                            let query ={type:"update_"+layer, select_type:"archiveUsertoGeneral", select_params:{id:array[1].value}}
                                            let query2 ={type:"insert_"+layer, select_type:"insert", select_params:{id:array[0].value,columns:column,values:values,layer:layer}}

                                            axios.post(urlBackend+'/postQuery', {query:query})
                                                .then((res) => {
                                                    this.props.layerupdate();
                                                    this.props.alert("success","Infos successfully updated.")
                                                    this.props.close();
                                                    return axios.post(urlBackend+'/postQuery', {query:query2})
                                                        .then((res) => {this.props.loadingclose();})
                                                }).catch((error) => {
                                                this.props.loadingclose();
                                                this.props.alert("error","Error while updating infos.")
                                            });
                                        }
                                    }
                                    else{
                                        // Processing General Infos
                                        if (this.state.trigger) {
                                            // Save as USER Infos
                                            if (this.props.userobject){
                                                // IF USER INFOS Already Exists
                                                column = column + ",view_state"
                                                values = values + ",'USER'"
                                                this.props.loadingopen();
                                                let query ={type:"update_"+layer, select_type:"archiveUser", select_params:{id:array[0].value,author:Cookies.get("id")}}
                                                let query2 ={type:"insert_"+layer, select_type:"insert", select_params:{id:array[0].value,columns:column,values:values,layer:layer}}
                                                axios.post(urlBackend+'/postQuery', {query:query2})
                                                    .then((res) => {
                                                        this.props.layerupdate();
                                                        this.props.alert("success","Infos successfully updated.")
                                                        this.props.close();
                                                        return axios.post(urlBackend+'/postQuery', {query:query})
                                                            .then((res) => {this.props.loadingclose();})

                                                    }).catch((error) => {
                                                    this.props.loadingclose();
                                                    this.props.alert("error","Error while updating infos.")
                                                });

                                            }else{
                                                // If USER INFOS Not Exists
                                                column = column + ",view_state"
                                                values = values + ",'USER'"
                                                this.props.loadingopen();
                                                let query2 ={type:"insert_"+layer, select_type:"insert", select_params:{id:array[0].value,columns:column,values:values,layer:layer}}
                                                axios.post(urlBackend+'/postQuery', {query:query2})
                                                    .then((res) => {
                                                        this.props.layerupdate();
                                                        this.props.alert("success","Infos successfully updated.")
                                                        this.props.close();
                                                        this.props.loadingclose();

                                                    }).catch((error) => {
                                                    this.props.loadingclose();
                                                    this.props.alert("error","Error while updating infos.")
                                                });
                                            }

                                        }
                                        else { // Save as GENERAL Infos
                                            this.props.loadingopen();
                                            let query ={type:"update_"+layer, select_type:"archive", select_params:{id:array[0].value}}
                                            let query2 ={type:"insert_"+layer, select_type:"insert", select_params:{id:array[0].value,columns:column,values:values,layer:layer}}
                                            axios.post(urlBackend+'/postQuery', {query:query})
                                                .then((res) => {
                                                    this.props.layerupdate();
                                                    this.props.alert("success","Infos successfully updated.")
                                                    this.props.close();
                                                    return axios.post(urlBackend+'/postQuery', {query:query2})
                                                        .then((res) => {this.props.loadingclose();})
                                                }).catch((error) => {
                                                this.props.loadingclose();
                                                this.props.alert("error","Error while updating infos.")
                                            });
                                        }
                                    }
                                }}} >{i18n.t("Save")}</Button>
                            <Button size={'mini'} onClick = {()=>{this.props.handler(layer,layer_id,this.state.name)}} >{i18n.t("Edit Geometry")}</Button>
                            <Button size={'mini'} onClick = {()=>{
                                this.setState({deleteObjectOpen:true})
                            }} >{i18n.t("Delete Object")}</Button></>)}

                    </span>
                    )}

                    <Dialog
                        classes={{ root: 'MenuItem' }}
                        open={this.state.deleteObjectOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={this.handleCancelDelete}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">{"Delete"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                Confirm Delete
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancelDelete} color="primary">
                                Disagree
                            </Button>
                            <Button onClick={()=> {
                                this.setState({deleteObjectOpen:false})
                                console.log(layer,layer_id)
                                this.props.deletehandle(layer,layer_id);
                            }} color="primary">
                                Agree
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <div style={{textAlign: 'right'}}>
                        {(this.props.username === null) && (<span>{i18n.t("Original Infos")}</span>)}
                        {(this.props.username !== null) && (<span>{i18n.t("Last edited by ")+this.props.username}</span>)}
                        {console.log(this.props.update_date,this.props)}
                        {(this.props.update_date !== null) && (<span><br/>{i18n.t("Modified ")+this.props.update_date}</span>)}
                    </div>
                </div>
            )
        }else return (null)
    }}

export default FeatureInfos;