import React from 'react';
import "../Styles/FloatWindow.css"
import {Button, Label, Loader, Checkbox, Dropdown, Input} from "semantic-ui-react";
import Draggable from 'react-draggable'
import ReservesFilter from './ReservesFilter'
import Geo3DViewer from "./Geo3DViewer";
import Visualizer3D from "./Visualizer3D/Visualizer3D";
import GeoReference from "./GeoReference";
import {withTranslation} from "react-i18next";
//import CountryReports from "./CountryReports";
const GeoReferenceTranlate = withTranslation()(GeoReference)
//const CountryReportsTranslate = withTranslation()(CountryReports)

class FloatWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentHeightRatio:100,
            currentWidthRatio:100
        }
        this.minimize = this.minimize.bind(this);
    };


    minimize () {
        if (this.state.Minimize === 'none')
            this.setState({Minimize:'block'})
        else
            this.setState({Minimize:'none'} )
    }

    componentDidMount() {
        let that = this;
        const observer = new ResizeObserver(function(mutations) {
            console.log('mutations:', mutations);
            var w = window.innerWidth
            var h = window.innerHeight
            console.log('mutations:',mutations[0].contentRect.width, mutations[0].contentRect.height,w,h);
            console.log("width ration :",Math.round(mutations[0].contentRect.width/w),", height ration :", Math.round(mutations[0].contentRect.height/h))
            that.setState({
                //currentHeightRatio:Math.round((mutations[0].contentRect.height/h)*100),
                //currentWidthRatio:Math.round((mutations[0].contentRect.width/w)*100)}
                currentHeightRatio:mutations[0].contentRect.height,
                currentWidthRatio:mutations[0].contentRect.width}
            )
        });
        const child = document.getElementsByClassName('Float');
        if (child.length >0)
            observer.observe(child[0]);
    }

    render() {

        console.log(this.props.lasURL)
        let style = {display:this.props.display}
        if (this.props.Component === "Geo3D"){
            style.width="100%"
            style.left="0%"
        }

        if (this.state.Minimize === "none")
            style.height = '40px'
        return(
            <>
                    <Draggable
                        axis="both"
                        handle=".handle"
                        bounds={{top:0}}
                        defaultPosition={{x: 0, y: 0}}
                        position={null}
                        grid={[5, 5]}
                        scale={1}
                        onStart={this.handleStart}
                        onDrag={this.handleDrag}
                        onStop={this.handleStop}>
                        <div className={"Float"} style={style}>
                            <div className="handle"><Label className={'label-no-border'} basic >{this.props.Component}</Label> <Button style={{right:'0px',position: 'absolute'}} className={'button-no-border'} basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button><Button style={{right:'30px',position: 'absolute'}} className={'button-no-border'} basic size={'mini'} onClick={this.minimize} icon><i className="window minimize icon"/></Button></div>
                            <br/>
                            <div style={{overflow: 'scroll',height:"100%",display:this.state.Minimize}}>
                                {(this.props.Component === "Analog")&&(
                                    <>
                                        <ReservesFilter display={"block"} onMapReserves={this.props.onMapReserves} dicts={this.props.dict} names={this.props.dict_names} data={this.props.data} categories = {this.props.categories} filterhandler={(val)=>{
                                            console.log(this.props)
                                            for (let i = 0; i < this.props.Filters.length; i++) {
                                                if (this.props.Filters[i].name.toLowerCase() === "fields") {
                                                    for (let j = 0; j < this.props.Filters[i].filters.length; j++) {
                                                        if (this.props.Filters[i].filters[j].name.toLowerCase() === "FilterByReserves".toLowerCase()) {
                                                            console.log(val)
                                                            this.props.Filters[i].filters[j].filter = val;
                                                            this.props.layerchange();
                                                        }
                                                    }
                                                }
                                            }
                                        }}  name={"FilterByReserves"}/>
                                    </>
                                )}
                                {(this.props.Component === "Georef")&&(
                                    <>
                                        <GeoReferenceTranlate alert={this.props.alert}
                                                              reset={this.props.reset}
                                                              mapPoints={this.props.mapPoints}
                                                              close={this.props.close}
                                                              toLayer={this.props.toLayer}
                                                              preview={this.props.preview}
                                                              updatePoint={this.props.updatePoint}
                                                              deletePoint={this.props.deletePoint}
                                                              addMapPoint={this.props.addMapPoint}
                                                              loadingopen={this.props.loadingopen}
                                                              loadingclose={this.props.loadingclose}
                                                              display={this.props.display} />
                                    </>
                                )}
                                {(this.props.Component === "Geo3D")&&(
                                    <>
                                    {(this.props.display)&&(<>
                                        <Visualizer3D heightRatio={this.state.currentHeightRatio} widthRatio={this.state.currentWidthRatio} onStartupUrl={this.props.url} />
                                    </>)}
                                    </>
                                )}
                                {/*(this.props.Component === "Report")&&(
                                    <>
                                        <CountryReportsTranslate close={this.props.close}/>
                                    </>
                                )*/}
                            </div>
                        </div>
                    </Draggable>
            </>
        )}}
export default FloatWindow;
