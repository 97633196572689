import React from "react";
import Gradient from "javascript-color-gradient";
import {Button, Dropdown, Label} from "semantic-ui-react";
import i18n from "../../i18n";
import reactCSS from 'reactcss';
import {SketchPicker} from "react-color";
import {getIntervalls,stringToColour} from "../../functions"
import {requast2} from "../../VariableInitialize"
// colorGradient1 colorGradient2 result TextData LegendLayer


class LayerColor extends React.Component {
    state = {
        displayColorPicker: false,
        color: '#F5A623',
        colorGradient1:'#000000',
        colorGradient2:'#000000',
        colorGradientPicker1:false,
        colorGradientPicker2:false,
        gradientOption:"paid_value",
        colorArray:[],
        id:this.props.value,
        index:null,
        props:[],
        type:null,
        mode:["single","multiple","gradient","default"],
        gradientArray:[ "paid_value","auction_value"],
        modeselected:"single",
        displaySingle:"inline",
        displayMulti:"none",
        displayGradient:"none",
        displayDefault:"none"
    };

    shouldComponentUpdate(nextProps, nextState,nextContent) {
        //console.log(this.state.modeselected, nextState.modeselected,  this.state.colorGradientPicker1,  nextState.colorGradientPicker1,  this.state.colorGradientPicker2,  nextState.colorGradientPicker2)
        if (this.state.displayColorPicker !== nextState.displayColorPicker || this.state.color !== nextState.color || this.state.colorArray !== nextState.colorArray || this.state.modeselected !== nextState.modeselected || this.state.colorGradient2 !== nextState.colorGradient2 || this.state.colorGradient1 !== nextState.colorGradient1 || this.state.colorGradientPicker1 !== nextState.colorGradientPicker1 || this.state.colorGradientPicker2 !== nextState.colorGradientPicker2) {
            return true;
        }
        //console.log("not updating!")
        return false;

    }

    componentDidMount(){
        //console.log(this.state.id,this.props.value)
        for (let i = 0 ; i < this.props.result.length ; i++) {
            if (this.props.result[i].name === this.state.id) {
                console.log(this.props.result[i].data,this.props.result[i].type)
                if (this.props.result[i].type === "geojson"){
                    console.log(this.props.result[i].data.features[0].properties,Object.keys(this.props.result[i].data.features[0].properties))
                    this.setState({color :this.props.result[i].color,type:this.props.result[i].type,props:Object.keys(this.props.result[i].data.features[0].properties)});
                }else{
                    this.setState({color :this.props.result[i].color,type:null});
                }

            }
        }
    }
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
        for (let i = 0 ; i < this.props.result.length;i++){
            if (this.props.result[i].name === this.props.value) {
                console.log(this.props.result[i].name , this.props.value)
                console.log("click color",this.state.displayColorPicker)
                this.setState({index : i,color : this.props.result[i].color});
            }
        }
    };
    handleClickGradient1 = (color) => {
        console.log("pick color 1");
        this.setState({ colorGradient1: color.hex });
    };
    handleClickGradient2 = (color) => {
        console.log("pick color 2");
        this.setState({ colorGradient2: color.hex });
    };
    handleCloseGradient1 = (color) => {
        this.setState({ colorGradientPicker1: false });
    };
    handleCloseGradient2 = (color) => {
        this.setState({ colorGradientPicker2: false });
    };
    handleOpenGradient1 = (color) => {
        this.setState({ colorGradientPicker1: !this.state.colorGradientPicker1 });
    };
    handleOpenGradient2 = (color) => {
        this.setState({ colorGradientPicker2: !this.state.colorGradientPicker2 });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    handleSelect = (option,value) => {
        console.log(option,value)
        if (value.value === "single")
            this.setState({modeselected:value.value,displaySingle:"inline", displayMulti:"none", displayDefault:"none",displayGradient:"none"});
        else if (value.value === "multiple"){
            this.setState({modeselected:value.value,displaySingle:"none" , displayMulti:"inline", displayDefault:"none",displayGradient:"none"});
        }
        else if (value.value === "gradient")
            this.setState({modeselected:value.value,displaySingle:"none" , displayMulti:"none", displayDefault:"none",displayGradient:"block"});
        else {
            this.setState({modeselected:value.value,displaySingle:"none", displayMulti:"none", displayDefault:"inline",displayGradient:"none"});
            for (let i = 0; i < this.props.result.length; i++) {
                if (this.props.result[i].name === this.state.id) {
                    this.props.result[i].color = this.props.result[i].colordefault;
                    this.props.change();
                }
            }
        }
    };
    handleSelectGradient2 = (option,value) => {
        this.setState({gradientOption:value.value})
    };
    handleSelectGradient = () => {
        let value = {value:this.state.gradientOption}
        console.log(value)
        let mapboxColorArray;
        const colorGradient = new Gradient();
        const color1 = this.state.colorGradient1;
        const color2 = this.state.colorGradient2;
        this.props.colorGradient1 = color1;
        this.props.colorGradient2 = color2;
        colorGradient.setMidpoint(10);
        colorGradient.setGradient(color1, color2);
        console.log(colorGradient.getArray())
        let colorArray = colorGradient.getArray();
        this.setState({colorArray:colorGradient.getArray()})
        mapboxColorArray = requast2({type:"get_legend_color",select_type:"minmax",select_params:{name:value.value}})
            .then(resultReq => {
                console.log(resultReq[0].min,resultReq[0].max)
                let interval;
                if (this.props.minGradient)
                {interval = getIntervalls(this.props.minGradient,this.props.maxGradient,10)}
                else
                {interval = getIntervalls(resultReq[0].min,resultReq[0].max,10)}
                console.log(interval,colorArray,value.value,this.props.minGradient,this.props.maxGradient,resultReq[0].min,resultReq[0].max)

                let mapboxGradientColor = [
                    'interpolate',
                    ['linear'],
                    ['get', value.value],
                    interval[0][0],
                    colorArray[0],
                    interval[1][0],
                    colorArray[1],
                    interval[2][0],
                    colorArray[2],
                    interval[3][0],
                    colorArray[3],
                    interval[4][0],
                    colorArray[4],
                    interval[5][0],
                    colorArray[5],
                    interval[6][0],
                    colorArray[6],
                    interval[7][0],
                    colorArray[7],
                    interval[8][0],
                    colorArray[8],
                    interval[9][0],
                    colorArray[9]
                ]
                for (let j = 0; j < this.props.result.length; j++) {
                    if (this.props.result[j].name === this.state.id) {
                        console.log("check")
                        this.props.result[j].color =mapboxGradientColor;
                        this.props.change();
                    }
                }
            });

    };
    handleSelectGeoJson2 = (option,value) =>{
        for (let j = 0; j < this.props.result.length; j++) {
            if (this.props.result[j].name === this.state.id) {
                //this.props.result[i].data.features[0].properties
                for ( let i = 0 ; i <this.props.result[j].data.features.length; i++){
                    console.log(this.props.result[j].data.features[i].properties[value.value])
                    let text = ''
                    if (this.props.result[j].data.features[i].properties[value.value] !== null)
                        text= this.props.result[j].data.features[i].properties[value.value].toString()
                    console.log(stringToColour(text))
                    this.props.result[j].data.features[i].properties["color"]=stringToColour(text);

                }
                let that = this;
                this.props.result[j].color =['get','color']
                this.props.result[j].visibility = 'none';
                this.props.change();
                setTimeout(function() {
                    that.props.result[j].visibility = 'visible';
                    that.props.change();
                }, 300);

                console.log(this.props.result[j].data)
            }
        }
    }
    handleSelect2 = (option,value) =>{
        let textselect = this.props.TextData[0].data;
        for (let i = 0; i < this.props.TextData.length; i++) {
            if (this.state.id !== this.props.value)
                this.state.id =this.props.value;
            if (this.props.TextData[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                textselect = this.props.TextData[i].data
            }
        }
        for (let i = 0; i < textselect.length; i++) {
            if (value.value === textselect[i]) {
                for (let j = 0; j < this.props.result.length; j++) {
                    if (this.props.result[j].name === this.state.id) {
                        console.log(this.props.result[j],'color'+i)
                        this.props.result[j].color =['get','color'+i] ;
                        console.log(i,this.state.id)
                        if (this.state.id === "Basins"){
                            //console.log("Basins multicolor legend")
                            switch(i) {
                                case 0:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'basin_name'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 1:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'basins_id'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 2:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'basin_area'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 3:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'classified_name'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 4:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'(SELECT f_basin_type_name FROM basin_type WHERE f_basin_type_id=(SELECT f_basin_type_id FROM basin_subtype WHERE f_basin_subtype_id=(select f_basin_subtype_id  FROM basin_class WHERE f_basin_class_id=v_basins.basin_class_id)))'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 5:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'(SELECT f_basin_subtype_name::TEXT FROM basin_subtype WHERE f_basin_subtype_id=(select f_basin_subtype_id  FROM basin_class WHERE f_basin_class_id=v_basins.basin_class_id))'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 6:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'(SELECT f_basin_class_name::Text FROM basin_class WHERE f_basin_class_id=v_basins.basin_class_id)'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 7:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'(SELECT f_basin_age_name::Text FROM basin_age WHERE f_basin_age_id=v_basins.basin_age_id)'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 8:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'v_basins.basin_volume'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 9:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'v_basins.basin_volume'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 10:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'v_basins.basin_volume'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 11:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'v_basins.basin_volume'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 12:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'v_basins.basin_volume'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                case 13:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:'v_basins.basin_volume'}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                                default:
                                    this.props.LegendLayer[0].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_basins",color:''}}).then(result => {this.props.LegendLayer[0].data= result;});
                                    break;
                            }
                        }
                        if (this.state.id === "SubBasins") {
                            console.log("SubBasins multicolor legend")
                            switch (i) {
                                case 0:
                                    //Имя
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'subbasin_name'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 1:
                                    //area
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'area'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 2:
                                    //Классификация
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'classified_name'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 3:
                                    //Lithology
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'lithology'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 4:
                                    //main_res
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'main_reservoir'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 5:
                                    //main_res_rock
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'main_source_rock'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 6:
                                    //Разв
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'area'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 7:
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'area'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 8:
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'area'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 9:
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'area'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 10:
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'area'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                case 11:
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'area'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                                default:
                                    this.props.LegendLayer[1].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_subbasins",color:'area'}}).then(result => {this.props.LegendLayer[1].data= result;});
                                    break;
                            }
                        }
                        if (this.state.id === "Licenses") {
                            console.log("licenses colors")
                            switch (i) {
                                case 0:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'licenses_name'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 1:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'lic_nbr'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 2:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'issue_date'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 3:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'source'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 4:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'(SELECT name_ru::Text from public.universal_dict where dict_code=\'confidence_degree\' and code=v_licenses.confidence_degree_id)'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 5:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'(SELECT name_ru::Text from public.universal_dict where dict_code=\'license_type\' and code=v_licenses.license_type)'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 6:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'(SELECT name_ru::Text from public.universal_dict where dict_code=\'license_status\' and code=v_licenses.license_status)'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 7:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'(SELECT name_ru::Text from public.universal_dict where dict_code=\'license_status\' and code=v_licenses.license_status)'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 8:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'issue_date::TEXT'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 9:
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'expiry_date::TEXT'}}).then(result => {this.props.LegendLayer[3].data= result;});
                                    break;
                                case 10:
                                    console.log("operator")
                                    this.props.LegendLayer[3].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_licenses",color:'operator_name'}}).then(result => {console.log(result,this.props.LegendLayer);this.props.LegendLayer[3].data= result;});
                                    break;
                            }
                        }
                        if (this.state.id === "GeologicObjects") {
                            //console.log("Basins multicolor legend")
                            switch (i) {
                                case 0:
                                    this.props.LegendLayer[4].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_geo_objects",color:'object_name'}}).then(result => {this.props.LegendLayer[4].data= result;});
                                    break;
                                case 1:
                                    this.props.LegendLayer[4].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_geo_objects",color:'(select (select json_agg((select name_ru from universal_dict where universal_dict.universal_dict_id = geo_objects_object_type.universal_dict_id)) from geo_objects_object_type where v_geo_objects.geo_objects_id = geo_objects_object_type.geo_objects_object_type_id))'}}).then(result => {this.props.LegendLayer[4].data= result;});
                                    break;
                                case 2:
                                    this.props.LegendLayer[4].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_geo_objects",color:'(select (select json_agg((select name_ru from universal_dict where universal_dict.universal_dict_id = geo_objects_object_type.universal_dict_id)) from geo_objects_object_type where v_geo_objects.geo_objects_id = geo_objects_object_type.geo_objects_object_type_id))'}}).then(result => {this.props.LegendLayer[4].data= result;});
                                    break;
                                case 3:
                                    this.props.LegendLayer[4].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_geo_objects",color:'(select (select json_agg((select name_ru from universal_dict where universal_dict.universal_dict_id = geo_objects_object_type.universal_dict_id)) from geo_objects_object_type where v_geo_objects.geo_objects_id = geo_objects_object_type.geo_objects_object_type_id))'}}).then(result => {this.props.LegendLayer[4].data= result;});
                                    break;
                            }
                        }
                        if (this.state.id === "Fileds") {
                            console.log("Basins multicolor legend")
                            switch (i) {
                                case 0:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'fields_name'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 1:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'source'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 2:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'development_stage'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 3:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'max_elevation'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 4:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'max_water_depth'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 5:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'reservoirs_number'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 6:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'area'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 7:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'discovery_year'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 8:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'discovery_year'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 9:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'start_production_year'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                case 10:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:'planed_start_production_year'}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                                default:
                                    this.props.LegendLayer[2].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_fields",color:''}}).then(result => {this.props.LegendLayer[2].data= result;});
                                    break;
                            }
                        }
                        if (this.state.id === "ProtectedAreas") {
                            console.log("Basins multicolor legend")
                            switch (i) {
                                case 0:
                                    this.props.LegendLayer[5].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_protected_areas",color:'name'}}).then(result => {this.props.LegendLayer[5].data= result;});
                                    break;
                                case 1:
                                    this.props.LegendLayer[5].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_protected_areas",color:'(select name_ru from universal_dict where universal_dict_id = protected_area_type_id)'}}).then(result => {this.props.LegendLayer[5].data= result;});
                                    break;
                                default:
                                    this.props.LegendLayer[5].data = requast2({type:"get_legend_color",select_type:"get_legend_color",select_params:{layer:"v_protected_areas",color:''}}).then(result => {this.props.LegendLayer[5].data= result;});
                                    break;
                            }
                        }
                        this.props.change();

                    }
                }
            }
        }
    };
    handleChange = (color) => {
        this.setState({ color: color.hex });
        if (this.state.index != null)
            this.props.result[this.state.index].color = color.hex;
        this.props.change();
    };

    render() {
        //console.log(this.state.colorGradientPicker2,this.state.colorGradientPicker2);
        let textselect = this.props.TextData[0].data;
        for (let i = 0; i < this.props.TextData.length; i++) {
            if (this.state.id !== this.props.value)
                this.state.id =this.props.value;
            if (this.props.TextData[i].name.toLowerCase() === this.state.id.toLowerCase()) {
                textselect = this.props.TextData[i].data
            }
        }


        const styles = reactCSS({
            'default': {
                color: {
                    width: '12px',
                    height: '10px',
                    background: this.state.color,
                },
                color1: {
                    width: '12px',
                    height: '10px',
                    background: this.state.colorGradient1,
                },
                color2: {
                    width: '12px',
                    height: '10px',
                    background: this.state.colorGradient2,
                },
                swatch: {
                    padding: '0px',
                    background: '#fff',
                    borderRadius: '0.1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '99999999',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div>
                <Dropdown options={this.state.mode.map(data =>{console.log(data); return ({key: data, text:i18n.t(data), value: data})})} onChange={this.handleSelect} value={this.state.modeselected} fluid selection placeholder="Select an option" />
                <div style={{display: this.state.displaySingle}}>
                    <div style={ styles.swatch } onClick={ this.handleClick }>
                        <div style={ styles.color } />
                    </div>
                    { (this.state.displayColorPicker) && (<div style={ styles.popover }>
                        <div style={styles.cover } onClick={ this.handleClose }/>
                        <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
                    </div> )}
                </div>
                <div style={{display: this.state.displayMulti}}>
                    {(this.state.type !== "geojson")&&(
                        <Dropdown options={textselect.map(data => {console.log(data,i18n.t(data)); return ({key: data, text:i18n.t(data), value: data})})} onChange={this.handleSelect2}  fluid selection placeholder="Select an option" />
                    )}
                    {(this.state.type === "geojson")&&(
                        <Dropdown options={this.state.props.map(data => {console.log(data,i18n.t(data)); return ({key: data, text:i18n.t(data), value: data})})} onChange={this.handleSelectGeoJson2}  fluid selection placeholder="Select an option" />
                    )}
                </div>
                <div style={{display: this.state.displayGradient}}>
                    <div style={{display:"inline"}}>
                        {"Choose 1 color : "}
                        <div style={ styles.swatch } onClick={ this.handleOpenGradient1 }>
                            <div style={ styles.color1 } />
                        </div>
                        { this.state.colorGradientPicker1 ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ this.handleCloseGradient1 }/>
                            <SketchPicker color={ this.state.colorGradient1 } onChange={ this.handleClickGradient1 } />
                        </div> : null }
                    </div>
                    <br/>
                    <div style={{display:"inline"}}>
                        {"Choose 2 color : "}
                        <div style={ styles.swatch } onClick={ this.handleOpenGradient2 }>
                            <div style={ styles.color2 } />
                        </div>
                        { this.state.colorGradientPicker2 ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={ this.handleCloseGradient2 }/>
                            <SketchPicker color={ this.state.colorGradient2 } onChange={ this.handleClickGradient2 } />
                        </div> : null }
                    </div>
                    <br/>
                    <Dropdown options={this.state.gradientArray.map(data => ({key: data, text:data, value: data}))} onChange={this.handleSelectGradient2} fluid selection placeholder="Select an option" />
                    <br/>
                    <Button onClick={this.handleSelectGradient} size={'tiny'} >{"Ok"}</Button>
                    <br/>
                    <div style={{display:"flex"}}>
                        {this.state.colorArray.map((color) => {
                            return (
                                <div key={color} style={ {
                                    display:"inline",
                                    width: '12px',
                                    height: '10px',
                                    background: color,}} />
                            );
                        })}
                    </div>
                </div>
                <div style={{display: this.state.displayDefault}}>
                    <Label basic>{i18n.t('Default Color Displayed')}</Label>
                </div>
            </div>
        )
    }
}
export default LayerColor;