import React, {useEffect, useState} from "react";
import {Checkbox, Dropdown} from "semantic-ui-react";
import i18n from "../../i18n";

const LayerButtonText = ({data2,TextData,title,handle}) => {
    const [active, setActive] = useState(false);
    let check = false;
    let drop = "";
    for (let i = 0; i<data2.length;i++){
        if (data2[i].name === title+"Text") {
            check = data2[i].visibility === "visible";
            drop = data2[i].layoutname;
        }
    }
    useEffect(() => {
        // Обновляем заголовок документа с помощью API браузера

        setActive(check);
    });
    let textselect = TextData[0].data;
    for (let i = 0; i < TextData.length; i++) {
        if (TextData[i].name.toLowerCase() === title.toLowerCase()) {
            textselect = TextData[i].data
        }
    }
    const handleSelect = (option,value) => {
        for (let i = 0; i < data2.length; i++) {
            if (data2[i].name === title+"Text") {
                data2[i].layoutname = value.value;
            }
        }
        handle()
    };
    return (
        <div style={{maxWidth:"160px",position: "relative",zIndex: 98}}>
            <Checkbox
                slider
                checked={active}
                size={'mini'}
                label={i18n.t('Display text')}
                className={'checkbox'}
                onChange={ () => {
                    handle();

                    for (let i = 0; i < data2.length; i++) {
                        //console.log(data2[i].name,title +"Text", data2[i].name === title +"Text")
                        if (data2[i].name === title +"Text") {
                            if (active === false) {
                                data2[i].visibility = 'visible';
                                setActive(true)
                                //console.log("enable", data2[i].visibility, data2)
                            } else {
                                //console.log("disable")
                                data2[i].visibility = 'none';
                                setActive(false)}
                        }
                    }
                }}/>
            <Dropdown  options={textselect.map(data => ({key: data, text:i18n.t(data), value: data}))} onChange={handleSelect} value={drop} fluid selection placeholder="Select an option" />


        </div>
    );
};
export default LayerButtonText;