import React from 'react';
import { Button,Label,Dropdown,Input, Popup, Message, Grid, Table, Icon } from 'semantic-ui-react'
import i18n from "../i18n";
import '../Styles/Reserves.css'
import Plot from '../../node_modules/react-plotly.js/react-plotly';
import axios from "axios";
import ReservesSuccessProbability from "./ReservesSuccessProbability";
import {ReservesGraphPreview} from "./ReservesGraphPreview";
const config = require('../config');
const reservesApi = config.reservesApi;
const default_state = () => ({
    paramsOil: [
        {
            name: "GRV",
            name_en: "Gross rock volume",
            name_ru: "Общий объем породы",
            popup: "Общий объем породы",
            distribution: null,
            values: {}
        },
        {
            name: "NPV",
            name_en: "Net pore volume",
            name_ru: "Чистый объем пор",
            popup: "Чистый объем пор",
            distribution: null,
            values: {}
        },
        {
            name: "HCPV",
            name_en: "Hydrocarbon pore volume",
            name_ru: "Поровый объем углеводородов",
            popup: "Поровый объем углеводородов",
            distribution: null,
            values: {}
        },
        {name: "s", name_en: "Areas", name_ru: "Площадь*", popup: "Площадь", distribution: null, values: {}},
        {
            name: "h",
            name_en: "Total thickness",
            name_ru: "Общая мощность",
            popup: "Общая мощность",
            distribution: null,
            values: {}
        },
        {
            name: "ntg",
            name_en: "Net-to-gross",
            name_ru: "Коэффициент песчанности",
            popup: "Коэффициент песчанности",
            distribution: null,
            values: {}
        },
        {
            name: "hef",
            name_en: "Reservoir thickness",
            name_ru: "Эффективная мощность*",
            popup: "Эффективная мощность",
            distribution: null,
            values: {}
        },
        {
            name: "poro",
            name_en: "Porosity",
            name_ru: "Пористость*",
            popup: "Пористость",
            distribution: null,
            values: {}
        },
        {
            name: "sw",
            name_en: "Water saturation",
            name_ru: "Водонасыщенность*",
            popup: "Водонасыщенность",
            distribution: null,
            values: {}
        },
        {
            name: "fvf",
            name_en: "Formation volume factor",
            name_ru: "Объемный коэффициент*",
            popup: "Объемный коэффициент",
            distribution: null,
            values: {}
        },
        {
            name: "ef",
            name_en: "Expansion factor",
            name_ru: "Коэффициент расширения",
            popup: "Коэффициент расширения",
            distribution: null,
            values: {}
        },
        {
            name: "geomf",
            name_en: "Geometric correction factor",
            name_ru: "Геометрический фактор",
            popup: "Геометрический фактор",
            distribution: null,
            values: {}
        },
        {
            name: "top_depth",
            name_en: "Top depth",
            name_ru: "Кровля пласта",
            popup: "Кровля пласта",
            distribution: null,
            values: {}
        },
        {
            name: "bot_depth",
            name_en: "Bottom depth",
            name_ru: "Подошва пласта",
            popup: "Подошва пласта",
            distribution: null,
            values: {}
        },
        {
            name: "owc",
            name_en: "Oil-water contact",
            name_ru: "ВНК",
            popup: "ВНК",
            distribution: null,
            values: {}
        },
        {
            name: "gwc",
            name_en: "Gas-water contact",
            name_ru: "ГНК",
            popup: "ГНК",
            distribution: null,
            values: {}
        },
        {
            name: "dens",
            name_en: "Oil density",
            name_ru: "Плотность нефти",
            popup: "Плотность нефти",
            distribution: null,
            values: {}
        },
        {
            name: "Kper",
            name_en: "Conversion Factor",
            name_ru: "Пересчетный коэффициен",
            popup: "Пересчетный коэффициен",
            distribution: null,
            values: {}
        },
    ],
    paramsOilDropdownBasic: ["GRV", "NPV", "HCPV", "s", "h", "ntg", "hef", "poro", "sw", "fvf", "ef", "geomf", "top_depth", "bot_depth", "owc", "gwc", "Kper"],
    paramsOilDropdown: ["GRV", "NPV", "HCPV", "s", "h", "ntg", "hef", "poro", "sw", "fvf", "ef", "geomf", "top_depth", "bot_depth", "owc", "gwc", "Kper"],
    paramsOilList: {"GRV":"Gross rock volume", "NPV":"Net pore volume", "HCPV":"Hydrocarbon pore volume", "s":"Areas", "h":"Total thickness", "ntg":"Net-to-gross", "hef":"Reservoir thickness", "poro":"Porosity", "sw":"Water saturation", "fvf":"Formation volume factor", "ef":"Expansion factor", "geomf":"Geometric correction factor", "top_depth":"Top depth", "bot_depth":"Bottom depth", "owc":"Oil-water contact", "gwc":"Gas-water contact", "Kper":"Conversion Factor"},
    paramsOilSelected: [],
    unitsOilList: ["Баррель", "Тонны", "М³"],
    unitsOil: null,
    submitError:false,
    probability:1,
});
class ReservesOil extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...default_state()};
        this.onSend = this.onSend.bind(this);
        this.reset = this.reset.bind(this);
        this.probabilityChange = this.probabilityChange.bind(this)
    };
    probabilityChange (probability) {
        console.log(probability)
        this.setState({probability:probability})
    }
    onSend () {
        const { t } = this.props;
        console.log(this.state.samples,this.state.paramsOilSelected,this.state.paramsOil)
        let samples = this.state.samples;
        let formula = '';
        if (this.state.samples === undefined || this.state.samples === null || isNaN(this.state.samples)) {
            samples = 10000;
        }
        for ( let i = 0; i < this.state.paramsOilSelected.length;i++){
            console.log(this.state.paramsOilSelected[i] )
            if (this.state.paramsOilSelected[i] === 'fvf'){
                formula = formula + '(1/fvf) * '
            } else if(this.state.paramsOilSelected[i] === 'sw' ){
                formula = formula + '(1-sw) * '
            }else {
                formula = formula  + this.state.paramsOilSelected[i] + ' * '
            }
        }
        if (this.state.unitsOil === "Баррель") {
            formula = formula + "6.29"
        }else{
            formula = formula + "1"
        }
        console.log(formula);
        let newJsonFormat = {
            "name": "Model",
            "seed": null,
            "num_samples": samples,
            "config": {
                "region_name": {
                    "composition": "Composition",
                    "inputs": {},
                    "results": {

                    }
                }
            }
        }
        newJsonFormat.config.region_name.results[formula] = {"equation" : formula,"probability":this.state.probability,}
        let params = JSON.parse(JSON.stringify(this.state.paramsOil));
        for (let i = 0; i < params.length; i++) {
            console.log(params[i].distribution)
            if (params[i].distribution === null || params[i].distribution === undefined) {
                console.log(params[i])
                params.splice(i, 1);
                i--;
            }
            else {
                let valuesArray = Object.values(params[i].values)
                for (let j = 0; j < valuesArray.length; j++) {
                    if (valuesArray[j] === undefined || valuesArray[j] === null) {
                        this.setState({submitError:true,submitErrorMassage:"An fields is empty or has invalid value. Please correct and try again"})
                        return;
                    }
                }
                console.log(params[i].distribution,valuesArray[1] , valuesArray[0])
                if (params[i].distribution === 'uniform' || params[i].distribution === 'triangular') {
                    if (valuesArray[0] > valuesArray[1]){
                        this.setState({submitError:true,submitErrorMassage:"Right cant be less then Left. " + params[i].name_ru})
                        return;
                    }

                }
                if (params[i].distribution === 'beta'|| params[i].distribution === 'truncated normal'){
                    if (valuesArray[2] > valuesArray[3]){
                        this.setState({submitError:true,submitErrorMassage:"Right cant be less then Left. " + params[i].name_ru})
                        return;
                    }

                }
                if (params[i].distribution === 'normal'){
                    console.log(valuesArray[0],valuesArray[1],valuesArray[0]-(3*valuesArray[1]),(valuesArray[0]-(3*valuesArray[1]))>0)
                    if ((valuesArray[0]-(3*valuesArray[1]))<0){
                        this.setState({submitError:true,submitErrorMassage:"Error in params for normal distribution. " + params[i].name_ru})
                        return;
                    }

                }
                newJsonFormat.config.region_name.inputs[params[i].popup] = {
                    "variable":params[i].name,
                    "distribution": {
                        "name": params[i].distribution,
                        "params": params[i].values
                    }
                }
            }
        }
        console.log(params)
        let results = {data:[]};
        let graphics = [];
        let layout = {};
        let result_graph = [];
        let result_layout = {};
        let cumulative_data = [];
        let cumulative_layout = {};
        console.log(params)
        console.log(results)
        console.log(newJsonFormat)
        axios.post(reservesApi+'/getGraphData',newJsonFormat,{ headers: { "Content-Type": "application/json" } })
            .then(result => {
                console.log(result.data)
                console.log(result.data.region_name)
                console.log(Object.keys(result.data.region_name))
                let keys = Object.keys(result.data.region_name);
                let data = result.data.region_name;
                layout = {
                    height: 700,
                    width: 1200,
                    grid: {rows: Math.ceil(Math.sqrt(params.length+1)), columns: Math.round(Math.sqrt(params.length+1)), pattern: 'independent'},
                    yaxis: {
                        title: {
                            text: i18n.t('frequency'),
                            standoff: 0
                        }
                    },
                    xaxis:{
                        title: {
                            text:i18n.t(keys[0]),
                            standoff:8
                        }
                    }
                };
                result_layout = {
                    height: 700,
                    width: 1200,
                    barmode: "overlay",
                    yaxis: {
                        title: {
                            text: i18n.t('frequency'),
                            standoff: 0
                        }
                    },
                    xaxis:{
                        title: {
                            text:i18n.t('result')+ " : " +i18n.t(keys[keys.length-1]),
                            standoff:8
                        }
                    }
                };
                cumulative_layout  = {
                    height: 700,
                    width: 1200,
                    barmode: "overlay",
                    yaxis: {
                        title: {
                            text: i18n.t('frequency'),
                            standoff: 0
                        }
                    },
                    xaxis:{
                        title: {
                            text:i18n.t('result')+ " : " +i18n.t(keys[keys.length-1]),
                            standoff:8
                        }
                    }
                };
                for (let i = 0 ; i<keys.length-2; i++){
                    console.log(data[keys[i]].values)
                    let trace1 = {
                        x: data[keys[i]].values,
                        xaxis: "x"+(i+1),
                        yaxis: "y"+(i+1),
                        type: "histogram",
                        histnorm: "probability density",
                        opacity: 0.8,
                        name:i18n.t(keys[i]),
                        marker: {
                            color: this.props.colors[i],
                        },
                    };
                    graphics.push(trace1);
                    if (i>0){
                        layout['yaxis'+(i+1)] = {
                            title:{
                                text:i18n.t('frequency'),
                                standoff:0
                            }
                        }
                        layout['xaxis'+(i+1)] ={
                            title: {
                                text:i18n.t(keys[i]),
                                standoff:8
                            }
                        }
                    }
                }
                let trace1 = {
                    x: data[keys[keys.length-2]].values,
                    xaxis: "x",
                    yaxis: "y",
                    type: "histogram",
                    histnorm: "probability density",
                    opacity: 0.8,
                    name:i18n.t(keys[keys.length-1]),
                    marker: {
                        color: this.props.colors[keys.length-1],
                    },
                };
                let probability_array =JSON.parse(JSON.stringify(data[keys[keys.length-1]].values))
                for ( let j = 0 ; j < probability_array.length;j++){
                    if ( probability_array[j] === 0)
                        probability_array[j] = -999;
                }
                let trace1_probability = {}
                if (this.state.probability < 1)
                    trace1_probability = {
                        x: probability_array,
                        xaxis: "x",
                        yaxis: "y",
                        type: "histogram",
                        histnorm: "probability density",
                        opacity: 0.8,
                        name:i18n.t(keys[keys.length-1])+"_Success_Probability",
                        marker: {
                            color: "green",
                        },
                    };
                result_graph.push(trace1,trace1_probability)
                let trace2 = {
                    x: data[keys[keys.length-2]].values,
                    xaxis: "x",
                    yaxis: "y",
                    type: "histogram",
                    histnorm: "probability density",
                    cumulative: { enabled: true, direction: "decreasing" },
                    opacity: 0.8,
                    name:i18n.t(keys[keys.length-1]),
                    marker: {
                        color: this.props.colors[keys.length],
                    },
                };
                let table = []
                table.push({"Name":i18n.t(keys[keys.length-1]),"P10":Number.parseFloat(data[keys[keys.length-2]].stats.P10[0]).toFixed(2),"P50":Number.parseFloat(data[keys[keys.length-2]].stats.P50[0]).toFixed(2),"P90":Number.parseFloat(data[keys[keys.length-2]].stats.P90[0]).toFixed(2)})
                table.push({"Name":i18n.t(keys[keys.length-1]+"_Success_Probability"),"P10":(data[keys[keys.length-1]].stats ? Number.parseFloat(data[keys[keys.length-1]].stats.P10[0]).toFixed(2) : "Not availible yet"), "P50":(data[keys[keys.length-1]].stats ? Number.parseFloat(data[keys[keys.length-1]].stats.P50[0]).toFixed(2) : "Not availible yet"), "P90":(data[keys[keys.length-1]].stats ? Number.parseFloat(data[keys[keys.length-1]].stats.P90[0]).toFixed(2) : "Not availible yet")})

                let trace2P10dot = {
                    x: [Math.min.apply(Math, data[keys[keys.length-2]].values),data[keys[keys.length-2]].stats.P10[0],data[keys[keys.length-2]].stats.P10[0]],
                    y: [data[keys[keys.length-2]].stats.P10[1]/100,data[keys[keys.length-2]].stats.P10[1]/100,0],
                    mode: 'lines',
                    name: 'P10',
                    legendgroup: 'markers1',
                    showlegend:false,
                    line: {
                        color: 'rgb(0,0,0)',
                        dash: 'dot',
                        width: 2
                    }
                };
                //result_probability
                let trace2P50dot = {
                    x: [Math.min.apply(Math, data[keys[keys.length-2]].values),data[keys[keys.length-2]].stats.P50[0],data[keys[keys.length-2]].stats.P50[0]],
                    y: [data[keys[keys.length-2]].stats.P50[1]/100,data[keys[keys.length-2]].stats.P50[1]/100,0],
                    mode: 'lines',
                    name: 'P50',
                    legendgroup: 'markers1',
                    showlegend:false,
                    line: {
                        color: 'rgb(0,0,0)',
                        dash: 'dot',
                        width: 2
                    }
                };
                let trace2P90dot = {
                    x: [Math.min.apply(Math, data[keys[keys.length-2]].values),data[keys[keys.length-2]].stats.P90[0],data[keys[keys.length-2]].stats.P90[0]],
                    y: [data[keys[keys.length-2]].stats.P90[1]/100,data[keys[keys.length-2]].stats.P90[1]/100,0],
                    mode: 'lines',
                    name: 'P90',
                    legendgroup: 'markers1',
                    showlegend:false,
                    line: {
                        color: 'rgb(0,0,0)',
                        dash: 'dot',
                        width: 2
                    }
                };
                var trace3 = {
                    x: [data[keys[keys.length-2]].stats.P10[0], data[keys[keys.length-2]].stats.P50[0], data[keys[keys.length-2]].stats.P90[0]],
                    y: [data[keys[keys.length-2]].stats.P10[1]/100, data[keys[keys.length-2]].stats.P50[1]/100, data[keys[keys.length-2]].stats.P90[1]/100],
                    mode: 'markers+text',
                    type: 'scatter',
                    name: 'markers P10,P50,P90',
                    legendgroup: 'markers1',
                    text: ['P10 '+parseFloat(data[keys[keys.length-2]].stats.P10[0]).toFixed(2), 'P50 '+parseFloat(data[keys[keys.length-2]].stats.P50[0]).toFixed(2), 'P90 '+parseFloat(data[keys[keys.length-2]].stats.P90[0]).toFixed(2)],
                    textposition: 'top center',
                    textfont: {
                        family:  'Raleway, sans-serif'
                    },
                    marker: { size: 12 }
                };
                let trace4Probability = {}
                if (this.state.probability < 1)
                    trace4Probability = {
                        x: data[keys[keys.length-1]].values,
                        xaxis: "x",
                        yaxis: "y",
                        type: "histogram",
                        histnorm: "probability density",
                        connectgaps: false,
                        cumulative: { enabled: true, direction: "decreasing" },
                        opacity: 0.8,
                        name:i18n.t(keys[keys.length-1] + " probability"),
                        marker: {
                            color: this.props.colors[keys.length+1],
                        },
                    };

                let nullArray  = []
                let valueArray = []
                for ( let j = 0 ; j < data[keys[keys.length-1]].values.length;j++){
                    if ( data[keys[keys.length-1]].values[j] === 0 ){
                        nullArray.push(data[keys[keys.length-1]].values[j])
                    }else{
                        valueArray.push(data[keys[keys.length-1]].values[j])
                    }
                }
                result_layout.xaxis.range = [0,Math.max(...data[keys[keys.length-2]].values)];
                console.log(valueArray,nullArray,Math.min(...valueArray))
                cumulative_layout.xaxis.range = [Math.min(...valueArray),Math.max(...data[keys[keys.length-2]].values)];

                cumulative_data.push(trace2,trace2P10dot,trace2P50dot,trace2P90dot,trace3,trace4Probability)
                if (data[keys[keys.length-1]].stats){
                    let trace4P10dot = {
                        x: [Math.min.apply(Math, data[keys[keys.length-1]].values),data[keys[keys.length-1]].stats.P10[0],data[keys[keys.length-1]].stats.P10[0]],
                        y: [data[keys[keys.length-1]].stats.P10[1]/100,data[keys[keys.length-1]].stats.P10[1]/100,0],
                        mode: 'lines',
                        name: 'P10_SP',
                        legendgroup: 'markers2',
                        showlegend:false,
                        line: {
                            color: 'rgb(0,0,0)',
                            dash: 'dot',
                            width: 2
                        }
                    };
                    //result_probability
                    let trace4P50dot = {
                        x: [Math.min.apply(Math, data[keys[keys.length-1]].values),data[keys[keys.length-1]].stats.P50[0],data[keys[keys.length-1]].stats.P50[0]],
                        y: [data[keys[keys.length-1]].stats.P50[1]/100,data[keys[keys.length-1]].stats.P50[1]/100,0],
                        mode: 'lines',
                        name: 'P50_SP',
                        legendgroup: 'markers2',
                        showlegend:false,
                        line: {
                            color: 'rgb(0,0,0)',
                            dash: 'dot',
                            width: 2
                        }
                    };
                    let trace4P90dot = {
                        x: [Math.min.apply(Math, data[keys[keys.length-1]].values),data[keys[keys.length-1]].stats.P90[0],data[keys[keys.length-1]].stats.P90[0]],
                        y: [data[keys[keys.length-1]].stats.P90[1]/100,data[keys[keys.length-1]].stats.P90[1]/100,0],
                        mode: 'lines',
                        name: 'P90_SP',
                        legendgroup: 'markers2',
                        showlegend:false,
                        line: {
                            color: 'rgb(0,0,0)',
                            dash: 'dot',
                            width: 2
                        }
                    };
                    let trace5 = {
                        x: [data[keys[keys.length-1]].stats.P10[0], data[keys[keys.length-1]].stats.P50[0], data[keys[keys.length-1]].stats.P90[0]],
                        y: [data[keys[keys.length-1]].stats.P10[1]/100, data[keys[keys.length-1]].stats.P50[1]/100, data[keys[keys.length-1]].stats.P90[1]/100],
                        mode: 'markers+text',
                        type: 'scatter',
                        legendgroup: 'markers2',
                        name: 'markers SP P10,P50,P90',
                        text: ['P10 '+parseFloat(data[keys[keys.length-1]].stats.P10[0]).toFixed(2), 'P50 '+parseFloat(data[keys[keys.length-1]].stats.P50[0]).toFixed(2), 'P90 '+parseFloat(data[keys[keys.length-1]].stats.P90[0]).toFixed(2)],
                        textposition: 'top center',
                        textfont: {
                            family:  'Raleway, sans-serif'
                        },
                        marker: { size: 12 }
                    };
                    cumulative_data.push(trace4P10dot,trace4P50dot,trace4P90dot,trace5)
                }

                this.props.onSetData(graphics,layout,result_graph,result_layout,cumulative_data,cumulative_layout)
            })
            .catch(err => {console.log(err)})

    };
    reset () {
        this.props.resetGraph()
        this.setState({...default_state()})
        console.log(this.state)
    };
    onDropdownChange (option, value, name) {
        let params = JSON.parse(JSON.stringify(this.state.paramsOil));
        let dist ;
        let index = 0;
        let list ={};
        for (let i = 0; i < params.length; i++) {
            if (params[i].name === name) {
                params[i].distribution = value.value;
                dist = value.value;
                index = i;
            }
        }
        console.log(index,dist,this.props.distribution)
        for (let i = 0 ; i < this.props.distribution.length;i++){
            console.log(dist,this.props.distribution[i].name)
            if (dist === this.props.distribution[i].name){
                let values = this.props.distribution[i].values;
                for (let j = 0; j < values.length;j++){
                    list[values[j]] = null;
                }
                params[index].values = list;
            }
        }
        console.log(params,list)
        this.setState({paramsOil:params})
    };
    paramsDelete (value){
        console.log(value)
        let paramsToSelect = this.state.paramsOilDropdown;
        let selectedParams = this.state.paramsOilSelected;
        //["GRV", "NPV", "HCPV", "s", "h", "ntg", "hef", "poro", "sw", "fvf", "ef", "geomf", "top_depth", "bot_depth", "owc", "gwc", "dens", "Kper"],
        switch (value){
            case "GRV" :
                // S H geomf
                paramsToSelect.push('s');
                paramsToSelect.push('h');
                paramsToSelect.push('geomf');
                paramsToSelect.push('NPV');
                paramsToSelect.push('HCPV');
                break;
            case "NPV":
                //GRV*NtG
                paramsToSelect.push('s');
                paramsToSelect.push('h');
                paramsToSelect.push('geomf');
                paramsToSelect.push('ntg');
                paramsToSelect.push('hef');
                paramsToSelect.push('GRV');
                paramsToSelect.push('HCPV');
                break;
            case "HCPV":
                //NPV * (1-So)
                paramsToSelect.push('s');
                paramsToSelect.push('h');
                paramsToSelect.push('geomf');
                paramsToSelect.push('ntg');
                paramsToSelect.push('sw');
                paramsToSelect.push('NPV');
                paramsToSelect.push('GRV');
                break;
            case "s":
                paramsToSelect.push('GRV');
                paramsToSelect.push('NPV');
                paramsToSelect.push('HCPV');
                break;
            case "h":
                paramsToSelect.push('hef');
                paramsToSelect.push('HCPC');
                paramsToSelect.push('NPV');
                break;
            case "ntg":
                paramsToSelect.push('hef');
                break;
            case "poro":
                break;
            case "sw":
                break;
            case "fvf":
                paramsToSelect.push('Kper');
                break;
            case "hef":
                paramsToSelect.push('h');
                paramsToSelect.push('ntg');
                break;
            case "geomf":
                break;
            case "ef":
                break;
            case "top_depth":
                break;
            case "bot_depth":
                break;
            case "owc":
                break;
            case "gwc":
                break;
            case "Kper":
                paramsToSelect.push('fvf');
                break;
        }
        paramsToSelect.push(value);
        selectedParams.splice(selectedParams.indexOf(value),1);
        this.setState({paramsOilDropdown: paramsToSelect, paramsOilSelected: selectedParams})
    };
    paramsSelection (value){
        console.log(value)
        let paramsToSelect = this.state.paramsOilDropdown;
        let selectedParams = this.state.paramsOilSelected;
        //["GRV", "NPV", "HCPV", "s", "h", "ntg", "hef", "poro", "sw", "fvf", "ef", "geomf", "top_depth", "bot_depth", "owc", "gwc", "dens", "Kper"],
        switch (value){
            case "GRV" :
                // S H geomf
                paramsToSelect.splice(paramsToSelect.indexOf('s'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('h'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('geomf'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('NPV'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('HCPV'),1);
                break;
            case "NPV":
                //GRV*NtG
                paramsToSelect.splice(paramsToSelect.indexOf('s'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('h'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('geomf'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('ntg'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('hef'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('GRV'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('HCPV'),1);
                break;
            case "HCPV":
                //NPV * (1-So)
                paramsToSelect.splice(paramsToSelect.indexOf('s'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('h'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('geomf'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('ntg'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('sw'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('NPV'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('GRV'),1);
                break;
            case "s":
                paramsToSelect.splice(paramsToSelect.indexOf('GRV'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('NPV'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('HCPV'),1);
                break;
            case "h":
                paramsToSelect.splice(paramsToSelect.indexOf('hef'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('HCPC'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('NPV'),1);
                break;
            case "ntg":
                paramsToSelect.splice(paramsToSelect.indexOf('hef'),1);
                break;
            case "poro":
                break;
            case "sw":
                break;
            case "fvf":
                paramsToSelect.splice(paramsToSelect.indexOf('Kper'),1);
                break;
            case "hef":
                paramsToSelect.splice(paramsToSelect.indexOf('h'),1);
                paramsToSelect.splice(paramsToSelect.indexOf('ntg'),1);
                break;
            case "geomf":
                break;
            case "ef":
                break;
            case "top_depth":
                break;
            case "bot_depth":
                break;
            case "owc":
                break;
            case "gwc":
                break;
            case "Kper":
                paramsToSelect.splice(paramsToSelect.indexOf('fvf'),1);
                break;
        }
        paramsToSelect.splice(paramsToSelect.indexOf(value),1);
        selectedParams.push(value);
        this.setState({paramsOilDropdown: paramsToSelect, paramsOilSelected: selectedParams})
    };
    onUnitsSelect (value) {
        //["Баррель", "Тонны", "М³"]
        let selectedParams = this.state.paramsOilSelected;
        switch (value) {
            case "Баррель":
                if (selectedParams.includes('dens'))
                    selectedParams.splice(selectedParams.indexOf("dens"),1);
                break;
            case "Тонны":
                selectedParams.push("dens");
                break;
            case "М³":
                if (selectedParams.includes('dens'))
                    selectedParams.splice(selectedParams.indexOf("dens"),1);
                break;
        }
        this.setState({unitsOil:value})
    };
    render() {
        let samplesError = false;
        if(this.state.submitError&&(this.state.samples === undefined || this.state.samples ===null))
            samplesError = true;
        return (
            <>
                <br/>
                <Dropdown className={"size75dropdown"}
                          placeholder={"Select units"}
                          selection
                          style={{zIndex: 10001,position: "relative"}}
                          onChange={(event,options)=>{console.log(options.value);this.onUnitsSelect(options.value)}}
                          options= {this.state.unitsOilList.map(data => ({key: data, text:data, value: data}))} />
                {(this.state.unitsOil !== null )&&(
                    <>
                        <br/>
                        <Dropdown className={"size75dropdown"}
                                  placeholder={"Select params"}
                                  selection
                                  style={{zIndex: 10000,position: "relative"}}
                                  onChange={(event,options)=>{this.paramsSelection(options.value)}}
                                  options= {this.state.paramsOilDropdown.map(data => ({key: data, text:i18n.t(this.state.paramsOilList[data]), value: data}))} />
                    </>
                )}
                {((this.state.paramsOilSelected.length > 0 && this.state.unitsOil !== "Тонны") || (this.state.paramsOilSelected.length > 1))&&(
                    <>
                        {this.state.paramsOil.map(({name,name_en,name_ru,popup,distribution,values},index)=>{
                            if (this.state.paramsOilSelected.includes(name))
                                return (
                                    <>
                                        <div >
                                            <Popup className={"popup"} content={name} trigger={<Label className={"size75label1"} >{i18n.t(name_ru)}</Label>}/>
                                            <Dropdown
                                                className={"size75dropdown"}
                                                placeholder={i18n.t('Select Distribution')}
                                                selection
                                                style={{zIndex: 9999-index,position: "relative"}}
                                                onChange={(option,value)=>{this.onDropdownChange(option,value,name)}}
                                                options= {this.props.distributionDropdown.map(data => ({key: data, text:i18n.t(data), value: data}))}
                                            />
                                            <div className={"size75div"}>
                                                {Object.keys(values).map((key,value) => {
                                                    let error = false;
                                                    if (this.state.submitError && ( values[key] === undefined || values[key] === null || isNaN(values[key])))
                                                        error = true;
                                                    return (
                                                        <div className={"size75div2"}><Label className={"size75label2"}>{key}</Label><Input key={name+key} id={name+key} className={"size75input"} value={this.state.paramsOil[index].values[key]} error={error} min="0" type={"number"} onChange={(e,input)=>{
                                                            let params = this.state.paramsOil;
                                                            if ( Number.parseFloat(input.value)>=0 || input.value === ''){
                                                                if (Number.parseFloat(input.value)>=1 &&(name==="ntg"||name==="poro"||name==="sw"||name==="geomf"||name==="den"||name==="ef") &&(key==='left'||key==='right'||key==='mode'||key==='mean')){
                                                                    document.getElementById(name+key).value = 1;
                                                                    params[index].values[key] = 1;
                                                                    this.setState({params: params})
                                                                }
                                                                else{
                                                                    params[index].values[key] = Number.parseFloat(input.value.replace('-', ''));
                                                                    this.setState({params: params})
                                                                }
                                                            }else
                                                                document.getElementById(name+key).value = document.getElementById(name+key).value.replace('-','') ;
                                                        }} />

                                                        </div>

                                                    )
                                                })}
                                                <Icon name={"eye"} onMouseEnter={(e)=>{
                                                    console.log("onMouseEnter",e.clientX,e.clientY)
                                                    this.props.setPreview(false,values,name,distribution,popup,e.clientX,e.clientY)
                                                }} onMouseLeave={(e)=>{console.log("onMouseLeave")
                                                    this.props.setPreview(true)
                                                }} />
                                            </div>
                                            {(name !== 'dens')&&(<Icon onClick={()=>this.paramsDelete(name)} bordered inverted color='red' name='trash' />)}
                                        </div>

                                    </>
                                )
                        })}
                    </>
                )}
                {(this.state.submitError)&&(<><Message size={"small"} compact negative>
                    <Message.Header>{"Error"}</Message.Header>
                    <p>{this.state.submitErrorMassage}</p>
                </Message><br/></>)}
                <ReservesSuccessProbability setProbability={this.probabilityChange} display={"block"}/>
                <div className={"size75div3"}>
                    <Grid>
                        <Grid.Column width={8}>
                            <Table className={"ui very basic table"}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            <Label className={"size75label2"}>
                                                {i18n.t("Samples")}
                                            </Label>
                                            <Input className={"size75input"} error={samplesError} type={"number"} min="0" value={this.state.samples}  onChange={(e,input)=>{
                                                if (Number.parseFloat(input.value)>=0  || input.value === '')
                                                    this.setState({samples:Number.parseFloat(input.value)})
                                            }} style={{width:"100px"}} />
                                            <Button className={"size75Button"} onClick={this.onSend}>
                                                {i18n.t("Send")}
                                            </Button>
                                            <Button className={"size75Button"} onClick={this.reset}>
                                                {i18n.t("Reset")}
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid>
                </div>
            </>
        )
    }
}
export default ReservesOil;