import React from "react";
import {Dropdown, Button, Label, Input, Checkbox} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import {age_word_to,age_word_from,age_word} from "../VariableInitialize"
import {Helmet} from "react-helmet";
import MapReserves from "./MapReserves";
import ('../Styles/ReservesFilter.css')
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}

class ReservesFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            age_from:age_word_from,
            age_to:age_word_to,
            Filters:{'Условия осадконакопления':[],
                'Геологический возраст':[],
                'Структура и геометрия':[],
                'Свойства резервуара':[],
                'Свойства флюида и давление':[],
                'Ресурсы и запасы':[],
                'Параметры добычи и КИН':[],
                'Другое':[]},
            Filters_params:[],
            Filters_categories:[],
            Filters_age:[],
            Filters_facies:[],
            Mode:false,
            Age:['age'],
            Facies:['facies','deposition_system','deposition_env'],
            selectedFilters:{'Условия осадконакопления':[],
                'Геологический возраст':[],
                'Структура и геометрия':[],
                'Свойства резервуара':[],
                'Свойства флюида и давление':[],
                'Ресурсы и запасы':[],
                'Параметры добычи и КИН':[],
                'Другое':[]},
            selectedFilters_params:[],
            selectedFilters_categories:[],
            selectedFilters_age:[],
            selectedFilters_facies:[],
            objectsDict:[],
            selectedObject:null,
            mapReservesIds:null,
            mapReservesDisplay:'none',
            query:null,
            ids:[],
        }
        this.onFiltersSelection = this.onFiltersSelection.bind(this);
        this.onFromChange = this.onFromChange.bind(this)
        this.onToChange = this.onToChange.bind(this)
        this.onDictChange = this.onDictChange.bind(this)
        this.onAgeFromChange = this.onAgeFromChange.bind(this)
        this.onAgeToChange = this.onAgeToChange.bind(this)
    };
    onAgeFromChange (e,data,folder) {
        let FiltersData =this.state.Filters
        let Filters =FiltersData[folder]
        console.log(e,data)
        for (let i = 0 ;i < Filters.length;i++){
            if ( Filters[i].field === "age"){
                let key = null;
                for (let j = 0 ;j < data.options.length;j++){
                    if (data.options[j].value === data.value)
                        key = data.options[j].key
                }
                Filters[i].from = Number.parseFloat(key)
                if (Filters[i].to === null)
                    Filters[i].to = 4601;
            }

            if (Filters[i].value !== undefined)
                delete Filters[i]['value']
        }
    }
    onAgeToChange (e,data,folder) {
        let FiltersData =this.state.Filters
        let Filters =FiltersData[folder]
        console.log(e,data)
        for (let i = 0 ;i < Filters.length;i++){
            if ( Filters[i].field === "age"){
                let key = null;
                for (let j = 0 ;j < data.options.length;j++){
                    if (data.options[j].value === data.value)
                        key = data.options[j].key
                }
                Filters[i].to = Number.parseFloat(key)
                if (Filters[i].from === null)
                    Filters[i].from = 0;
            }
            if (Filters[i].value !== undefined)
                delete Filters[i]['value']
        }
    }
    onDictChange (name,values,folder){
        let FiltersData =this.state.Filters
        let Filters =FiltersData[folder]
        for (let i = 0 ;i < Filters.length;i++){
            if ( Filters[i].field === name)
                Filters[i].value = values
            if (Filters[i].to !== undefined)
                delete Filters[i]['to']
            if (Filters[i].from !== undefined)
                delete Filters[i]['from']
        }

                this.setState({Filters:FiltersData})

        //this.setState({Filters:Filters})
    }
    onFiltersSelection (e,data,folder) {
        let FiltersData =this.state.Filters
        let Filters =FiltersData[folder]
        let Selected_Filters = this.state.selectedFilters
        console.log(e,data,Filters,folder,FiltersData,Selected_Filters)
        if ( Filters.length > data.value.length){
            //удаление из фильтров
            for ( let i  = 0 ; i < Filters.length;i++){
                if ( !data.value.includes(Filters[i].field)){
                    Filters.splice(i, 1);
                }
            }

        }else if ( Filters.length < data.value.length){
            //добавление в фильтры
            for ( let i = 0 ; i < data.value.length; i++){
                let exist = false;
                console.log(Filters,data.value)
                if (Filters.length > 0)
                    for ( let j = 0 ; j < Filters.length; j++){
                        if (Filters[j].field === data.value[i] ){
                            exist = true
                        }
                    }
                if (!exist){
                    Filters.push({field:data.value[i],from:null,to:null,value:null})
                }
            }

        }
        Selected_Filters[folder] = data.value;
        console.log(Selected_Filters,Filters,this.state.selectedFilters)
        this.setState({selectedFilters:Selected_Filters,Filters:FiltersData})
    };
    onFromChange (name,value,folder) {
        let FiltersData =this.state.Filters
        let Filters =FiltersData[folder]
        for (let i = 0 ;i < Filters.length;i++){
            if ( Filters[i].field === name)
                Filters[i].from = Number.parseFloat(value)
            if (Filters[i].value !== undefined)
                delete Filters[i]['value']
        }

        this.setState({Filters:FiltersData})

    }
    onToChange (name,value,folder) {
        let FiltersData =this.state.Filters
        let Filters =FiltersData[folder]
        for (let i = 0 ;i < Filters.length;i++){
            if ( Filters[i].field === name)
                Filters[i].to = Number.parseFloat(value)
            if (Filters[i].value !== undefined)
                delete Filters[i]['value']
        }
        this.setState({Filters:FiltersData})

    }
    handleMapReserves = (ids) => {
        if (this.state.mapReservesDisplay === "none")
            this.setState({mapReservesDisplay:"block",mapReservesIds:ids})
        else
            this.setState({mapReservesDisplay:"none",mapReservesIds:null})
    };
    render() {
        const { t } = this.props;
        console.log(this.props)
        // eslint-disable-next-line no-unused-vars
        let files;
        return(
            <div style={{display:this.props.display}} >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{"Аналоги"}</title>
                    <meta name="description" content={"Поиск месторождений аналогов бенчмарк benchmark статистика аналитика подсчетные параметры"} />
                </Helmet>
                <div>
                    <div style={{transform: "scale(0.85)",
                        left: "100px",
                        position: "absolute",
                        top: "-9px",}}>
                        <Label className={"label-no-border"} basic>{"AND"}</Label><Checkbox checked={this.state.Mode} onChange={(e,data)=>this.setState({Mode:!this.state.Mode})} toggle /><Label className={"label-no-border"} basic>{"OR"}</Label>
                        <br/>
                    </div>
                    <div>
                        <div style={{width:"50%",float: "left"}}>
                            {this.props.categories.map((category,index)=>{
                                //console.log(this.props.data,this.props.data[category])
                                if (this.props.data[category] !== null)
                                    //
                                return(
                                    <div style={{position:"relative",zIndex:999999999-index}} id={category}>
                                        <Label>{category + " :"}</Label>
                                        <br/>
                                        {console.log(this.state.selectedFilters[category])}
                                        <Dropdown
                                            style={{top: '13px',overflow: 'inherit'}}
                                            options={this.props.data[category].map(data => ({key: data, text:data, value: data}))}
                                            value={this.state.selectedFilters[category]}
                                            onChange={(e,data)=>this.onFiltersSelection(e,data,category)}
                                            selection
                                            search
                                            multiple
                                            placeholder={"Params"} />
                                        <br/>
                                        <br/>
                                        {this.state.selectedFilters[category].map(name=>{
                                            if (name === "age") {
                                                return (
                                                    <div style={{padding:"10px"}}>
                                                        <Label>{name}</Label>
                                                        <br/>
                                                        <Dropdown
                                                            options={age_word_from.slice().reverse()}
                                                            onChange={(e, data) => this.onAgeFromChange(e, data, category)}
                                                            selection
                                                            placeholder={"Age from"}/>
                                                        <br/>
                                                        <Dropdown
                                                            options={age_word_to}
                                                            onChange={(e, data) => this.onAgeToChange(e, data, category)}
                                                            selection
                                                            placeholder={"Age to"}/>
                                                        <br/>
                                                    </div>
                                                )
                                            }if (this.props.names.includes(name)){
                                                console.log(this.props.dicts[name])
                                                let dict = new Set(this.props.dicts[name])
                                                console.log(dict)
                                                dict = Array.from(dict)
                                                return(
                                                    <div style={{padding:"10px"}}>
                                                        <Label>{name}</Label>
                                                        <br/>
                                                        <Dropdown
                                                            options={dict.map(data => ({key: data, text:data, value: data}))}
                                                            onChange={(e,data)=>{this.onDictChange(name,data.value,category)}}
                                                            selection
                                                            multiple
                                                            placeholder={"Filters"} />
                                                        <br/>
                                                    </div>
                                                )
                                            }
                                            else
                                                return(
                                                    <div style={{padding:"10px"}}>
                                                        <Label>{name}</Label>
                                                        <br/>
                                                        {"from"}<Input onChange={(e,data)=>{
                                                        this.onFromChange(name,data.value,category)
                                                    }}/>{"to"}<Input onChange={(e,data)=>{
                                                        this.onToChange(name,data.value,category)
                                                    }}/>
                                                        <br/>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                )
                            })}
                            <br/>
                            {console.log(this.state.selectedFilters)}


                            <span><Button style={{transform: "scale(0.815)"}} basic size={'medium'} onClick={(e,val) => {
                                console.log(this.state.Filters)
                                this.setState({ids:null,mapReservesDisplay:"none",mapReservesIds:null});
                                let result_string_array = '';
                                let Filters = [];
                                for ( let i = 0 ; i < this.props.categories.length ; i++){
                                    console.log(Filters,this.state.Filters[this.props.categories[i]],this.state.Filters,this.props.categories[i])
                                    Filters = [...Filters,...this.state.Filters[this.props.categories[i]]]
                                }
                                console.log(Filters)
                                for (let i = 0 ; i < Filters.length;i++){
                                    /*if (this.props.names.includes(this.state.Filters[i].field) ){
                                        let json_array = '{'
                                        for ( let j = 0 ; j < this.state.Filters[i].value.length;j++){
                                            json_array = json_array + '"'+ j + '":"' + this.state.Filters[i].value[j] +'",'
                                        }
                                        json_array.slice(0, -1)
                                        json_array = json_array + '}'
                                        result_string_array = result_string_array +' \'{"field":"' + this.state.Filters[i].field + '","value":'+ json_array + "}\'::json,"
                                    }
                                    else*/
                                        result_string_array = result_string_array +' \'' + JSON.stringify(Filters[i]) + "\'::json,"
                                }
                                result_string_array = result_string_array.slice(0,-1);
                                console.log(result_string_array)
                                axios.post(urlBackend+'/postQuery', {query:{type:'get_reserves_filter',select_type:'filter',select_params:{array:result_string_array}}})
                                    .then((res) => {
                                        console.log(res.data)
                                        let check_duplicate_in_array = (input_array) => {
                                            input_array = input_array.sort((a, b) => a - b);
                                            let duplicate_elements = []
                                            for (let index in input_array) {
                                                if (input_array[index] ===
                                                    input_array[index - 1]) {
                                                    duplicate_elements.push(
                                                        input_array[index]);
                                                }
                                            }
                                            return [...new Set(duplicate_elements)];
                                        }
                                        let id_array =[];
                                        if (!this.state.Mode)
                                        {
                                            console.log(res.data[0].process_array_for_fields_filter[0],res.data[0].process_array_for_fields_filter.length)
                                            id_array = res.data[0].process_array_for_fields_filter[0]
                                            if ( res.data[0].process_array_for_fields_filter.length > 1){
                                                let temp_array = [];
                                                for ( let i = 0 ; i < id_array.length; i++){
                                                    temp_array.push(id_array[i].id)
                                                }
                                                console.log(temp_array)
                                                for (let i = 1 ; i < res.data[0].process_array_for_fields_filter.length; i++) {
                                                    id_array = res.data[0].process_array_for_fields_filter[i]
                                                    let temp_array2 = []
                                                    for ( let i = 0 ; i < id_array.length; i++){
                                                        temp_array2.push(id_array[i].id)
                                                    }
                                                    console.log(temp_array2)
                                                    temp_array = [...temp_array, ...temp_array2]
                                                    console.log(temp_array)
                                                    temp_array = check_duplicate_in_array(temp_array)
                                                    console.log(temp_array)
                                                }
                                                id_array = temp_array;
                                                console.log(id_array)
                                            } else if ( res.data[0].process_array_for_fields_filter.length === 0)
                                            {
                                                console.log(res.data[0].process_array_for_fields_filter)
                                            }else{
                                                let temp_array = [];
                                                for ( let i = 0 ; i < id_array.length; i++){
                                                    temp_array.push(id_array[i].id)
                                                }
                                                id_array = temp_array;
                                            }

                                        }else{
                                            for (let i = 0 ; i < res.data[0].process_array_for_fields_filter.length; i++) {
                                                for (let j = 0 ; j < res.data[0].process_array_for_fields_filter[i].length; j++)
                                                    id_array.push(res.data[0].process_array_for_fields_filter[i][j].id);
                                            }
                                        }

                                        let set = new Set(id_array)
                                        console.log(id_array)
                                        this.props.filterhandler(Array.from(set))
                                        this.setState({ids:Array.from(set),mapReservesDisplay:"block",mapReservesIds:Array.from(set)});
                                    })
                                    .catch((err) => {console.log(err);})


                            }}>{"Filter"}</Button> </span>
                            <br/>
                        </div>
                        <div style={{width:"50%",float: "right"}}>
                            







                        </div>
                    </div>
                </div>
                <br/>

                {(this.state.mapReservesDisplay==="block")&&(<MapReserves update={()=>{this.setState({inc:this.state.inc+1})}} ids={this.state.mapReservesIds}  display= {this.state.mapReservesDisplay} />)}

            </div>
        )}}
export default ReservesFilter;
