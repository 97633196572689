import React from "react";
import i18n from "../../i18n";
import {
    DndContext,
    useSensors,
    useSensor,
    PointerSensor,
    KeyboardSensor,
    closestCenter,
} from "@dnd-kit/core";
import {
    arrayMove as dndArrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {arrayMoveImmutable } from "array-move";
import Gradient from "javascript-color-gradient";
import {BrowserView, isMobile, MobileView} from "react-device-detect";
import Draggable from "react-draggable";
import {Button, Checkbox, Icon, Label} from "semantic-ui-react";
import LayerColor from "./LayerColor";
import LayerBorderColor from "./LayerBorderColor";
import LayerButtonTransparent from "./LayerButtonTransparent";
import LayerOpacity from "./LayerOpacity";
import LayerButtonText from "./LayerButtonText";
import LayerTextSize from "./LayerTextSize";
import LayerButtonHover from "./LayerButtonHover";
import LayerButtonPopup from "./LayerButtonPopup";
import LayerDelete from "./LayerDelete";
import LayerGeoJsonToDB from "./LayerGeoJsonToDB";
import FilterRangeSlider from "../FilterRangeSlider";
import LayerGeoTiffToDB from "./LayerGeoTiffToDB";
import Cookies from "js-cookie";
import LayerButtonFilter from "./LayerButtonFilter";
import LayerButtonDisplayToggle from "./LayerButtonDisplayToggle";
import LayerButtonOptions from "./LayerButtonOptions";
import FitLayer from "../FitLayer";
import LayerFeaturesTable from "./LayerFeaturesTable";
import LayerFilters from "./LayerFilters";
import AddHeatMapMenu from "../AddHeatMapMenu";
import {age_period, age_word, change_only_my} from "../../VariableInitialize"
import ReservesFilter from "../ReservesFilter";
import {withTranslation} from "react-i18next";
import {SortableList} from "../SortableList";

const ReservesFilterTranslate = withTranslation()(ReservesFilter)
var dataname = [{index:0,name:"Basins"},{index:1,name:"GeologicMap"},{index:2,name:"TectonicMap"},{index:3,name:"SubBasins"},{index:4,name:"Regions"},{index:5,name:"Licenses"},{index:6,name:"Fields"},{index:7,name:"GeologicObjects"},{index:8,name:"Wells"},{index:9,name:"ProtectedAreas"},{index:10,name:"Seismics"},{index:11,name:"MSU_Branches"},{index:13,name:"co2_world_sources"},{index:14,name:"infra_obj"}];
const explordataname = [{index:14,name:"Wells"},{inded:16,name:"Seismics"},{index:0,name:"Fields"},{index:5,name:"Licenses"},{index:3,name:"SubBasins"},{index:1,name:"Basins"}];
const geodataname = [{index:1,name:"GeologicObjects"},{index:4,name:"TectonicMap"},{index:8,name:"GeologicMap"}];
const infdataname = [{index:9,name:"Info"}];
const regdataname = [{index:4,name:"Regions"},{index:17,name:"MSU_Branches"}];
const ecodataname = [{index:15,name:"ProtectedAreas"},{index:19,name:"co2_world_sources"},{index:20,name:"infra_obj"}];
var userdataname = [];
const layoutdataname = [{index:10,name:"GoogleSat"},{index:11,name:"Blackmarble"},{index:12,name:"BingAerial"},{index:13,name:"ArcGISTopo"}];

var LayerOptions=[{name:"Basins",data:'none',data2:"none",id:0},{name:"GeologicMap",data:'none',data2:"none",id:1},{name:"TectonicMap",data:'none',data2:"none",id:2},{name:"SubBasins",data:'none',data2:"none",id:3},{name:"Regions",data:'none',data2:"none",id:4},{name:"Licenses",data:'none',data2:"none",id:5},{name:"Fields",data:'none',data2:"none",id:6},{name:"GeologicObjects",data:'none',data2:"none",id:7},{name:"Wells",data:'none',data2:"none",id:8},{name:"ProtectedAreas",data:'none',data2:"none",id:9},{name:"Seismics",data:"none",data2:"none",id:10},{name:"MSU_Branches",data:"none",data2:"none",id:11},{name:"co2_world_sources",data:"none",data2:"none",id:13},{name:"infra_obj",id:14}];
var UserOptions=[];
var ExploreOptions=[{name:"Wells",data:'none',data2:"none",id:13},{name:"Seismics",data:"none",data2:"none",id:15},{name:"Fields",data:'none',data2:"none",id:0},{name:"Licenses",data:'none',data2:"none",id:5},{name:"SubBasins",data:'none',data2:"none",id:3},{name:"Basins",data:'none',data2:"none",id:1}];
var GeoOptions=[{name:"GeologicObjects",data:'none',data2:"none",id:1},{name:"TectonicMap",data:'none',data2:"none",id:4},{name:"GeologicMap",data:'none',data2:"none",id:8}];
var InfraOptions=[{name:"Info",data:'none',data2:"none",id:9},{name:"Google",data:'none',data2:"none",id:10}];
var AdminOptions=[{name:"Regions",data:'none',data2:"none",id:4},{name:"MSU_Branches",id:17}];
var EcoOptions=[{name:"ProtectedAreas",data:"none",data2:"none",id:14},{name:"co2_world_sources",id:19},{name:"infra_obj",index:20}];
var LayoutOptions=[{name:"GoogleSat",data:'none',id:10},{name:"Blackmarble",data:'none',id:11},{name:"BingAerial",data:'none',id:12},{name:"ArcGISTopo",data:'none',id:13}];

/*
const DragHandle = sortableHandle(() => <span><Icon name='arrows alternate icon' size='large'/></span>);

const SortableItem = sortableElement(({key, index, value, layers, setOptionsName, layers2, handler, handler2, layerchange, onFeaturestable, fitlayer, layerchange2, layerorder,
                                          result, data, data2, presetdata, colorGradient1, colorGradient2, TextData, LegendLayer, FilterLayerData, Filters, minGradient,
                                          maxGradient, histogramValue, maxWinValue , histogramValue2, maxAuctionValue, onMapReserves}) =>
    <div className="item" border="solid 1px black">
    <li style={{width: "auto",paddingTop: "4px"}}>
        <LayerButtonFilter setName={setOptionsName} handler={handler2} title={value}/>
        <LayerButtonOptions  setName={setOptionsName} handler={handler} title={value} />
        <DragHandle />
        <LayerButtonDisplayToggle result={result} data={data} data2={data2} presetdata={presetdata} title={value} handle={layerchange} dataset={1} custom={true} layerorder={layerorder} />
        <div id={index}  style={{display:layers,border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
            <div style={{display:'inline-block'}}>
                <Label basic>{i18n.t('Fill color:')}</Label>
                <span>&nbsp;&nbsp;</span>
                <LayerColor result={result} colorGradient1={colorGradient1} colorGradient2={colorGradient2}  TextData={TextData} LegendLayer={LegendLayer}  value={value} change={layerchange} />
                <span>&nbsp;&nbsp;</span>
                <Label basic>{i18n.t('Border color: ')}</Label><span>&nbsp;&nbsp;</span><LayerBorderColor result={result}  value={value} handle={layerchange}/>
            </div>
            <br/><LayerButtonTransparent result={result}  title={value} handle={layerchange} />
            <LayerOpacity result={result} value = {value} handle={layerchange}/>
            <LayerButtonText data2={data2} TextData={TextData}  title={value} handle={layerchange} />
            <br/> <LayerTextSize data2={data2}  value = {value} handle={layerchange}/>
            <br/><LayerButtonHover result={result}  title={value} handle={layerchange} />
            <br/><LayerButtonPopup result={result}  title={value} handle={layerchange} />
        </div>
        {(layers2==="block")&&(
            <div id={index}  style={{display:layers2,paddingLeft: "25px"}}>
                <div style={{display:"flex"}}><FitLayer fitlayer={fitlayer} value = {value} />
                    <LayerFeaturesTable result={result}  onFeaturestable={onFeaturestable} value={value} /> </div>
                <label><Label basic>Layer Filters:</Label></label>
                <LayerFilters onMapReserves={onMapReserves} FilterLayerData={FilterLayerData} Filters={Filters} minGradient={minGradient} maxGradient={maxGradient} histogramValue={histogramValue} maxWinValue={maxWinValue} histogramValue2={histogramValue2} maxAuctionValue={maxAuctionValue} age_period={age_period} age_word={age_word} value = {value} handle={layerchange}/>
            </div>)}
    </li></div>);
const SortableItem2 = sortableElement(({key, index, value ,layers, setOptionsName, handler, layerchange, delete2, loader2,
                                           result, data, data2, presetdata, LayerOptions,dataname,UserOptions,userdataname}) =>
    <div className="item" border="solid 1px black">
        <li style={{width: "auto",paddingTop: "4px",height:"28.29px"}}>
            <LayerButtonOptions  setName={setOptionsName} handler={handler} title={value} />
            <DragHandle />
            <LayerButtonDisplayToggle result={result} data={data} data2={data2} presetdata={presetdata} title={value} handle={layerchange} />
            <div id={index}  style={{display:layers,border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                <LayerOpacity result={result} value = {value} handle={layerchange}/>
                <LayerDelete result={result} LayerOptions={LayerOptions} data={data} presetdata={presetdata} data2={data} dataname={this.props.dataname} UserOptions={UserOptions} userdataname={userdataname} value = {value} handle={layerchange} delete={delete2}/>
                <LayerGeoTiffToDB result={result}  value={value} loader={loader2} />
            </div>
        </li>
    </div>);
const SortableItem3 = sortableElement(({key, index, value, layers, setOptionsName, handler, layerchange, loader, delete2,
                                       result, data, data2, presetdata, colorGradient1,colorGradient2,TextData,
                                           LegendLayer,LayerOptions,dataname,UserOptions,userdataname}) =>
    <div className="item" border="solid 1px black">
        <li style={{width: "auto",paddingTop: "4px",height:"28.29px"}}>
            <LayerButtonOptions  setName={setOptionsName} handler={handler} title={value} />
            <DragHandle />
            <LayerButtonDisplayToggle result={result} data={data} data2={data2} presetdata={presetdata} title={value} handle={layerchange} />
            <div id={index}  style={{display:layers,border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                <div style={{display:'inline-block'}}>
                    {i18n.t('Fill color:')}
                    <span>&nbsp;&nbsp;</span>
                    <LayerColor result={result} colorGradient1={colorGradient1} colorGradient2={colorGradient2}  TextData={TextData} LegendLayer={LegendLayer}  value={value} change={layerchange} />
                </div>
                <br/>
                <LayerOpacity result={result} value = {value} handle={layerchange}/>
                <LayerDelete result={result} LayerOptions={LayerOptions} data={data} presetdata={presetdata} data2={data} dataname={this.props.dataname} UserOptions={UserOptions} userdataname={userdataname} value = {value} handle={layerchange} delete={delete2}/>
                <LayerGeoJsonToDB result={result}  value={value} loader={loader} />
            </div>
        </li>
    </div>);
const SortableContainer = sortableContainer(({value,children}) => {
    return <ul style={{marginLeft: '-40px',width: "auto"}}>{children}</ul>;
});

 */
function SortableItemNew({key, index, value, layers, setOptionsName, layers2, handler, handler2, layerchange, onFeaturestable, fitlayer, layerchange2, layerorder,
                             result, data, data2, presetdata, colorGradient1, colorGradient2, TextData, LegendLayer, FilterLayerData, Filters, minGradient,
                             maxGradient, histogramValue, maxWinValue , histogramValue2, maxAuctionValue, onMapReserves}) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition} = useSortable({id:index})
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;
    return (
        <div ref={setNodeRef} style={style}  className="item" border="solid 1px black">

            <button {...listeners} {...attributes} style={{width:"100px"}} ><Icon name='arrows alternate icon' size='large'/> {index}</button>

        </div>
    );
}
function SortableItemNew1({key, index, value, layers, setOptionsName, layers2, handler, handler2, layerchange, onFeaturestable, fitlayer, layerchange2, layerorder,
                             result, data, data2, presetdata, colorGradient1, colorGradient2, TextData, LegendLayer, FilterLayerData, Filters, minGradient,
                             maxGradient, histogramValue, maxWinValue , histogramValue2, maxAuctionValue, onMapReserves,setinteraction}) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition} = useSortable({id:index})
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;
    return (
        <div ref={setNodeRef} style={style}  className="item" border="solid 1px black">
                <LayerButtonFilter setName={setOptionsName} handler={handler2} title={value}/>
                <LayerButtonOptions  setName={setOptionsName} handler={handler} title={value} />
                <Icon {...listeners} {...attributes} name='arrows alternate icon' size='large'/>
                <LayerButtonDisplayToggle result={result} data={data} data2={data2} presetdata={presetdata} title={value} handle={layerchange} dataset={1} custom={true} layerorder={layerorder} />
                <div id={index}  style={{display:layers,border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                    <div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}>
                        <Label basic>{i18n.t('Fill color:')}</Label>
                        <span>&nbsp;&nbsp;</span>
                        <LayerColor result={result} colorGradient1={colorGradient1} colorGradient2={colorGradient2}  TextData={TextData} LegendLayer={LegendLayer}  value={value} change={layerchange} />
                        <span>&nbsp;&nbsp;</span>
                        <Label basic>{i18n.t('Border color: ')}</Label><span>&nbsp;&nbsp;</span><LayerBorderColor result={result}  value={value} handle={layerchange}/>
                    </div>
                    <br/><LayerButtonTransparent result={result}  title={value} handle={layerchange} />
                    <LayerOpacity result={result} value = {value} handle={layerchange}/>
                    <LayerButtonText data2={data2} TextData={TextData}  title={value} handle={layerchange} />
                    <br/> <LayerTextSize data2={data2}  value = {value} handle={layerchange}/>
                    <br/><LayerButtonHover result={result}  title={value} handle={layerchange} />
                    <br/><LayerButtonPopup result={result}  title={value} handle={layerchange} />
                </div>
                {(layers2==="block")&&(
                    <div id={index}  style={{display:layers2,paddingLeft: "25px"}}>
                        <div style={{display:"flex"}}><FitLayer fitlayer={fitlayer} value = {value} />
                            <LayerFeaturesTable result={result}  onFeaturestable={onFeaturestable} value={value} /> </div>
                        <label><Label basic>Layer Filters:</Label></label>
                        <LayerFilters setinteraction={setinteraction} onMapReserves={onMapReserves} FilterLayerData={FilterLayerData} Filters={Filters} minGradient={minGradient} maxGradient={maxGradient} histogramValue={histogramValue} maxWinValue={maxWinValue} histogramValue2={histogramValue2} maxAuctionValue={maxAuctionValue} age_period={age_period} age_word={age_word} value = {value} handle={layerchange}/>
                    </div>)}
            </div>
    );
}
function SortableItemNew3({key, index, value, layers, setOptionsName, handler, layerchange, loader, delete2,
                              result, data, data2, presetdata, colorGradient1,colorGradient2,TextData,
                              LegendLayer,LayerOptions,dataname,UserOptions,userdataname}) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition} = useSortable({id:index})
    const style = {
        transform:CSS.Transform.toString(transform),
        transition
    }
    return (
        <div ref={setNodeRef} style={style}  className="item" border="solid 1px black">
                <LayerButtonOptions  setName={setOptionsName} handler={handler} title={value} />
                <button {...listeners} {...attributes} ><Icon name='arrows alternate icon' size='large'/></button>
                <LayerButtonDisplayToggle result={result} data={data} data2={data2} presetdata={presetdata} title={value} handle={layerchange} />
                <div id={index}  style={{display:layers,border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                    <div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}>
                        {i18n.t('Fill color:')}
                        <span>&nbsp;&nbsp;</span>
                        <LayerColor result={result} colorGradient1={colorGradient1} colorGradient2={colorGradient2}  TextData={TextData} LegendLayer={LegendLayer}  value={value} change={layerchange} />
                    </div>
                    <br/>
                    <LayerOpacity result={result} value = {value} handle={layerchange}/>
                    <LayerDelete result={result} LayerOptions={LayerOptions} data={data} presetdata={presetdata} data2={data} dataname={dataname} UserOptions={UserOptions} userdataname={userdataname} value = {value} handle={layerchange} delete={delete2}/>
                    <LayerGeoJsonToDB result={result}  value={value} loader={loader} />
                </div>
        </div>
    );
}
function SortableItemNew2({key, index, value ,layers, setOptionsName, handler, layerchange, delete2, loader2,
                              result, data, data2, presetdata, LayerOptions,dataname,UserOptions,userdataname}) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition} = useSortable({id:index})
    const style = {
        transform:CSS.Transform.toString(transform),
        transition
    }
    return (
        <div ref={setNodeRef} style={style}  className="item" border="solid 1px black">
                <LayerButtonOptions  setName={setOptionsName} handler={handler} title={value} />
            <button {...listeners} {...attributes} ><Icon name='arrows alternate icon' size='large'/></button>
                <LayerButtonDisplayToggle result={result} data={data} data2={data2} presetdata={presetdata} title={value} handle={layerchange} />
                <div id={index}  style={{display:layers,border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                    <LayerOpacity result={result} value = {value} handle={layerchange}/>
                    <LayerDelete result={result} LayerOptions={LayerOptions} data={data} presetdata={presetdata} data2={data} dataname={dataname} UserOptions={UserOptions} userdataname={userdataname} value = {value} handle={layerchange} delete={delete2}/>
                    <LayerGeoTiffToDB result={result}  value={value} loader={loader2} />
                </div>
        </div>
    );
}

class LayerMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOptionName:"",
            items: this.props.dataname,
            itemsfilter:this.props.dataname,
            renderitems: this.props.data,
            LayerOptions: this.props.LayerOptions,
            ExploreOptions:ExploreOptions,
            GeoOptions:GeoOptions,
            InfraOptions:InfraOptions,
            EcoOptions:EcoOptions,
            UserOptions:this.props.UserOptions,
            AdminOptions:AdminOptions,
            LayoutOptions:LayoutOptions,
            layerSwitch2:'block',
            layerSwitch:'none',
            active: false,
            only_my_active:false,
            popup_active:true,
            image_active:false,
            text:i18n.t("Change to Preset"),
            trigger:false,
            onlyMy:false,
            infoDisplay:'none',
            legendDisplay:'none',
            addheatmapDisplay:'none',
            heatmapcoord:{x:null,y:null},
            currentSettings:null,
            currentType:null,
            x: 0, y: 0,
            legend:null,
            legend2:false,
            interation:this.props.interation,
            data:null,
            categories:null,
            dict:null,
            dict_names:null,
            reserves_display:"none",
        };
        this.onDelete = this.onDelete.bind(this);
    }
    static getDerivedStateFromProps(props, current_state) {
        //console.log(props,current_state);
        if (current_state.interation !== props.interation) {
            //console.log(current_state.items,current_state.renderitems)
            return {
                interation: props.interation,
            }
        }
        return null
    }
    onDelete=() => {
        this.props.layerchange2();
        setTimeout(() => {
                this.setState({items:dataname,itemsfilter:dataname,
                    renderitems: this.props.data,
                    LayerOptions: this.props.LayerOptions,
                    ExploreOptions:ExploreOptions,
                    GeoOptions:GeoOptions,
                    InfraOptions:InfraOptions,
                    AdminOptions:AdminOptions,
                    EcoOptions:EcoOptions,
                    UserOptions:this.props.UserOptions,
                    LayoutOptions:LayoutOptions,
                    currentSettings:null,
                    currentType:null})
            },
            1000);
    };
    onSortEnd = (event) => {
        let oldIndex= event.active.id
        let newIndex= event.over.id
        console.log(event,oldIndex,newIndex)
        //this.setState({items:dataname,itemsfilter:dataname, renderitems: this.props.data, LayerOptions: this.props.LayerOptions})
        if (this.state.trigger === true) {
            this.props.result.reverse();
        }

        console.log(oldIndex, newIndex,this.state.items,this.state.renderitems,this.state.LayerOptions);
        console.log("Sort Layers from to",this.state.items[oldIndex],this.state.items[newIndex])
        let temp1 = this.state.items[oldIndex].name;
        let temp2 =this.state.items[newIndex].name;
        let index1;
        let index2;
        for (let i = 0;i < this.state.renderitems.length;i++) {
            //console.log(this.state.renderitems[i].name,temp1,temp2);
            if (this.state.renderitems[i].name === temp1) {
                index1 = i;
            }
            if (this.state.renderitems[i].name === temp2){
                index2 = i;
            }
        }
        console.log(index1,index2,temp1,temp2,this.state.renderitems);
        console.log(this.state.items, oldIndex, newIndex)
        console.log(arrayMoveImmutable(this.state.items, oldIndex, newIndex))
        let temp = arrayMoveImmutable(this.state.items, oldIndex, newIndex);

        console.log(this.state.LayerOptions, oldIndex, newIndex)
        console.log(arrayMoveImmutable(this.state.LayerOptions, oldIndex, newIndex))

        this.setState(({items,renderitems,LayerOptions}) => ({
            items: arrayMoveImmutable(items, oldIndex, newIndex),
            renderitems:arrayMoveImmutable(renderitems, index1, index2),
            LayerOptions:arrayMoveImmutable(LayerOptions, oldIndex, newIndex),
        }));
        console.log(this.state.renderitems);
        /*
        let array= this.state.items.slice();
        for (let i = 0 ; i < this.state.items.length ; i++) {
            array[i].index= i;
        }



        this.setState({items:array});

         */
        this.props.setLayerOptions(this.state.LayerOptions.slice());
        this.props.setDataname(this.state.items.slice());
        this.props.setResult(this.state.renderitems.slice());

        if (this.state.trigger === false) {
            this.setState({trigger:true})
        }
        this.props.onlayermove();
    };
    setOptionsName = (optionName) =>{
        this.setState({currentOptionName:optionName})
    }
    handler2 = (currentOptionName) => {
        for (let i = 0; i < this.state.LayerOptions.length; i++) {
            if (this.state.LayerOptions[i].name === currentOptionName) {
                if (this.state.LayerOptions[i].data2 === 'none') {
                    this.state.LayerOptions[i].data2 = 'block';
                    this.setState({active: true})
                } else {
                    this.state.LayerOptions[i].data2 = 'none';
                    this.setState({active: false})
                }
            }
        }
        for (let i = 0 ; i < this.state.GeoOptions.length;i++){
            if (this.state.GeoOptions[i].name === currentOptionName) {
                if (this.state.GeoOptions[i].data2 === 'none') {
                    this.state.GeoOptions[i].data2 = 'block';
                    this.setState({active:true})}
                else{
                    this.state.GeoOptions[i].data2 = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.AdminOptions.length;i++){
            if (this.state.AdminOptions[i].name === currentOptionName) {
                if (this.state.AdminOptions[i].data2 === 'none') {
                    this.state.AdminOptions[i].data2 = 'block';
                    this.setState({active:true})}
                else{
                    this.state.AdminOptions[i].data2 = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.InfraOptions.length;i++){
            if (this.state.InfraOptions[i].name === currentOptionName) {
                if (this.state.InfraOptions[i].data2 === 'none') {
                    this.state.InfraOptions[i].data2 = 'block';
                    this.setState({active:true})}
                else{
                    this.state.InfraOptions[i].data2 = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.EcoOptions.length;i++){
            if (this.state.EcoOptions[i].name === currentOptionName) {
                if (this.state.EcoOptions[i].data2 === 'none') {
                    this.state.EcoOptions[i].data2 = 'block';
                    this.setState({active:true})}
                else{
                    this.state.EcoOptions[i].data2 = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.UserOptions.length;i++){
            if (this.state.UserOptions[i].name === currentOptionName) {
                if (this.state.UserOptions[i].data2 === 'none') {
                    this.state.UserOptions[i].data2 = 'block';
                    this.setState({active:true})}
                else{
                    this.state.UserOptions[i].data2 = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.ExploreOptions.length;i++){
            if (this.state.ExploreOptions[i].name === currentOptionName) {
                if (this.state.ExploreOptions[i].data2 === 'none') {
                    this.state.ExploreOptions[i].data2 = 'block';
                    this.setState({active:true})}
                else{
                    this.state.ExploreOptions[i].data2 = 'none';
                    this.setState({active:false})
                }
            }
        }

    };
    handleSelectGradient = (value) => {
        function getIntervalls(min,max, nInt) {
            const c = Math.floor((max-min) / nInt);
            const r = [];
            for (var i = min; i <= max; i += c) {
                const a = i == 0 ? i : i += 1;
                const b = i + c > max ? max : i + c;
                if (a < max) r.push([a, b])
            }
            return r;
        }

        for (let j = 0; j < this.props.result.length; j++) {
            if (this.props.result[j].name === "Licenses") {
                const colorGradient = new Gradient();
                let interval;
                interval = getIntervalls(this.props.minGradient,this.props.maxGradient,10)
                let color1 = this.props.colorGradient1;
                let color2 = this.props.colorGradient2;
                if ( this.props.colorGradient1 === undefined)
                {
                    let color1 = this.props.result[j].color[4];
                    let color2 = this.props.result[j].color[22];
                }
                let getValue =this.props.result[j].color[2];
                console.log(color1,color2)
                colorGradient.setMidpoint(10);
                colorGradient.setGradient(color1, color2);
                let colorArray = colorGradient.getArray();
                console.log(colorArray);
                let mapboxGradientColor = [
                    'interpolate',
                    ['linear'],
                    getValue ,
                    interval[0][0],
                    colorArray[0],
                    interval[1][0],
                    colorArray[1],
                    interval[2][0],
                    colorArray[2],
                    interval[3][0],
                    colorArray[3],
                    interval[4][0],
                    colorArray[4],
                    interval[5][0],
                    colorArray[5],
                    interval[6][0],
                    colorArray[6],
                    interval[7][0],
                    colorArray[7],
                    interval[8][0],
                    colorArray[8],
                    interval[9][0],
                    colorArray[9]
                ]
                this.props.result[j].color =mapboxGradientColor;
                this.props.layerchange();
            }
        }

    };
    handler = (currentOptionName) => {
        console.log(this.state.currentOptionName,this.state.currentSettings,this.state.currentType);
        if ( this.state.currentSettings === null || this.state.currentSettings !== currentOptionName) {
            let type = null;
            for ( let i = 0 ; i < this.props.result.length;i++){
                if (this.props.result[i].name === currentOptionName){
                    type = this.props.result[i].type
                }
            }
            this.setState({currentSettings:currentOptionName,currentType:type});
        }

        else if ( this.state.currentSettings === currentOptionName){
            this.setState({currentSettings:null,currentType:null});
        }

        for (let i = 0 ; i < this.state.LayerOptions.length;i++){
            if (this.state.LayerOptions[i].name === currentOptionName) {
                if (this.state.LayerOptions[i].data === 'none') {
                    this.state.LayerOptions[i].data = 'block';
                    this.setState({active:true})}
                else{
                    this.state.LayerOptions[i].data = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.GeoOptions.length;i++){
            if (this.state.GeoOptions[i].name === currentOptionName) {
                if (this.state.GeoOptions[i].data === 'none') {
                    this.state.GeoOptions[i].data = 'block';
                    this.setState({active:true})}
                else{
                    this.state.GeoOptions[i].data = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.AdminOptions.length;i++){
            if (this.state.AdminOptions[i].name === currentOptionName) {
                if (this.state.AdminOptions[i].data === 'none') {
                    this.state.AdminOptions[i].data = 'block';
                    this.setState({active:true})}
                else{
                    this.state.AdminOptions[i].data = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.InfraOptions.length;i++){
            if (this.state.InfraOptions[i].name === currentOptionName) {
                if (this.state.InfraOptions[i].data === 'none') {
                    this.state.InfraOptions[i].data = 'block';
                    this.setState({active:true})}
                else{
                    this.state.InfraOptions[i].data = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.EcoOptions.length;i++){
            if (this.state.EcoOptions[i].name === currentOptionName) {
                if (this.state.EcoOptions[i].data === 'none') {
                    this.state.EcoOptions[i].data = 'block';
                    this.setState({active:true})}
                else{
                    this.state.EcoOptions[i].data = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.UserOptions.length;i++){
            if (this.state.UserOptions[i].name === currentOptionName) {
                if (this.state.UserOptions[i].data === 'none') {
                    this.state.UserOptions[i].data = 'block';
                    this.setState({active:true})}
                else{
                    this.state.UserOptions[i].data = 'none';
                    this.setState({active:false})
                }
            }
        }
        for (let i = 0 ; i < this.state.ExploreOptions.length;i++){
            if (this.state.ExploreOptions[i].name === currentOptionName) {
                if (this.state.ExploreOptions[i].data === 'none') {
                    this.state.ExploreOptions[i].data = 'block';
                    this.setState({active:true})}
                else{
                    this.state.ExploreOptions[i].data = 'none';
                    this.setState({active:false})
                }
            }
        }

    };
    handleClick = () => {
        if (this.state.layerSwitch === 'block') {
            for (let i = 0 ; i < this.props.data.length;i++){
                for (let j = 0 ; j< this.props.presetdata.length;j++) {
                    if(this.props.data[i].name === this.props.presetdata[j].name){
                        this.props.presetdata[j].color = this.props.data[i].color;
                        this.props.presetdata[j].visibility = this.props.data[i].visibility;
                        this.props.presetdata[j].opacity = this.props.data[i].opacity;
                        this.props.presetdata[j].text = this.props.data[i].text;
                        this.props.presetdata[j].border = this.props.data[i].border;
                        this.props.presetdata[j].hover = this.props.data[i].hover;
                        this.props.presetdata[j].transparent = this.props.data[i].transparent;
                    }
                }
            }
            this.setState({layerSwitch:'none',layerSwitch2:'block',text:i18n.t("Change to Custom")})
            setTimeout(() => {
                this.props.setResult(this.props.presetdata.slice())
                this.props.layerchange();
            }, 600);

        }
        else {
            for (let i = 0 ; i < this.props.presetdata.length;i++){
                for (let j = 0 ; j< this.props.data.length;j++) {
                    if(this.props.data[j].name === this.props.presetdata[i].name){
                        this.props.data[j].color = this.props.presetdata[i].color;
                        this.props.data[j].visibility = this.props.presetdata[i].visibility;
                        this.props.data[j].opacity = this.props.presetdata[i].opacity;
                        this.props.data[j].text = this.props.presetdata[i].text;
                        this.props.data[j].border = this.props.presetdata[i].border;
                        this.props.data[j].hover = this.props.presetdata[i].hover;
                        this.props.data[j].transparent = this.props.presetdata[i].transparent;
                    }
                }
            }
            this.setState({layerSwitch:'block',layerSwitch2:'none',text:i18n.t("Change to Preset")})
            setTimeout(() => {
                this.props.setResult(this.props.data.slice())
                this.props.onlayermove();
            }, 600);
        }
    };
    handleLayerDelete = (value) => {

        // console.log(result,dataname);
        for (let i = 0 ; i < this.props.result.length;i++){
            if (value === this.props.result[i].name){
                this.props.result[i].visibility="none";
            }
        }
        this.props.deleteLayerFromMap(value)
        //console.log(result,LayerOptions,data,presetdata,data2,dataname,this.state.id)
        this.onDelete();
    }
    handleLegendClick2 = () => {
        //console.log("onFocus")
        this.setState({legend2:!this.state.legend2});
    };
    handleLegendClick = () => {
        if ( this.state.legendDisplay === 'block')
            this.setState({legendDisplay:'none'})
        else
            this.setState({legendDisplay:'block'})
    };

    render() {


        // result colorGradient1 colorGradient2 TextData LegendLayer data2 presetdata data LayerOptions dataname UserOptions userdataname geodataname Filters FilterLayerData minGradient maxGradient
        //histogramValue2 histogramValue maxWinValue maxAuctionValue infdataname ecodataname regdataname userdataname layoutdataname
        let itemIds = []
        for (let i = 0 ; i < this.state.items.length;i++)
            itemIds.push(this.state.items[i].index)
        console.log(itemIds,this.state.items)
        let zindex = 100;
        let left = "0px";
        if (isMobile){
            zindex = 10;
            left = "-150px";
        }
        let optionsDisplay = 'none';
        if (this.state.currentSettings!==null)
            optionsDisplay = 'block'
        //this.state.items=dataname;
        //DnD списка слоев
        return (
            <div>
                <Draggable
                    axis="both"
                    handle=".draghandle"
                    defaultPosition={{x: 370, y: 0}}
                    //position={{x:"30%",y:"5%"}}
                    grid={[10, 10]}
                    scale={1}
                    onStart={(e) => {
                        //console.log(e,"onStart")
                    }}
                    onDrag={(e) => {
                        //console.log(e,"onDrag")
                    }}
                    onStop={(e) => {
                        //console.log(e,"onStop")
                    }}
                >
                    <div style={{display:optionsDisplay, position: "absolute",padding:"5px", width:"180px", backgroundColor:"white",zIndex:99999999999,left:left}}>
                        {console.log(this.state.currentOptionName)}
                        {(this.state.currentSettings!==null && (this.state.currentType==="fill" || this.state.currentType==="circle"||this.state.currentType==="line")) && (

                            <div>
                                <div border="solid 1px black"><li style={{
                                    width: "auto",
                                    listStyleType: "none"
                                }}>
                                    <Button basic size={'mini'} onClick={() => {
                                        this.setState({currentSettings: null});
                                    }} icon><i className="close icon"/></Button>
                                </li></div><div className="draghandle"><br/></div>
                                <div className="item" border="solid 1px black"><li style={{
                                    width: "auto",
                                    listStyleType: "none"
                                }}>
                                    {i18n.t(this.state.currentSettings)}
                                    <div id={this.state.currentSettings}  style={{
                                        display: "block",
                                        border: "solid 1px black",
                                        padding: '2px 2px',
                                        lineHeight: '1.6'
                                    }}>
                                        <div id={this.state.currentSettings} style={{display: 'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}>
                                            <Label style={{border: "none"}} basic>{i18n.t('Fill color:')} </Label>
                                            <span>&nbsp;&nbsp;</span>
                                            <LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={this.state.currentSettings}  change={this.props.layerchange} change2={this.handleLegendClick2} />
                                            <span>&nbsp;&nbsp;</span>
                                            <Label style={{border: "none"}} basic>{i18n.t('Border color: ')}</Label>
                                            <span>&nbsp;&nbsp;</span>
                                            <LayerBorderColor result={this.props.result}  value={this.state.currentSettings} handle={this.props.layerchange}/>
                                        </div>
                                        <br/>
                                        <LayerButtonTransparent result={this.props.result}  title={this.state.currentSettings} handle={this.props.layerchange} dataset={2}/>
                                        <br/>
                                        <LayerOpacity result={this.props.result} value = {this.state.currentSettings} handle={this.props.layerchange}/>
                                        <LayerButtonText data2={this.props.data2} TextData={this.props.TextData}  title={this.state.currentSettings} handle={this.props.layerchange} dataset={2}/>
                                        <br/>
                                        <LayerTextSize data2={this.props.data2}  value = {this.state.currentSettings} handle={this.props.layerchange}/>
                                        <br/>
                                        <LayerButtonHover result={this.props.result}  title={this.state.currentSettings} handle={this.props.layerchange} dataset={2}/>
                                        <br/>
                                        <LayerButtonPopup result={this.props.result}  title={this.state.currentSettings} handle={this.props.layerchange} />
                                    </div>
                                </li></div></div>

                        )}
                        {(this.state.currentSettings!==null && this.state.currentType==="geojson" ) && (
                            <div>
                                <Button basic size={'mini'} onClick={() => {
                                    this.setState({currentSettings: null,currentType:null});
                                }} icon><i className="close icon"/></Button>

                                <div className="item" border="solid 1px black"><li style={{
                                    width: "auto",
                                    listStyleType: "none"
                                }}>
                                    {this.state.currentSettings}
                                    <div id={this.state.currentSettings}  style={{display:"block",border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                                        <div style={{display:'inline-block',position: "relative",zIndex: 99, width: "-webkit-fill-available" }}>
                                            {i18n.t('Fill color:')}
                                            <span>&nbsp;&nbsp;</span>
                                            <LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={this.state.currentSettings} change={this.props.layerchange} />
                                        </div>
                                        <br/>
                                        <LayerOpacity result={this.props.result} value = {this.state.currentSettings} handle={this.props.layerchange}/>
                                        <LayerDelete result={this.props.result} LayerOptions={this.props.LayerOptions} data={this.props.data} presetdata={this.props.presetdata} data2={this.props.data} dataname={this.props.dataname} UserOptions={this.props.UserOptions} userdataname={this.props.userdataname} value = {this.state.currentSettings} handle={this.props.layerchange} delete={this.handleLayerDelete}/>
                                        <Button size={"mini"} circular basic icon={"zoom-in"} onClick={()=>this.props.fitGeoJsonLayer(this.state.currentSettings)}/>
                                        <LayerGeoJsonToDB result={this.props.result}  value={this.state.currentSettings} loader={this.props.loaderhandle} />
                                    </div>
                                </li>
                                </div>
                            </div>

                        )}
                        {(this.state.currentSettings!==null && this.state.currentType==="heatmap" ) && (
                            <div>
                                <Button basic size={'mini'} onClick={() => {
                                    this.setState({currentSettings: null,currentType:null});
                                }} icon><i className="close icon"/></Button>

                                <div className="item" style={{border:"solid 1px black"}}><li style={{
                                    width: "auto",
                                    listStyleType: "none"
                                }}>
                                    {this.state.currentSettings}
                                    <div id={this.state.currentSettings}  style={{display:"block",border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                                        <div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}>
                                            {i18n.t('Fill color:')}
                                            <span>&nbsp;&nbsp;</span>
                                            <LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={this.state.currentSettings} change={this.props.layerchange} />
                                        </div>
                                        <br/>
                                        <FilterRangeSlider histogram={null} min={0} max={20000000} handle={()=>{

                                        }} handle2={()=>{

                                        }} name ={this.state.currentOptionName}/>
                                        <LayerDelete result={this.props.result} LayerOptions={this.props.LayerOptions} data={this.props.data} presetdata={this.props.presetdata} data2={this.props.data} dataname={this.props.dataname} UserOptions={this.props.UserOptions} userdataname={this.props.userdataname} value = {this.state.currentSettings} handle={this.props.layerchange} delete={this.handleLayerDelete}/>
                                    </div>
                                </li>
                                </div>
                            </div>

                        )}
                        {(this.state.currentSettings!==null && this.state.currentType==="image" ) && (
                            <div>
                                <Button basic size={'mini'} onClick={() => {
                                    this.setState({currentSettings: null,currentType:null});
                                }} icon><i className="close icon"/></Button>

                                <div className="item" border="solid 1px black"><li style={{
                                    width: "auto",
                                    listStyleType: "none"
                                }}>
                                    {this.state.currentSettings}
                                    <div id={this.state.currentSettings}  style={{display:"block",border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                                        <div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}>
                                            {i18n.t('Fill color:')}
                                            <span>&nbsp;&nbsp;</span>
                                            <LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={this.state.currentSettings} change={this.props.layerchange} />
                                        </div>
                                        <br/>
                                        <LayerOpacity result={this.props.result} value = {this.state.currentSettings} handle={this.props.layerchange}/>
                                        <LayerDelete result={this.props.result} LayerOptions={this.props.LayerOptions} data={this.props.data} presetdata={this.props.presetdata} data2={this.props.data} dataname={this.props.dataname} UserOptions={this.props.UserOptions} userdataname={this.props.userdataname} value = {this.state.currentSettings} handle={this.props.layerchange} delete={this.handleLayerDelete}/>
                                        <LayerGeoTiffToDB result={this.props.result}  value={this.state.currentSettings} loader={this.props.loaderhandle2} />
                                    </div>
                                </li>
                                </div>
                            </div>

                        )}
                    </div>
                </Draggable>
                <div id={this.props.interation} style={{display:this.props.LayerDisplay }}>

                    <div className="control-panel" style={{display:this.props.LayerDisplay,zIndex:zindex }}>
                        <MobileView>
                            {(Cookies.get('username')!==undefined) && (
                                <li>
                                    <Label size={'small'}> {Cookies.get('username')}</Label>
                                    <Button
                                        onClick={() => { Cookies.remove("id");
                                            Cookies.remove("username");}} size={'small'}
                                    >
                                        {i18n.t("Logout")}
                                    </Button>
                                </li>
                            )}

                            {(Cookies.get('username')===undefined) && (
                                <li>
                                    <Button
                                        onClick={() => { this.props.layerchange3("Login");}} size={'small'}
                                    >
                                        {i18n.t("Login")}
                                    </Button>
                                    <Button
                                        onClick={() => { this.props.layerchange3("Register");}} size={'small'}
                                    >
                                        {i18n.t("Register")}
                                    </Button>
                                </li>
                            )}
                        </MobileView>
                        <Checkbox
                            slider
                            size={'mini'}
                            label={i18n.t("Only my objects")}
                            className={'checkbox'}
                            checked={this.state.onlyMy}
                            onChange={ () => {
                                this.setState({onlyMy:!this.state.onlyMy})
                                change_only_my(!this.state.onlyMy)
                                this.props.user_id_change();
                                this.props.layerchange2();
                            }}
                        />
                        <br/>
                        <Checkbox
                            slider
                            size={'mini'}
                            label={i18n.t("Disable/Enable Popups")}
                            className={'checkbox'}
                            checked={this.state.popup_active}
                            onChange={ () => {
                                //console.log(result)
                                for (let i =0; i < this.props.result.length;i++){
                                    //console.log(i,result[i],result[i].popup)
                                    if (this.props.result[i].popup !== undefined)
                                        //console.log("layer_state=",result[i].popup," class_state=",this.state.popup_active," new_state=",!this.state.popup_active)
                                        this.props.result[i].popup = !this.state.popup_active;
                                }

                                this.setState({popup_active:!this.state.popup_active})
                                //this.props.popupregister();
                                this.props.layerchange2();
                            }}
                        />
                        <br/>
                        <BrowserView>
                            <Button onClick={this.handleClick} size={'small'}>{this.state.text}</Button></BrowserView>
                        {(this.state.layerSwitch === "block")&&(
                            <div style = {{display:this.state.layerSwitch}}>
                                <DndContext
                                    collisionDetection={closestCenter}
                                    onDragEnd={this.onSortEnd}
                                >
                                    <div>
                                    <SortableContext items={itemIds} strategy={verticalListSortingStrategy} >
                                        {this.state.items.map(({index,name,type},index2) => {
                                            if (type !== undefined) {
                                                if ( type.includes("Image")) {
                                                    //console.log(index,name,type,index2)
                                                    return(
                                                        <SortableItemNew2 key={`item-${name}`} index={index} value={name} layers={"none"} setOptionsName={this.setOptionsName} handler={this.handler} layerchange={this.props.layerchange} delete2={this.handleLayerDelete} loader2={this.props.loaderhandle2}/>

                                                    )}
                                                else if ( type.includes("Geojson")) {
                                                    console.log(index,name,index2)
                                                    return(
                                                        <SortableItemNew3 key={`item-${name}`} index={index} value={name} layers={"none"} setOptionsName={this.setOptionsName} handler={this.handler} layerchange={this.props.layerchange} loader={this.props.loaderhandle} delete2={this.handleLayerDelete}/>

                                                    )}
                                            }else {
                                                console.log(this.props,this.state,index2,this.state.LayerOptions)
                                                return (
                                                    <SortableItemNew1
                                                        key={`item-${name}`}
                                                        index={index}
                                                        value={name}
                                                        layers={"none"}
                                                        setOptionsName={this.setOptionsName}
                                                        layers2={this.state.LayerOptions[index2].data2}
                                                        handler={this.handler}
                                                        handler2={this.handler2}
                                                        layerchange={this.props.layerchange}
                                                        onFeaturestable={this.props.onFeaturestable}
                                                        fitlayer={this.props.fitlayer}
                                                        layerchange2={this.props.layerchange2}
                                                        layerorder={this.props.onlayermove}
                                                        result={this.props.result}
                                                        data={this.props.data}
                                                        data2={this.props.data2}
                                                        presetdata={this.props.presetdata}
                                                        colorGradient1={this.props.colorGradient1}
                                                        colorGradient2={this.props.colorGradient2}
                                                        TextData={this.props.TextData}
                                                        LegendLayer={this.props.LegendLayer}
                                                        FilterLayerData={this.props.FilterLayerData}
                                                        Filters={this.props.Filters}
                                                        minGradient={this.props.minGradient}
                                                        maxGradient={this.props.maxGradient}
                                                        setMinGradient={this.props.setMinGradient}
                                                        setMaxGradient={this.props.setMaxGradient}
                                                        histogramValue={this.props.histogramValue}
                                                        maxWinValue={this.props.maxWinValue}
                                                        histogramValue2={this.props.histogramValue2}
                                                        maxAuctionValue={this.props.maxAuctionValue}
                                                        onMapReserves={this.props.onMapReserves}
                                                        setinteraction={this.props.setinteraction} />
                                                )
                                            }
                                        })}
                                    </SortableContext>
                                    </div>
                                </DndContext>
                            </div>
                        )}
                        {(this.state.layerSwitch2 === "block")&&(
                            <div style = {{display:this.state.layerSwitch2}}>
                                <Label>{i18n.t("Exploration and Production")}</Label>
                                <ul style={{marginLeft: '-40px',width: "auto"}}>
                                    {explordataname.map(({index,name},index2) => (
                                        <div className="item" border="solid 1px black">
                                            <li style={{width: "auto",paddingTop: "4px"}}>
                                                <LayerButtonFilter setName={this.setOptionsName} handler={this.handler2} title={name}/>
                                                <LayerButtonDisplayToggle result={this.props.result} data={this.props.data} data2={this.props.data2} presetdata={this.props.presetdata} title={name} handle={this.props.layerchange} dataset={2} />
                                                <LayerButtonOptions  setName={this.setOptionsName} handler={this.handler} title={name} />
                                                <div id={index}  style={{display:"none",border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                                                    <div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}>
                                                        <Label style={{border:"none"}} basic>{i18n.t('Fill color:')} </Label>
                                                        <span>&nbsp;&nbsp;</span>
                                                        <LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={name}  change={this.props.layerchange} change2={this.handleLegendClick2} />
                                                        <span>&nbsp;&nbsp;</span>
                                                        <Label style={{border:"none"}} basic>{i18n.t('Border color: ')}</Label>
                                                        <span>&nbsp;&nbsp;</span>
                                                        <LayerBorderColor result={this.props.result}  value={name} handle={this.props.layerchange}/>
                                                    </div>
                                                    <br/>
                                                    <LayerButtonTransparent result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/>
                                                    <br/>
                                                    <LayerOpacity result={this.props.result} value = {name} handle={this.props.layerchange}/>
                                                    <LayerButtonText data2={this.props.data2} TextData={this.props.TextData}  title={name} handle={this.props.layerchange} dataset={2}/>
                                                    <br/>
                                                    <LayerTextSize data2={this.props.data2}  value = {name} handle={this.props.layerchange}/>
                                                    <br/>
                                                    <LayerButtonHover result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/>
                                                    <br/>
                                                    <LayerButtonPopup result={this.props.result}  title={name} handle={this.props.layerchange} />
                                                </div>
                                            {(this.state.ExploreOptions[index2].data2==="block")&&(<div id={index}  style={{display:this.state.ExploreOptions[index2].data2,paddingLeft: "25px"}}>
                                                <div style={{display:"flex"}}><FitLayer fitlayer={this.props.fitlayer} value = {name} />
                                                    <LayerFeaturesTable result={this.props.result}  onFeaturestable={this.props.onFeaturestable} value = {name} /> </div>
                                                <Label>Layer Filters:</Label>
                                                <LayerFilters setinteraction={this.props.setinteraction} FilterReservesChange={this.handleReservesFilter} onMapReserves={this.props.onMapReserves} FilterLayerData={this.props.FilterLayerData} setMinGradient={this.props.setMinGradient}
                                                              setMaxGradient={this.props.setMaxGradient} Filters={this.props.Filters} minGradient={this.props.minGradient} maxGradient={this.props.maxGradient} histogramValue={this.props.histogramValue} maxWinValue={this.props.maxWinValue} histogramValue2={this.props.histogramValue2} maxAuctionValue={this.props.maxAuctionValue} age_period={age_period} age_word={age_word} value = {name} handle={this.props.layerchange} colorChange={this.handleSelectGradient}/>
                                            </div>)}
                                        </li></div>
                                    ))}
                                </ul>
                                <Label>{i18n.t("Geologic Structures")}</Label>
                                <ul style={{marginLeft: '-40px',width: "auto"}}>
                                    {geodataname.map(({index,name},index2) => (
                                        <div className="item" border="solid 1px black"><li style={{width: "auto",paddingTop: "4px"}}><LayerButtonFilter setName={this.setOptionsName} handler={this.handler2} title={name}/><LayerButtonOptions  setName={this.setOptionsName} handler={this.handler} title={name} /><LayerButtonDisplayToggle result={this.props.result} data={this.props.data} data2={this.props.data2} presetdata={this.props.presetdata} title={name} handle={this.props.layerchange} dataset={2}/><div id={index}  style={{display:"none",border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}><div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}><Label style={{border:"none"}} basic>{i18n.t('Fill color:')} </Label><span>&nbsp;&nbsp;</span><LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={name} change={this.props.layerchange}/><span>&nbsp;&nbsp;</span><Label style={{border:"none"}} basic>{i18n.t('Border color: ')}</Label><span>&nbsp;&nbsp;</span><LayerBorderColor result={this.props.result}  value={name} handle={this.props.layerchange}/></div><br/><LayerButtonTransparent result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/><br/> <LayerOpacity result={this.props.result} value = {name} handle={this.props.layerchange}/><LayerButtonText data2={this.props.data2} TextData={this.props.TextData}  title={name} handle={this.props.layerchange} />
                                            <br/> <LayerTextSize data2={this.props.data2}  value = {name} handle={this.props.layerchange}/><br/><LayerButtonHover result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/><FitLayer fitlayer={this.props.fitlayer} value = {name} /></div>
                                            {(this.state.GeoOptions[index2].data2==="block")&&(<div id={index}  style={{display:this.state.GeoOptions[index2].data2,paddingLeft: "25px"}}>
                                                <div style={{display:"flex"}}><FitLayer fitlayer={this.props.fitlayer} value = {name} />
                                                    <LayerFeaturesTable result={this.props.result}  onFeaturestable={this.props.onFeaturestable} value = {name} /></div>
                                                <label>Layer Filters:</label>
                                                <LayerFilters setinteraction={this.props.setinteraction} FilterReservesChange={this.handleReservesFilter} FilterLayerData={this.props.FilterLayerData} Filters={this.props.Filters} minGradient={this.props.minGradient} maxGradient={this.props.maxGradient} histogramValue={this.props.histogramValue} maxWinValue={this.props.maxWinValue} histogramValue2={this.props.histogramValue2} maxAuctionValue={this.props.maxAuctionValue} age_period={age_period} age_word={age_word} value = {name} handle={this.props.layerchange}/>
                                            </div>)}
                                        </li></div>
                                    ))}
                                </ul>
                                <Label>{i18n.t("Infrastructure")}</Label>
                                <ul style={{marginLeft: '-40px',width: "auto"}}>
                                    {infdataname.map(({index,name},index2) => (
                                        <div className="item" border="solid 1px black"><li style={{width: "auto",paddingTop: "4px"}}><LayerButtonFilter setName={this.setOptionsName} handler={this.handler2} title={name}/><LayerButtonOptions  setName={this.setOptionsName} handler={this.handler} title={name} /><LayerButtonDisplayToggle result={this.props.result} data={this.props.data} data2={this.props.data2} presetdata={this.props.presetdata} title={name} handle={this.props.layerchange} dataset={2}/><div id={index}  style={{display:"none",border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}><div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}><Label style={{border:"none"}} basic>{i18n.t('Fill color:')}</Label><span>&nbsp;&nbsp;</span><LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={name} change={this.props.layerchange}/><span>&nbsp;&nbsp;</span><Label style={{border:"none"}} basic>{i18n.t('Border color: ')}</Label><span>&nbsp;&nbsp;</span><LayerBorderColor result={this.props.result}  value={name} handle={this.props.layerchange}/></div><br/><LayerButtonTransparent result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/><br/> <LayerOpacity result={this.props.result} value = {name} handle={this.props.layerchange}/><LayerButtonText data2={this.props.data2} TextData={this.props.TextData}  title={name} handle={this.props.layerchange} />
                                            <br/> <LayerTextSize data2={this.props.data2}  value = {name} handle={this.props.layerchange}/><br/><LayerButtonHover result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/></div>
                                            {(this.state.InfraOptions[index2].data2==="block")&&(<div id={index}  style={{display:this.state.InfraOptions[index2].data2}}>
                                                <label>Layer Filters:</label>
                                                <LayerFilters setinteraction={this.props.setinteraction} FilterLayerData={this.props.FilterLayerData} Filters={this.props.Filters} setMinGradient={this.props.setMinGradient}
                                                              setMaxGradient={this.props.setMaxGradient} minGradient={this.props.minGradient} maxGradient={this.props.maxGradient} histogramValue={this.props.histogramValue} maxWinValue={this.props.maxWinValue} histogramValue2={this.props.histogramValue2} maxAuctionValue={this.props.maxAuctionValue} age_period={age_period} age_word={age_word} value = {name} handle={this.props.layerchange}/>
                                            </div>)}
                                        </li></div>
                                    ))}
                                </ul>
                                <Label>{i18n.t("Ecology")}</Label>
                                <ul style={{marginLeft: '-40px',width: "auto"}}>
                                    {ecodataname.map(({index,name},index2) => (
                                        <div className="item" border="solid 1px black"><li style={{width: "auto",paddingTop: "4px"}}><LayerButtonFilter setName={this.setOptionsName} handler={this.handler2} title={name}/><LayerButtonOptions  setName={this.setOptionsName} handler={this.handler} title={name} /><LayerButtonDisplayToggle result={this.props.result} data={this.props.data} data2={this.props.data2} presetdata={this.props.presetdata} title={name} handle={this.props.layerchange} dataset={2}/><div id={index}  style={{display:"none",border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}><div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}><Label style={{border:"none"}} basic>{i18n.t('Fill color:')} </Label><span>&nbsp;&nbsp;</span><LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={name} change={this.props.layerchange}/><span>&nbsp;&nbsp;</span><Label style={{border:"none"}} basic>{i18n.t('Border color: ')}</Label><span>&nbsp;&nbsp;</span><LayerBorderColor result={this.props.result}  value={name} handle={this.props.layerchange}/></div><br/><LayerButtonTransparent result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/><br/> <LayerOpacity result={this.props.result} value = {name} handle={this.props.layerchange}/><LayerButtonText data2={this.props.data2} TextData={this.props.TextData}  title={name} handle={this.props.layerchange} />
                                            <br/> <LayerTextSize data2={this.props.data2}  value = {name} handle={this.props.layerchange}/><br/><LayerButtonHover result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/></div>
                                            {(this.state.EcoOptions[index2].data2==="block")&&(<div id={index}  style={{display:this.state.EcoOptions[index2].data2,paddingLeft: "25px"}}>
                                                <div style={{display:"flex"}}><FitLayer fitlayer={this.props.fitlayer} value = {name} />
                                                    <LayerFeaturesTable result={this.props.result}  onFeaturestable={this.props.onFeaturestable} value = {name} /></div>
                                                <label>Layer Filters:</label>
                                                <LayerFilters setinteraction={this.props.setinteraction} FilterLayerData={this.props.FilterLayerData} Filters={this.props.Filters} minGradient={this.props.minGradient} maxGradient={this.props.maxGradient} histogramValue={this.props.histogramValue} maxWinValue={this.props.maxWinValue} histogramValue2={this.props.histogramValue2} maxAuctionValue={this.props.maxAuctionValue} age_period={age_period} age_word={age_word} value = {name} handle={this.props.layerchange}/>
                                            </div>)}
                                        </li></div>
                                    ))}
                                </ul>
                                <Label>{i18n.t("Administrative structures")}</Label>
                                <ul style={{marginLeft: '-40px',width: "auto"}}>
                                    {regdataname.map(({index,name},index2) => (
                                        <div className="item" border="solid 1px black"><li style={{width: "auto",paddingTop: "4px"}}><LayerButtonFilter setName={this.setOptionsName} handler={this.handler2} title={name}/><LayerButtonOptions  setName={this.setOptionsName} handler={this.handler} title={name} /><LayerButtonDisplayToggle result={this.props.result} data={this.props.data} data2={this.props.data2} presetdata={this.props.presetdata} title={name} handle={this.props.layerchange} dataset={2}/><div id={index}  style={{display:"none",border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}><div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}><Label style={{border:"none"}} basic>{i18n.t('Fill color:')}</Label><span>&nbsp;&nbsp;</span><LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={name} change={this.props.layerchange}/><span>&nbsp;&nbsp;</span><Label style={{border:"none"}} basic>{i18n.t('Border color: ')}</Label><span>&nbsp;&nbsp;</span><LayerBorderColor result={this.props.result}  value={name} handle={this.props.layerchange}/></div><br/><LayerButtonTransparent result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/><br/> <LayerOpacity result={this.props.result} value = {name} handle={this.props.layerchange}/><LayerButtonText data2={this.props.data2} TextData={this.props.TextData}  title={name} handle={this.props.layerchange} />
                                            <br/> <LayerTextSize data2={this.props.data2}  value = {name} handle={this.props.layerchange}/><br/><LayerButtonHover result={this.props.result}  title={name} handle={this.props.layerchange} dataset={2}/></div>
                                            {(this.state.AdminOptions[index2].data2==="block")&&(<div id={index}  style={{display:this.state.AdminOptions[index2].data2,paddingLeft: "25px"}}>
                                                <div style={{display:"flex"}}><FitLayer fitlayer={this.props.fitlayer} value = {name} />
                                                    <LayerFeaturesTable result={this.props.result}  onFeaturestable={this.props.onFeaturestable} value = {name} /></div>
                                                <label>Layer Filters:</label>
                                                <LayerFilters setinteraction={this.props.setinteraction} FilterLayerData={this.props.FilterLayerData} Filters={this.props.Filters} minGradient={this.props.minGradient} maxGradient={this.props.maxGradient} histogramValue={this.props.histogramValue} maxWinValue={this.props.maxWinValue} histogramValue2={this.props.histogramValue2} maxAuctionValue={this.props.maxAuctionValue} age_period={age_period} age_word={age_word} value = {name} handle={this.props.layerchange}/>
                                            </div>)}
                                        </li></div>
                                    ))}
                                </ul>
                                <Label>{i18n.t("User Layers")}</Label><Icon onMouseDown={(e)=>{
                                e.persist();
                                e.preventDefault()
                                console.log(e);
                                this.setState({addheatmapDisplay:"block",heatmapcoord:{x:e.clientX,y:e.clientY-64}})
                            }} name={"plus circle"} size='large'  />
                                <AddHeatMapMenu coord={this.state.heatmapcoord} update={this.props.layerchange} display={this.state.addheatmapDisplay}
                                    addLayer={(name,res,sourcelayer,paint,min,max,prop,type)=>{
                                        if (type === "close") {
                                            this.setState({addheatmapDisplay:"none"});
                                        }
                                        else{
                                            let filter =
                                                [
                                                    "all",
                                                    [">=", ['get', prop], min],
                                                    ["<=", ['get', prop], max]
                                                ];
                                            let exist = false;
                                            for (let j=0;j<this.props.result.length;j++){
                                                if ( this.props.result[j].name.toLowerCase() === name.toLowerCase()){
                                                    exist=true;
                                                }
                                            }
                                            if (!exist){
                                                this.props.result.push({name:name,data:res,color:"#FF00FF",id:this.props.data.length+name,beforeid:this.props.data.length+2,visibility:"visible",sourcelayer:sourcelayer,opacity:0.8,type:type,text:false,border:"none",hover:true,paint:paint,transparent:false,source:sourcelayer,layers:filter,colordefault:"#FFFFFF"});
                                                this.props.LayerOptions.push({name:name,data:'none',data2:"none",id:this.props.data.length+1});
                                                this.props.UserOptions.push({name:name,data:'none',data2:"none",id:20+this.props.data.length+1});
                                                this.props.userdataname.push({index:2+userdataname.length,name:name});
                                                this.props.data.push({name:name,data:res,color:"#FF00FF",id:this.props.data.length+name,beforeid:this.props.data.length+2,visibility:"visible",sourcelayer:sourcelayer,opacity:0.8,type:type,text:false,border:"none",hover:true,paint:paint,transparent:false,source:sourcelayer,layers:filter,colordefault:"#FFFFFF"});
                                                this.props.presetdata.push({name:name,data:res,color:"#FF00FF",id:this.props.data.length+name,beforeid:this.props.data.length+2,visibility:"visible",sourcelayer:sourcelayer,opacity:0.8,type:type,text:false,border:"none",hover:true,paint:paint,transparent:false,source:sourcelayer,layers:filter,colordefault:"#FFFFFF"});
                                                this.props.dataname.push({index:dataname.length,name:name});
                                                this.props.data2.push({name:name+"Text",data:res,color:"#00218B",id:this.props.data2[this.props.data2.length-1].id,beforeid:false,visibility:"none",sourcelayer:sourcelayer,opacity:0.8,type:type,layoutname:'OriginalName'})
                                                this.setState({addheatmapDisplay:"none"});
                                                this.props.layerchange();
                                            }
                                            else{
                                                console.log("LAYER ALREADY EXISTS")
                                            }
                                        }

                                    }}
                            />
                                <ul style={{marginLeft: '-40px',width: "auto"}}>
                                    {this.props.userdataname.map(({index,name,type},index2) => (
                                        <div className="item" border="solid 1px black"><li style={{width: "auto",paddingTop: "4px",height:"28.8px"}}>
                                            {console.log(type,name)}
                                            {(!name.includes("Heatmap") && !name.includes("Bubble"))&&(<LayerButtonOptions  setName={this.setOptionsName} handler={this.handler} title={name} />)}
                                            <LayerButtonDisplayToggle result={this.props.result} data={this.props.data} data2={this.props.data2} presetdata={this.props.presetdata} title={name} handle={this.props.layerchange} />
                                            <div id={index}  style={{display:this.state.UserOptions[index2].data2,border:"solid 1px black",padding:'2px 2px',lineHeight: '1.6'}}>
                                                <div style={{display:'inline-block',position: "relative",zIndex: 99,width: "-webkit-fill-available"}}>
                                                    {i18n.t('Fill color:')}
                                                    <span>&nbsp;&nbsp;</span>
                                                    <LayerColor result={this.props.result} colorGradient1={this.props.colorGradient1} colorGradient2={this.props.colorGradient2}  TextData={this.props.TextData} LegendLayer={this.props.LegendLayer}  value={name} change={this.props.layerchange} />
                                                </div>
                                                <br/>
                                                <LayerOpacity result={this.props.result} value = {name} handle={this.props.layerchange}/>
                                                <LayerDelete result={this.props.result} LayerOptions={this.props.LayerOptions} data={this.props.data} presetdata={this.props.presetdata} data2={this.props.data} dataname={this.props.dataname} UserOptions={this.props.UserOptions} userdataname={this.props.userdataname} value = {name} handle={this.props.layerchange} delete={this.handleLayerDelete}/>
                                                {(type === "GeojsonLayer") && (
                                                    <>
                                                        <Button size={"small"} basic icon={"zoom-in"} onClick={()=>this.props.fitGeoJsonLayer(name)}/>
                                                        <LayerGeoJsonToDB result={this.props.result}  value={name} loader={this.props.loaderhandle} />
                                                    </>

                                                )}
                                                {(type === "ImageLayer") && (
                                                    <LayerGeoTiffToDB result={this.props.result}  value={name} loader={this.props.loaderhandle2} />
                                                )}

                                            </div>
                                        </li></div>
                                    ))}
                                </ul>
                                <Label>{i18n.t("Layouts")}</Label>
                                <ul style={{marginLeft: '-40px',width: "auto"}}>
                                    {layoutdataname.map(({index,name},index2) => (
                                        <div className="item" border="solid 1px black"><li style={{width: "auto",paddingTop: "4px"}}><LayerButtonDisplayToggle result={this.props.result} data={this.props.data} data2={this.props.data2} presetdata={this.props.presetdata} title={name} handle={this.props.layerchange} dataset={2}/></li></div>
                                    ))}
                                </ul>
                            </div>)}
                        <MobileView>
                            <Button
                                onClick={() => { this.props.layerchange3("About");}} size={'small'}
                            >
                                {i18n.t("About")}
                            </Button>
                            <Button
                                onClick={() => { this.props.layerchange3("Reset");}} size={'small'}
                            >
                                {i18n.t("Reset")}
                            </Button>
                            <Button
                                onClick={() => { this.props.openhelp()}} size={'small'}
                            >
                                {i18n.t("Help")}
                            </Button>
                        </MobileView>
                        <Button onClick={this.handleLegendClick} >{i18n.t("Legends")}</Button>
                        <div onMouseEnter={this.handleLegendClick2} style={{display:this.state.legendDisplay}}>
                            {console.log(this.props.LegendLayer)}
                            {this.props.LegendLayer.map(({name,data}) => {
                                let layer = name;
                                if (data === false || (typeof(data)==='object' && data.length ===undefined)) {
                                    return (
                                        <div>
                                            <br/>
                                        </div>
                                    )
                                }
                                else {
                                    let datalegend=data;
                                    console.log(datalegend)
                                    if (datalegend === "Not supported request!")
                                        datalegend = {};
                                    return (
                                        <div>
                                            <Label>{layer}</Label>
                                            {datalegend.map(({name,color}) => {
                                                if ( name === null || name === "")
                                                {
                                                    name = "Not Available"  ;
                                                    color = "#000000";
                                                }
                                                return(
                                                    <div style={{display:"inline"}}>
                                                        <br/>
                                                        <div style={ {width: '40px', height: '20px', background:color,display:'inline-block'} } /><div style={{display:'inline-block'}}>:{name}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LayerMenu;
