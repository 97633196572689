import React, {useEffect, useState} from "react";
import {Checkbox} from "semantic-ui-react";
import i18n from "../../i18n";

const LayerButtonHover = ({result,title,handle}) => {
    const [active, setActive] = useState(false);
    let check = false;
    for (let i = 0; i<result.length;i++){
        if (result[i].name === title) {
            check = !result[i].hover;
        }
    }
    useEffect(() => {
        // Обновляем заголовок документа с помощью API браузера
        setActive(check);
    });
    return (
        <Checkbox
            slider
            size={'mini'}
            label={i18n.t('Hover')}
            className={'checkbox'}
            checked={!active}
            onChange={ () => {
                handle();
                for (let i = 0; i < result.length; i++) {
                    if (result[i].name === title) {
                        if (active === false) {
                            result[i].hover = false;
                            setActive(true)}
                        else{
                            result[i].hover = true;
                            setActive(false)}
                    }
                }
            }}/>
    );
};
export default LayerButtonHover;